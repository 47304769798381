import { NavigationWrapper } from '../common/app-navigation/navigation-wrapper'
import { Account } from './account'

export const AccountView = () => {
  return (
    <NavigationWrapper>
      <Account />
    </NavigationWrapper>
  )
}
