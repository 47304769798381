import { Field, Int32, Int64, List, Struct } from 'apache-arrow'

/**
 * This file should look like the following scala schema:
 * case class FilesHourly(
 *     hour_window: Timestamp,
 *     file_rows_hist: WindowedHistogram[Long],
 *     file_bytes_hist: WindowedHistogram[Long])
 *
 * case class WindowedHistogram[T](
 *     min_value: T,
 *     max_value: T,
 *     count_value: T,
 *     sum_value: T,
 *     hist: Histogram[T])
 *
 * case class Histogram[T](
 *     min_bin_value: T,
 *     max_bin_value: T,
 *     number_of_bins: Int,
 *     bin_heights: Seq[Bin])
 *
 * case class Bin(bin_index: Int, bin_count: Long)
 */
const BinStructType = new Struct([new Field('bin_index', new Int32(), true), new Field('bin_count', new Int64(), true)])
const HistogramStructType = new Struct([
  new Field('number_of_bins', new Int32(), true),
  new Field('bin_heights', new List(new Field('element', BinStructType)), true),
])
export const WindowedHistogramStructType = new Struct([
  new Field('min_value', new Int64(), true),
  new Field('max_value', new Int64(), true),
  new Field('count_value', new Int64(), true),
  new Field('sum_value', new Int64(), true),
  new Field('hist', HistogramStructType, true),
])
