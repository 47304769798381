import { TimeSeries } from '../../response-models'
import { TimeSeriesWindow } from '../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { sanitize } from '../sql-utils'
import { ingestionTimeSeriesQuery } from './queries/ingestion-time-series'
import { duck, DuckSession } from '../../duck'

export interface IngestionTimeSeries {
  /**
   * The average duration of an ingestions in the given time window
   */
  averageDuration: TimeSeries<number>
  /**
   * The total cpu consumption for the given time window.
   */
  cpuConsumption: TimeSeries<number>
  /**
   * The total number of files ingested in the given time window.
   */
  numberOfFilesIngested: TimeSeries<number>
  /**
   * The total number of bytes ingested in the given time window.
   */
  bytesIngested: TimeSeries<number>
  /**
   * The average maximum delay between a object creation and its ingestion in the given time window.
   */
  maxIngestionDelay: TimeSeries<number>
}

/**
 * This function uses the data from loadIngestionsTable to return a time
 * window that shows how the number of files, bytes and rows changed over time.
 * @param timeWindow
 * @param ingestionsInsightsTable the name of hte table
 */

export const getIngestionsTimeSeries = async (
  timeWindow: TimeSeriesWindow,
  ingestionsInsightsTable: string
): Promise<IngestionTimeSeries> => {
  const query = ingestionTimeSeriesQuery(ingestionsInsightsTable, timeWindow)

  return duck.runWithSession(async (session: DuckSession) => {
    const queryResult = await session.runQuery(query)
    const firstRow = queryResult.get(0).toJSON()
    if (
      firstRow &&
      firstRow.averageDuration &&
      firstRow.cpuConsumption &&
      firstRow.numberOfFilesIngested &&
      firstRow.maxIngestionDelay &&
      firstRow.bytesIngested
    ) {
      const { averageDuration, cpuConsumption, numberOfFilesIngested, bytesIngested, maxIngestionDelay } = firstRow

      return {
        averageDuration: {
          windowUnit: timeWindow,
          x: averageDuration.x.toJSON().map(sanitize) as number[],
          y: averageDuration.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
        cpuConsumption: {
          windowUnit: timeWindow,
          x: cpuConsumption.x.toJSON().map(sanitize) as number[],
          y: cpuConsumption.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
        numberOfFilesIngested: {
          windowUnit: timeWindow,
          x: numberOfFilesIngested.x.toJSON().map(sanitize) as number[],
          y: numberOfFilesIngested.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
        bytesIngested: {
          windowUnit: timeWindow,
          x: bytesIngested.x.toJSON().map(sanitize) as number[],
          y: bytesIngested.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
        maxIngestionDelay: {
          windowUnit: timeWindow,
          x: maxIngestionDelay.x.toJSON().map(sanitize) as number[],
          y: maxIngestionDelay.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
      }
    } else {
      return null
    }
  })
}
