interface StatsCardProps {
  title: string
  value: string
}

export const StatsCard = ({ title, value }: StatsCardProps) => {
  return (
    <div className='w-30 flex h-24 flex-col items-center justify-between rounded-xl bg-primaryLight px-2 py-4 text-white shadow-2xl'>
      <span className='px-4 text-center text-lg'>{title}</span>
      <div className='flex flex-col items-center text-center font-bold'>
        <span>{value}</span>
      </div>
    </div>
  )
}
