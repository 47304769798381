import { TableMetadataConvertTo } from './table-metadata-convert-to'
import { TableMetadataTablePreview } from './table-metadata-table-preview'
import { TableMetadataLogs } from './table-metadata-logs'
import { TableMetadataDetails } from './table-metadata-details'
import { TableMetadataColumns } from './table-metadata-columns'
import { ChosenTab, NotChosenTab } from '../../../../common/catalog-navigation/catalog-navigation-atoms'
import { useDispatch, useSelector } from 'react-redux'
import {
  chooseTableMetadataTab,
  selectChosenTableMetadataTab,
  TableMetadataTab,
} from '../../../../../state/slices/catalogs-slice'

export const TableMetadata = () => {
  const chosenTab = useSelector(selectChosenTableMetadataTab)
  const dispatch = useDispatch()

  return (
    <>
      <ul className='sticky top-0 z-10 flex h-20 w-full flex-row items-center justify-around border-gray-200 bg-gray-100 shadow'>
        {[
          [TableMetadataTab.DETAILS, 'DETAILS'],
          [TableMetadataTab.COLUMNS, 'COLUMNS'],
          /*[TableMetadataTab.HISTORY, 'HISTORY'],
          [TableMetadataTab.TABLE_PREVIEW, 'TABLE PREVIEW'],
          [TableMetadataTab.CONVERT_TO, 'CONVERT TO'],*/
        ].map((tuple) => {
          const [tab, title] = tuple as [TableMetadataTab, string]
          if (tab === chosenTab) {
            return (
              <ChosenTab
                key={title}
                title={title}
                handleClick={() => {
                  dispatch(chooseTableMetadataTab(tab))
                }}
              />
            )
          } else {
            return (
              <NotChosenTab
                key={title}
                title={title as string}
                handleClick={() => {
                  dispatch(chooseTableMetadataTab(tab))
                }}
              />
            )
          }
        })}
      </ul>
      <div className='w-full p-4'>
        {
          {
            [TableMetadataTab.DETAILS]: <TableMetadataDetails />,
            [TableMetadataTab.COLUMNS]: <TableMetadataColumns />,
            [TableMetadataTab.HISTORY]: <TableMetadataLogs />,
            [TableMetadataTab.TABLE_PREVIEW]: <TableMetadataTablePreview />,
            [TableMetadataTab.CONVERT_TO]: <TableMetadataConvertTo />,
          }[chosenTab]
        }
      </div>
    </>
  )
}
