import { TimeSeries } from '../../response-models'
import { TimeSeriesWindow } from '../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { sanitize } from '../sql-utils'
import { globalIngestionTimeSeriesQuery } from './queries/global-ingestion-time-series'
import { duck, DuckSession } from '../../duck'

export interface GlobalIngestionTimeSeries {
  /**
   * The total size of data ingested in the given time window
   */
  totalInputBytes: TimeSeries<number>
  /**
   * The total size of files or events ingested in the given time window
   */
  totalInputFiles: TimeSeries<number>
  /**
   * The total number of managed ingestions in the given time window
   */
  numberOfIngestedTable: TimeSeries<number>
}

/**
 * This function uses the data from loadIngestionsTable to return a time
 * window that shows how the number of files, bytes and rows changed over time.
 * @param timeWindow
 */

export const getGlobalIngestionsTimeSeries = async (
  timeWindow: TimeSeriesWindow
): Promise<GlobalIngestionTimeSeries> => {
  const query = globalIngestionTimeSeriesQuery(timeWindow)

  return duck.runWithSession(async (session: DuckSession) => {
    const queryResult = await session.runQuery(query)
    const firstRow = queryResult.get(0).toJSON()

    if (
      firstRow &&
      firstRow.totalInputBytes &&
      firstRow.totalInputBytes.x != null &&
      firstRow.totalInputFiles &&
      firstRow.numberOfIngestedTable
    ) {
      const { totalInputBytes, totalInputFiles, numberOfIngestedTable } = firstRow

      return {
        totalInputBytes: {
          windowUnit: timeWindow,
          x: totalInputBytes.x.toJSON().map(sanitize) as number[],
          y: totalInputBytes.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
        totalInputFiles: {
          windowUnit: timeWindow,
          x: totalInputFiles.x.toJSON().map(sanitize) as number[],
          y: totalInputFiles.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
        numberOfIngestedTable: {
          windowUnit: timeWindow,
          x: numberOfIngestedTable.x.toJSON().map(sanitize) as number[],
          y: numberOfIngestedTable.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
      }
    } else {
      return {
        totalInputBytes: {
          windowUnit: timeWindow,
          x: [],
          y: [],
        } as TimeSeries<number>,
        totalInputFiles: {
          windowUnit: timeWindow,
          x: [],
          y: [],
        } as TimeSeries<number>,
        numberOfIngestedTable: {
          windowUnit: timeWindow,
          x: [],
          y: [],
        } as TimeSeries<number>,
      }
    }
  })
}
