import { TimeSeries } from '../transport/response-models'
import { ChartDataPoint } from '../components/common/chart/types'

export const zipTimeSeriesOrGetEmptyIfFalsy = (timeSeries: TimeSeries<number>): ChartDataPoint[] => {
  if (!timeSeries) {
    return []
  } else {
    return zipTimeSeries(timeSeries)
  }
}
export const zipTimeSeries = (timeSeries: TimeSeries<number>): ChartDataPoint[] => {
  return timeSeries.x.map((x, i) => ({
    x,
    y: timeSeries.y[i],
  }))
}
