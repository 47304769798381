export const CANVAS_IDS = Object.freeze({
  TABLE_INSIGHTS_SIZE_HISTOGRAM_ID: 'table-details-size-histogram-id',
  TABLE_INSIGHTS_NUMBER_OF_ROWS_PER_FILE_HISTOGRAM_ID: 'table-details-number-of-rows-per-file-histogram-id',
  TABLE_INSIGHTS_TIMELINE_ID: 'table-insights-timeline-id',
  CONSUMPTION_DETAILS_TIMELINE_ID: 'consumption-details-timeline-id',
  INGESTION_DETAILS_TIMELINE_ID: 'ingestion-details-timeline-id',
  TABLE_INSIGHTS_OVERVIEW_TIMELINE_ID: 'table-insights-overview-timeline-id',
  CONSUMPTION_OVERVIEW_TIMELINE_ID: 'consumption-overview-timeline-id',
  INGESTION_OVERVIEW_TIMELINE_ID: 'ingestion-overview-timeline-id',
  GLOBAL_INGESTION_OVERVIEW_TIMELINE_ID: 'global-ingestion-overview-timeline-id',
  GLOBAL_FILES_OVERVIEW_TIMELINE_ID: 'global-files-overview-timeline-id',
})
