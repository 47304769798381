interface ChartCanvasProps {
  canvasId: string
}

export const ChartCanvas = ({ canvasId }: ChartCanvasProps) => {
  return (
    // https://www.chartjs.org/docs/latest/configuration/responsive.html#important-note
    <div className={`${canvasId}-container relative h-[500px] w-full 2xl:h-[600px]`}>
      <canvas id={canvasId} className='h-full w-full' />
    </div>
  )
}
