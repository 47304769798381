import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectAreIngestionInsightsFetched,
  selectIngestionTimeSeriesWindow,
  selectIsIngestionInsightsTimelineCalculated,
  selectShouldFetchIngestionTableWithName,
} from '../../../../../state/slices/ingestion-insights-slice'
import { selectAccountAccessToken } from '../../../../../state/slices/account-slice'
import {
  selectChosenCatalogId,
  selectChosenIngestionInsightsArrowTableName,
  selectChosenNamespaceId,
  selectChosenTableId,
  selectHasIngestionInsights,
} from '../../../../../state/slices/catalogs-slice'
import { Loader } from '../../../../common/loader'
import { IngestionInsightsTimelineConnector } from './ingestion-insights-timeline-connector'
import { useFetchIngestionInsights } from './use-fetch-ingestion-insights'
import { useFetchIngestionTimeSeries } from './use-fetch-ingestion-time-series'

export const IngestionInsights = () => {
  const hasIngestionInsights = useSelector(selectHasIngestionInsights)
  const areIngestionInsightsFetched: (tableName: string) => boolean = useSelector(selectAreIngestionInsightsFetched)
  const isCongestionTimelineCalculated: boolean = useSelector(selectIsIngestionInsightsTimelineCalculated)
  const shouldFetch: (arrowTableName: string) => boolean = useSelector(selectShouldFetchIngestionTableWithName)
  const accessToken = useSelector(selectAccountAccessToken)

  const catalogId = useSelector(selectChosenCatalogId)
  const namespaceId = useSelector(selectChosenNamespaceId)
  const tableId = useSelector(selectChosenTableId)

  const ingestionInsightsArrowTableName = useSelector(selectChosenIngestionInsightsArrowTableName)
  const ingestionTimeSeriesWindow = useSelector(selectIngestionTimeSeriesWindow)

  const fetchIngestionInsights = useFetchIngestionInsights()
  const fetchIngestionTimeSeries = useFetchIngestionTimeSeries()

  useEffect(() => {
    if (catalogId && namespaceId && tableId && hasIngestionInsights && shouldFetch(ingestionInsightsArrowTableName)) {
      fetchIngestionInsights(accessToken).then()
    }
  }, [ingestionInsightsArrowTableName])

  useEffect(() => {
    if (hasIngestionInsights) {
      fetchIngestionTimeSeries().then()
    }
  }, [
    ingestionTimeSeriesWindow,
    ingestionInsightsArrowTableName,
    areIngestionInsightsFetched(ingestionInsightsArrowTableName),
  ])

  if (!hasIngestionInsights) {
    return (
      <div className='mt-24 flex w-full flex-col items-center p-4'>
        <div className='w-full'>
          <h1 className='text-center text-xl font-bold'>Ingestion Insights are not available for this table</h1>
        </div>
      </div>
    )
  } else {
    return (
      <div className='mt-24 flex w-full flex-col items-center p-4'>
        {!areIngestionInsightsFetched(ingestionInsightsArrowTableName) || !isCongestionTimelineCalculated ? (
          <div className='w-full'>
            <Loader />
          </div>
        ) : (
          <div className='mb-20 flex h-full w-10/12 flex-col items-center justify-center 3xl:w-3/4'>
            <IngestionInsightsTimelineConnector />
          </div>
        )}
      </div>
    )
  }
}
