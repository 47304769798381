import { CollapsedCatalog, CollapsedNamespace, ExpandedCatalog, ExpandedNamespace, TableLine } from './atoms'
import { useDispatch, useSelector } from 'react-redux'
import {
  collapseCatalog,
  collapseNamespace,
  expandCatalog,
  expandNamespace,
  selectCatalogsCompleteTree,
  selectChosenCatalogId,
  selectChosenNamespaceId,
  selectChosenTableId,
  selectIsFetchingCatalogs,
  selectTable,
} from '../../../state/slices/catalogs-slice'
import { useEffect } from 'react'

export const DataExplorerSideBar = () => {
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetchingCatalogs)
  const chosenCatalogId = useSelector(selectChosenCatalogId)
  const chosenNamespaceId = useSelector(selectChosenNamespaceId)
  const chosenTableId = useSelector(selectChosenTableId)
  const catalogs = useSelector(selectCatalogsCompleteTree)

  useEffect(() => {
    const imageLinks: string[] = [
      '/qbeast-icons/data-explorer/arrow-down2.svg',
      '/qbeast-icons/data-explorer/arrow-right2.svg',
      '/qbeast-icons/data-explorer/hard-drive.svg',
      '/qbeast-icons/data-explorer/database.svg',
      '/qbeast-icons/data-explorer/app-window.svg',
    ]
    imageLinks.forEach((link: string) => {
      new Image().src = link
      const img = new Image()
      img.src = link
    })
  }, [])

  return (
    <>
      {!isFetching && (
        <div className='flex w-full flex-col overflow-auto'>
          {catalogs.map((c) => {
            if (c.isExpanded) {
              return (
                <div key={c.id} className='flex flex-col'>
                  <ExpandedCatalog
                    text={c.id}
                    handleClick={() => {
                      dispatch(collapseCatalog(c.id))
                    }}
                    isChosen={c.id === chosenCatalogId}
                  />
                  <div className='flex w-full flex-col pl-4'>
                    <div className={'flex w-full flex-col'}>
                      {c.namespaces.map((n) => {
                        if (n.isExpanded) {
                          return (
                            <div key={n.id} className='flex flex-col'>
                              <ExpandedNamespace
                                text={n.id}
                                handleClick={() => {
                                  dispatch(collapseNamespace(n.id))
                                }}
                                isChosen={n.id === chosenNamespaceId}
                              />
                              <div className='flex w-full flex-col pl-6'>
                                <div className={'flex w-full flex-col'}>
                                  {n.tables.map((t) => (
                                    <TableLine
                                      key={t.id}
                                      text={t.id}
                                      isChosen={t.id === chosenTableId}
                                      handleClick={() => {
                                        dispatch(selectTable(t.id))
                                      }}
                                    />
                                  ))}
                                </div>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <CollapsedNamespace
                              key={n.id}
                              text={n.id}
                              handleClick={() => {
                                dispatch(expandNamespace(n.id))
                              }}
                            />
                          )
                        }
                      })}
                    </div>
                  </div>
                </div>
              )
            } else {
              return (
                <CollapsedCatalog
                  key={c.id}
                  text={c.id}
                  handleClick={() => {
                    dispatch(expandCatalog(c.id))
                  }}
                />
              )
            }
          })}
        </div>
      )}
    </>
  )
}
