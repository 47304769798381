import { Bin, Stats, TimeSeries } from '../../response-models'
import {
  Period,
  TimeSeriesWindow,
} from '../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { duckDialect, sanitize } from '../sql-utils'
import { duck } from '../../duck'
import { windowedHistogramPerPeriod } from '../query-utils/windowed-histogram-per-period'
import { fileSummaryTimeSeries } from './queries/files-summary-time-series'

export const getTableInsightsStats = async (
  period: Period,
  columnName: string,
  fileInsightsTableName: string
): Promise<Stats<number>> => {
  const query = windowedHistogramPerPeriod(fileInsightsTableName, columnName, period, duckDialect)
  const queryResult = await duck.runOne(query)
  const firstRow = queryResult.get(0).toJSON()
  if (firstRow && firstRow.hist && firstRow.hist.length > 0) {
    return {
      histogram: firstRow.hist
        .toArray()
        .map((a) => a.toJSON())
        .map(({ bin_index, bin_count }) => {
          return {
            binIndex: sanitize(bin_index),
            binCount: sanitize(bin_count),
          } as Bin
        }),
      max: sanitize(firstRow.max_value),
      min: sanitize(firstRow.min_value),
      avg: sanitize(firstRow.avg_value),
      total: sanitize(firstRow.sum_value),
      count: sanitize(firstRow.count_value),
    } as Stats<number>
  } else {
    return {
      histogram: [],
      max: 0,
      min: 0,
      avg: 0,
      total: 0,
      count: 0,
    } as Stats<number>
  }
}

/**
 * This function uses the data from TableInsight to return a time window that shows
 * how the number of files, bytes and rows changed over time.
 * @param timeWindow
 * @param fileInsightsTableName
 */

export interface FileTimeSeries {
  numberOfBytes: TimeSeries<number>
  numberOfRows: TimeSeries<number>
  numberOfFiles: TimeSeries<number>
}

export const getFileTimeSeries = async (
  timeWindow: TimeSeriesWindow,
  fileInsightsTableName: string
): Promise<FileTimeSeries> => {
  const query = fileSummaryTimeSeries(fileInsightsTableName, timeWindow)
  const queryResult = await duck.runOne(query)
  const firstRow = queryResult.get(0)
  if (firstRow && firstRow.number_of_rows && firstRow.number_of_rows.x) {
    const { number_of_rows, number_of_files, number_of_bytes } = firstRow

    return {
      numberOfRows: {
        windowUnit: timeWindow,
        x: number_of_rows.x.toJSON().map(sanitize) as number[],
        y: number_of_rows.y.toJSON().map(sanitize) as number[],
      } as TimeSeries<number>,
      numberOfFiles: {
        windowUnit: timeWindow,
        x: number_of_files.x.toJSON().map(sanitize) as number[],
        y: number_of_files.y.toJSON().map(sanitize) as number[],
      },
      numberOfBytes: {
        windowUnit: timeWindow,
        x: number_of_bytes.x.toJSON().map(sanitize) as number[],
        y: number_of_bytes.y.toJSON().map(sanitize) as number[],
      },
    }
  } else {
    return {
      numberOfRows: {
        windowUnit: timeWindow,
        x: [],
        y: [],
      } as TimeSeries<number>,
      numberOfFiles: {
        windowUnit: timeWindow,
        x: [],
        y: [],
      },
      numberOfBytes: {
        windowUnit: timeWindow,
        x: [],
        y: [],
      },
    }
  }
}
