import {
  Cell3,
  ColumnTitle3,
  Table,
  TableBodyRow,
  TableHeadRow,
  TableWrapper,
} from '../../../../common/table/table-atoms'
import { useSelector } from 'react-redux'
import { Column, selectColumnsOfChosenTable } from '../../../../../state/slices/catalogs-slice'

export const Row = ({ column: { name, description, dataType } }: { column: Column }) => (
  <TableBodyRow>
    <Cell3 text={name} />
    <Cell3 text={dataType} />
    <Cell3 text={description || 'NO DESCRIPTION'} />
  </TableBodyRow>
)

export const TableMetadataColumns = () => {
  const columns: Column[] = useSelector(selectColumnsOfChosenTable)

  return (
    <div className='mt-16 flex w-full'>
      <div className='xl:w-1/6 2xl:w-1/4' />
      <div className='flex w-full flex-col items-center justify-center xl:w-2/3 2xl:w-1/2'>
        <div className='flex flex-row gap-x-4 py-4'>
          <h2 className='text-bold mb-4 text-center text-3xl font-bold'>Columns</h2>
        </div>
        <TableWrapper>
          <Table>
            <TableHeadRow>
              <ColumnTitle3 title='Column Name' />
              <ColumnTitle3 title='Type' />
              <ColumnTitle3 title='Description' />
            </TableHeadRow>
            <tbody>
              {columns.map((c: Column) => (
                <Row key={c.name} column={c} />
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </div>
    </div>
  )
}
