export enum ChartNavigationMode {
  ZOOM = 'zoom',
  PAN = 'pan',
  SELECTION = 'selection',
}

export interface ChartDataPoint {
  x: number
  y: number
}
