import { getConfig } from '../../config/environment-config'
import * as amplitude from '@amplitude/analytics-browser'

export const initializeAmplitude = () => {
  if (getConfig().amplitudeApiKey) {
    amplitude.init(getConfig().amplitudeApiKey, {
      defaultTracking: true,
    })
  }
}
