import { useDispatch, useSelector } from 'react-redux'
import { manageTableContinuousOptimization, optimizeTable } from '../../transport/spark-api'
import { selectAccountAccessToken } from '../../state/slices/account-slice'
import {
  selectChosenOptimizationTableName,
  selectChosenTable,
  selectHasContinuousOptimization,
} from '../../state/slices/catalogs-slice'
import { actions as modalActions } from '../../state/slices/modal-slice'
import { useFetchCatalogs } from '../data-explorer/use-fetch-catalogs'
import { useState } from 'react'
import ModalForm from '../modal/modal-form'
import { ClipLoader } from 'react-spinners'
import { Dialog } from '@headlessui/react'

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export const ContOptimizeTableButton: React.FC = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const fetchCatalogs = useFetchCatalogs(true)
  const fetchHasContinuousOptimization = useSelector(selectHasContinuousOptimization)
  // I need the table name but without the use_catalog. prefix
  const tableName = useSelector(selectChosenOptimizationTableName).slice('use_catalog.'.length)
  let hasContinuousOptimization = useSelector(selectHasContinuousOptimization)
  const labels: Array<string> = ['Start', 'Stop']

  const handleOptimize = async (e: React.FormEvent) => {
    if (e) {
      // Avoid to reload the web page when submitting the form
      e.preventDefault()
      setLoading(true)
    }
    const result = await manageTableContinuousOptimization(
      accessToken,
      tableName,
      !hasContinuousOptimization,
      parseInt(optimizationArgument, 10)
    )
    if (result.hasFailed) {
      console.error(`Optimization request failed: ${result.errorMessage}`)
    } else {
      console.debug('Optimization request successful')
      // We reload the catalog from the USE backend with an updated cont optimization configuration
      await fetchCatalogs(accessToken).then(() => {})
      hasContinuousOptimization = fetchHasContinuousOptimization
    }
  }

  const [loading, setLoading] = useState(false)
  const [showModalForm, setShowModalForm] = useState(false)
  const [optimizationArgument, setOptimizationArgument] = useState('1000000000')

  const handleOptimizeClick = () => {
    if (hasContinuousOptimization) {
      setLoading(true)
      handleOptimize(null)
    } else setShowModalForm(true)
  }

  return (
    <>
      <button onClick={handleOptimizeClick} className='mb-4 rounded bg-primary px-4 py-2 font-medium text-white'>
        {labels[Number(hasContinuousOptimization)]} Continuous Optimization ™
      </button>
      <ModalForm isOpen={showModalForm} onClose={() => setShowModalForm(false)}>
        <form onSubmit={handleOptimize} className='mb-4'>
          <p>Once enabled, your table will be continuously optimized in the background.</p>
          <p>Please check the optimization events tab for optimization activities.</p>
          <p>
            You can select below how many bytes will be optimized by the process each time it is run. Default value is 1
            Gigabytes.
          </p>
          <div className='mt-4 flex items-center space-x-4'>
            <label className='block text-sm font-medium text-gray-700'>Bytes to optimize in each iteration</label>
            <input
              type='number'
              value={optimizationArgument}
              onChange={(e) => setOptimizationArgument(e.target.value)}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
              required
            />
          </div>
          <div className='mt-4 flex justify-between'>
            <button type='button' onClick={() => setShowModalForm(false)} disabled={loading}>
              Cancel
            </button>
            <button
              type='submit'
              disabled={loading}
              className='mb-4 rounded bg-primary px-4 py-2 font-medium text-white'
            >
              Confirm
            </button>
          </div>
        </form>
      </ModalForm>
      {loading && (
        <Dialog
          open={loading}
          onClose={() => setLoading(false)}
          className='fixed inset-0 z-50 flex items-center justify-center'
        >
          <Dialog.Overlay className='fixed inset-0 bg-black opacity-50' />
          <div className='relative z-10 flex flex-col items-center justify-center rounded bg-white p-4'>
            <ClipLoader size={50} color='#123abc' />
            <span className='mt-2 text-lg font-medium'>Sending request to the server...</span>
          </div>
        </Dialog>
      )}
    </>
  )
}
