import { useDispatch, useSelector } from 'react-redux'
import { optimizeTable } from '../../transport/spark-api'
import { selectAccountAccessToken } from '../../state/slices/account-slice'
import { selectChosenOptimizationTableName, selectHasContinuousOptimization } from '../../state/slices/catalogs-slice'
import { actions as modalActions } from '../../state/slices/modal-slice'
import ModalForm from '../modal/modal-form'
import { Dialog } from '@headlessui/react'
import { ClipLoader } from 'react-spinners'
import { useState } from 'react'

export const OptimizeTableButton: React.FC = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const tableName = useSelector(selectChosenOptimizationTableName)
  const dispatch = useDispatch()
  const hasContinuousOptimization = useSelector(selectHasContinuousOptimization)

  const handleOptimize = async (e: React.FormEvent) => {
    e.preventDefault()
    setShowModalForm(false)
    setLoading(true)
    const result = await optimizeTable(accessToken, tableName, parseInt(optimizationArgument, 10))
    if (result.hasFailed) {
      console.error(`Optimization request failed: ${result.errorMessage}`)
    } else {
      console.debug('Optimization request successful')
    }
    setLoading(false)
  }

  const [loading, setLoading] = useState(false)
  const [showModalForm, setShowModalForm] = useState(false)
  const [optimizationArgument, setOptimizationArgument] = useState('1000000000')

  return (
    <>
      <button
        disabled={hasContinuousOptimization}
        onClick={() => setShowModalForm(true)}
        className={`mb-4 rounded px-4 py-2 font-medium text-white ${
          hasContinuousOptimization ? 'cursor-not-allowed bg-gray-400' : 'hover:bg-primary-dark bg-primary'
        }`}
      >
        Optimize
      </button>
      <ModalForm isOpen={showModalForm} onClose={() => setShowModalForm(false)}>
        <form onSubmit={handleOptimize} className='mb-4'>
          <p>
            When you click on "Confirm", an optimization process will be started immediately in the background for this
            table and will last depending on the size of the data to be processed.
          </p>
          <p>In the case our algorithm doesn't find any data to optimize, no optimization will be performed.</p>
          <p>You can select below how many bytes will be optimized by the process. Default value is 1 Gigabytes.</p>
          <div className='mt-4 flex items-center space-x-4'>
            <label className='block text-sm font-medium text-gray-700'>Bytes to optimize in each iteration</label>
            <input
              type='number'
              value={optimizationArgument}
              onChange={(e) => setOptimizationArgument(e.target.value)}
              className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50'
              required
            />
          </div>
          <div className='mt-4 flex justify-between'>
            <button type='button' onClick={() => setShowModalForm(false)} disabled={loading}>
              Cancel
            </button>
            <button
              type='submit'
              disabled={loading}
              className='mb-4 rounded bg-primary px-4 py-2 font-medium text-white'
            >
              Confirm
            </button>
          </div>
        </form>
      </ModalForm>
      {loading && (
        <Dialog
          open={loading}
          onClose={() => setLoading(false)}
          className='fixed inset-0 z-50 flex items-center justify-center'
        >
          <Dialog.Overlay className='fixed inset-0 bg-black opacity-50' />
          <div className='relative z-10 flex flex-col items-center justify-center rounded bg-white p-4'>
            <ClipLoader size={50} color='#123abc' />
            <span className='mt-2 text-lg font-medium'>Sending request to the server...</span>
          </div>
        </Dialog>
      )}
    </>
  )
}
