import { Period, TimeSeriesWindow } from '../../components/data-explorer/data-explorer-right-side/table-details/types'

/**
 * This functions tries to convert long number from Apache Arrow to a simple number
 * in Javascript. This might result in a loss of precision.
 * @param v
 * @returns {number} the number representation of the arrow data.
 */
export const sanitize = (v: number | Uint32Array | unknown): number => {
  switch (typeof v) {
    case 'bigint':
      return Number(v)
    case 'number':
      return v
    case 'object':
      if (v instanceof Uint32Array) {
        let b = BigInt(v[0])

        for (let i = v.length - 1; i >= 1; i--) {
          const t = BigInt(v[i])
          b += BigInt(t) << BigInt(32)
        }
        // Convert the binary string to a BigInt
        return Number(b)
      } else if (v instanceof Date) {
        return v.getTime()
      }
      throw new Error('Unknown object type:' + typeof v + v.toString())
    default:
      throw new Error('Unknown object type' + typeof v)
  }
}

export const getTimePeriodWherePredicate = (period: Period, unixTimeColumnName: string): string => {
  switch (period) {
    case Period.LAST_6HOUR:
      return `WHERE ${unixTimeColumnName} > CAST(CURRENT_TIMESTAMP AS TIMESTAMP) - INTERVAL 6 HOUR`
    case Period.LAST_DAY:
      return `WHERE ${unixTimeColumnName} >  CAST(CURRENT_TIMESTAMP AS TIMESTAMP) - INTERVAL 1 DAY`
    case Period.LAST_WEEK:
      return `WHERE ${unixTimeColumnName} >  CAST(CURRENT_TIMESTAMP AS TIMESTAMP) - INTERVAL 7 DAY`
    case Period.LAST_MONTH:
      return `WHERE ${unixTimeColumnName} >  CAST(CURRENT_TIMESTAMP AS TIMESTAMP) - INTERVAL 1 MONTH`
    case Period.ALL:
      return ``
  }
}
export const generateDenseSeries = (tableName: string, timeWindow: TimeSeriesWindow): string => {
  const selectTimePredicate = getTruncateSelectTimeWindow(timeWindow, 'hour_window')
  return `
    Windowed as (
        SELECT *, ${selectTimePredicate},
        FROM ${tableName}           
    ),
    RANGES as (
        SELECT unnest(generate_series(min(time_window),max(time_window), ${getSeriesInterval(
          timeWindow
        )})) as time_window 
        FROM Windowed
    )`
}
export const getTruncateSelectTimeWindow = (timeWindow: TimeSeriesWindow, unixTimeColumnName: string): string => {
  const unit = (() => {
    switch (timeWindow) {
      case TimeSeriesWindow.HOUR:
        return 'hour'
      case TimeSeriesWindow.DAY:
        return 'day'
      case TimeSeriesWindow.WEEK:
        return 'week'
      case TimeSeriesWindow.MONTH:
        return 'month'
    }
  })()

  return `DATE_TRUNC('${unit}', epoch_ms(CAST(round(epoch_ms(${unixTimeColumnName})) as LONG) )) as time_window`
}
const getSeriesInterval = (timeWindow: TimeSeriesWindow) => {
  switch (timeWindow) {
    case TimeSeriesWindow.HOUR:
      return `interval '1' hour`
    case TimeSeriesWindow.DAY:
      return `interval '1' day`
    case TimeSeriesWindow.WEEK:
      return `interval '7' day`
    case TimeSeriesWindow.MONTH:
      return `interval '1' month`
  }
}

export interface Dialect {
  STRUCT: string
  EXPLODE: string
  UNNEST: string
  COLLECT_LIST: string
  LIST: string
  ARRAY_AGG: string
}
export const sparkDialect: Dialect = {
  STRUCT: 'STRUCT',
  EXPLODE: 'EXPLODE',
  UNNEST: 'EXPLODE',
  COLLECT_LIST: 'COLLECT_LIST',
  LIST: 'COLLECT_LIST',
  ARRAY_AGG: 'ARRAY_AGG',
}
export const duckDialect: Dialect = {
  STRUCT: 'ROW',
  EXPLODE: 'UNNEST',
  UNNEST: 'UNNEST',
  COLLECT_LIST: 'ARRAY_AGG',
  LIST: 'ARRAY_AGG',
  ARRAY_AGG: 'ARRAY_AGG',
}

/**
 * This function builds  aggregates into an array the dimensions and
 * the time window.
 *
 * NumberOfMyColumnList AS (
 *         SELECT
 *         ARRAY_AGG(my_column) as y,
 *         ARRAY_AGG(time_window) as x
 *         FROM NumberOf
 *     )
 *
 *     SELECT STRUCT_PACK(
 *         x := NumberOfMyColumnList.x,
 *         y := NumberOfMyColumnList.y
 *     ) as number_of_files_read,
 *
 * @param columns the list of the columns we want to aggregate and pa
 * @param fromTable the
 */
export const packColumns = (columns: string[], fromTable: string) => {
  const withPart = columns
    .map(
      (column) =>
        `STRUCT_PACK( 
        x := ARRAY_AGG(time_window),
        y :=ARRAY_AGG(${column})) as ${column} `
    )
    .join(',\n')
  return `
  SELECT  ${withPart}
  FROM ${fromTable}`
}

const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .split('_')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join('')

const lowerToUpperCamel = (str: string) => str.charAt(0).toUpperCase() + str.slice(1)
