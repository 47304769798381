import * as remoteApi from './spark-api'
import { getConfig } from '../config/environment-config'
import { sleep } from '../utils/sleep-utils'
import { MOCKED_CATALOGS } from './mocks/mocked-catalogs'
import { CatalogsResponseModel } from './response-models'
import { WebClientResultWithData } from './spark-api'

export const getCatalogs = async (accessToken: string): Promise<WebClientResultWithData<CatalogsResponseModel>> => {
  if (getConfig().shouldCallMockedBackend) {
    await sleep(500)
    return {
      hasFailed: false,
      data: MOCKED_CATALOGS,
    }
  } else {
    const result: WebClientResultWithData<CatalogsResponseModel> = await remoteApi.getCatalogs(accessToken)
    return result
  }
}
