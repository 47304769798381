import { useDispatch, useSelector } from 'react-redux'
import { loadGlobalIngestion, WebClientResultWithoutData } from '../../../../transport/spark-api'
import { actions as modalActions } from '../../../../state/slices/modal-slice'
import * as Sentry from '@sentry/react'
import {
  actions,
  selectIsGlobalIngestionInsightsFetched,
} from '../../../../state/slices/global-ingestion-insights-slice'

export const useFetchGlobalIngestionInsights = () => {
  const dispatch = useDispatch()

  const isFetched = useSelector(selectIsGlobalIngestionInsightsFetched)
  return async (accessToken: string) => {
    if (isFetched) return
    dispatch(actions.startFetchingGlobalIngestionInsights())
    const result: WebClientResultWithoutData = await loadGlobalIngestion(accessToken)
    if (result.hasFailed) {
      dispatch(actions.setNotAvailableFromBackend())
      Sentry.captureException(
        'There has been an error when retrieving global ingestion insights. ' + JSON.stringify(result)
      )
    } else {
      dispatch(actions.addGlobalIngestionInsights())
    }
  }
}
