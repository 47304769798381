import { useEffect, useRef, useState } from 'react'
import { TimeSeriesType } from './timeline-types'
import { ArrowDown, DropdownItem } from './timeline-atoms'
import { TimeSeriesWindow } from '../../../data-explorer/data-explorer-right-side/table-details/types'

const timeSeriesWindowToString = (timeSeriesWindow: TimeSeriesWindow) => {
  switch (timeSeriesWindow) {
    case TimeSeriesWindow.MONTH:
      return 'Month'
    case TimeSeriesWindow.WEEK:
      return 'Weeks'
    case TimeSeriesWindow.DAY:
      return 'Days'
    default:
      return 'Hours'
  }
}

interface TimelineAggregationDropdownsProps {
  onTimeSeriesWindowChange: (timeSeriesWindow: TimeSeriesWindow) => void
  currentTimeSeriesWindow: TimeSeriesWindow
}

export const TimelineAggregationDropdown = ({
  onTimeSeriesWindowChange,
  currentTimeSeriesWindow,
}: TimelineAggregationDropdownsProps) => {
  const [timeSeriesWindow, setTimeSeriesWindow] = useState<TimeSeriesWindow>(currentTimeSeriesWindow)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const dropdownListRef = useRef<HTMLDivElement>(null)
  const dropdownButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    onTimeSeriesWindowChange(timeSeriesWindow)
  }, [timeSeriesWindow])

  const handleClickOutside = (event: MouseEvent) => {
    const isClickOnLeftDropdownButtonOrList =
      dropdownListRef &&
      dropdownListRef.current &&
      !dropdownListRef.current.contains(event.target as Node) &&
      !dropdownButtonRef.current.contains(event.target as Node)
    if (isOpen && isClickOnLeftDropdownButtonOrList) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isOpen])

  const handleEscapeDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsOpen(false)
    }
  }

  const onChoiceClick = (timeSeriesWindow: TimeSeriesWindow) => {
    onTimeSeriesWindowChange(timeSeriesWindow)
    setTimeSeriesWindow(timeSeriesWindow)
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeDown)
    return () => {
      document.removeEventListener('keydown', handleEscapeDown)
    }
  }, [])

  return (
    <div className='mt-4 flex flex-row justify-center gap-x-4'>
      <div className='relative flex flex-col gap-y-2'>
        <button
          ref={dropdownButtonRef}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
          id='leftDropdownButton'
          className='w-68 flex flex-row items-center justify-between rounded-lg bg-primary px-5 py-2.5 text-center text-sm font-medium text-white duration-300 hover:shadow-lg'
        >
          Aggregate by {timeSeriesWindowToString(timeSeriesWindow)}
          <ArrowDown />
        </button>

        {isOpen && (
          <div
            className='absolute left-10 top-12 z-10 w-28 divide-y divide-gray-100 rounded-lg bg-white shadow'
            ref={dropdownListRef}
          >
            <ul className='py-2 text-sm text-gray-700' aria-labelledby='leftDropdownButton'>
              {[TimeSeriesWindow.HOUR, TimeSeriesWindow.DAY, TimeSeriesWindow.WEEK, TimeSeriesWindow.MONTH].map(
                (key) => (
                  <DropdownItem
                    key={key}
                    isDisabled={timeSeriesWindow === key}
                    handleClick={() => onChoiceClick(key)}
                    text={timeSeriesWindowToString(key)}
                  />
                )
              )}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}
