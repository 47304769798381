import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app-store'
import { getConfig } from '../../config/environment-config'

export interface AccountState {
  id: string
  name: string
  lastName: string
  email: string
  accessToken: string
  picture: string
}

const initialState: AccountState = {
  id: null,
  name: null,
  lastName: null,
  email: null,
  accessToken: null,
  picture: null,
}
export interface SetLoggedInPayload {
  id: string
  email: string
  name: string
  lastName: string
  picture: string
}

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setLoggedIn: (state, action: PayloadAction<SetLoggedInPayload>) => {
      state.id = action.payload.id
      state.name = action.payload.name
      state.lastName = action.payload.lastName
      state.email = action.payload.email
      state.picture = action.payload.picture
    },
    setAccessToken: (state, action: PayloadAction<{ accessToken: string }>) => {
      state.accessToken = action.payload.accessToken
    },
    setNotLoggedIn: (state) => {
      state.id = null
      state.name = null
      state.email = null
      state.accessToken = null
    },
  },
})

export const selectIsSignedIn = (state: RootState): boolean => !!state.account.accessToken

/**
 shouldRequireLogin will be set to false on development environment.
 In this case, we return a fake string so that the UI can work.

 In all other cases, we return the accessToken, which can be null
*/
export const selectAccountAccessToken = (state: RootState): string => {
  return getConfig().shouldRequireLogin === false ? 'NOT_REQUIRED' : state.account.accessToken
}
export const selectAccountData = (state: RootState): AccountState => state.account
export const selectHasUsedGoogleToLogIn = (state: RootState): boolean =>
  state.account.id && state.account.id.includes('google')

export const selectHasUsedEmailToLogIn = (state: RootState): boolean =>
  state.account.id && state.account.id.includes('auth0')

export const { setNotLoggedIn, setLoggedIn, setAccessToken } = accountSlice.actions

export default accountSlice.reducer
