import { getConfig } from '../config/environment-config'
import * as Sentry from '@sentry/react'

export const initializeSentry = () => {
  if (getConfig().sentryDataSourceName) {
    Sentry.init({
      dsn: getConfig().sentryDataSourceName,
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', 'cloud.qbeast.io', 'ermesdemouse.cloud.qbeast.io'],
        }),
        new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    })
  }
}
