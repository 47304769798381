import { DataExplorer } from './data-explorer'
import { NavigationWrapper } from '../common/app-navigation/navigation-wrapper'

export const DataExplorerView = () => {
  return (
    <NavigationWrapper>
      <DataExplorer />
    </NavigationWrapper>
  )
}
