import { configureStore } from '@reduxjs/toolkit'
import catalogsSlice from './slices/catalogs-slice'
import accountSlice from './slices/account-slice'
import consumptionInsightsSlice from './slices/consumption-insights-slice'
import { ReducerBuilder } from './reducer-builder'
import { AppReducer } from './app-reducer'
import tableInsightsSlice from './slices/table-insights-slice'
import ingestionInsightsSlice from './slices/ingestion-insights-slice'
import modalSlice from './slices/modal-slice'
import globalIngestionInsightsSlice from './slices/global-ingestion-insights-slice'
import globalTableInsightsSlice from './slices/global-files-insights-slice'

const reducerBuilder = new ReducerBuilder()
reducerBuilder.setCatalogsReducer(catalogsSlice)
reducerBuilder.setAccountReducer(accountSlice)
reducerBuilder.setConsumptionInsightsReducer(consumptionInsightsSlice)
reducerBuilder.setIngestionInsightsReducer(ingestionInsightsSlice)
reducerBuilder.setGlobalIngestionInsightsReducer(globalIngestionInsightsSlice)
reducerBuilder.setGlobalFilesInsightsReducer(globalTableInsightsSlice)
reducerBuilder.setTableInsightsReducer(tableInsightsSlice)
reducerBuilder.setModalReducer(modalSlice)
const appReducer: AppReducer = reducerBuilder.build()
export const createStore = (reducer: AppReducer) =>
  configureStore({
    reducer: reducer,
  })

export const appStore = createStore(appReducer)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appStore.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof appStore.dispatch
