import {
  addCatalogs,
  selectAreAlreadyFetched,
  selectIsFetchingCatalogs,
  startFetchingCatalogs,
  stopFetchingCatalogs,
} from '../../state/slices/catalogs-slice'
import { CatalogsResponseModel } from '../../transport/response-models'
import * as apiProvider from '../../transport/api-provider'
import { catalogsResponseToStoreMapper } from '../../state/http-response-to-store-mapper'
import { useDispatch, useSelector } from 'react-redux'
import { WebClientResultWithData } from '../../transport/spark-api'
import { actions } from '../../state/slices/modal-slice'
import * as Sentry from '@sentry/react'

export const useFetchCatalogs = (force: boolean = false) => {
  const dispatch = useDispatch()
  const isFetching = useSelector(selectIsFetchingCatalogs)
  const areAlreadyFetched = useSelector(selectAreAlreadyFetched)

  return async (accessToken: string) => {
    if (!isFetching && (!areAlreadyFetched || force)) {
      dispatch(startFetchingCatalogs())
      try {
        const catalogCallResult: WebClientResultWithData<CatalogsResponseModel> =
          await apiProvider.getCatalogs(accessToken)
        if (catalogCallResult.hasFailed) {
          dispatch(
            actions.showModal({ message: 'There has been an error when retrieving catalogs. Please reload the page.' })
          )
          Sentry.captureException(
            'There has been an error when retrieving catalogs. ' + JSON.stringify(catalogCallResult)
          )
          dispatch(stopFetchingCatalogs())
        } else {
          const backendCatalogs = catalogCallResult.data
          dispatch(addCatalogs(catalogsResponseToStoreMapper(backendCatalogs)))
        }
      } catch {
        dispatch(stopFetchingCatalogs())
      }
    }
  }
}
