import { TooltipItem } from 'chart.js'
import Chart from 'chart.js/auto'
import { useEffect, useRef, useState } from 'react'
import { BRAND_COLORS } from '../../../../constants/brand-colors'
import {
  bytesToReadable,
  parseNumberFromStringWithComas,
  timestampToReadableDateAndTimeInTwoLines,
  timestampToReadableDatetime,
  toMaxTwoFloatingPoints,
} from '../../../../utils/conversion-utils'
import { ChartNavigationButtons } from './chart-navigation-buttons'
import { ChartCanvas } from '../chart-canvas'
import { useAddEventsForChartZoom } from '../use-add-events-for-chart-zoom'
import { ChartDataPoint } from '../types'
import { TimelineComparisonDropdowns } from './timeline-comparison-dropdowns'
import { CustomVerticalScale, TimeSeriesData, TimeSeriesType } from './timeline-types'
import { initializeChartjsIfNotInitialized } from '../chart-initializer'
import { TimeSeriesWindow } from '../../../data-explorer/data-explorer-right-side/table-details/types'
import { getZoomConfig } from '../chart-utils'
import { TimelineAggregationDropdown } from './timeline-aggregation-dropdown'

initializeChartjsIfNotInitialized()

interface TimelineProps {
  canvasId: string
  tableName: string
  timeSeriesData: TimeSeriesData
  onTimeSeriesWindowChange: (timeSeriesWindow: TimeSeriesWindow) => void
  currentTimeSeriesWindow: TimeSeriesWindow
  initialLeftTimeSeries: TimeSeriesType
  initialRightTimeSeries: TimeSeriesType
  isOverviewMode?: boolean
}

interface CustomDataset {
  id: string
}

const getDatasetBelongingToLeftYAxis = (label: string, data: ChartDataPoint[], timeSeriesType: TimeSeriesType) => ({
  ...getDatasetCommonConfig(label, data, timeSeriesType),
  borderColor: BRAND_COLORS.ACCENT,
  yAxisID: 'left-axis',
})
const getDatasetBelongingToRightYAxis = (label: string, data: ChartDataPoint[], timeSeriesType: TimeSeriesType) => ({
  ...getDatasetCommonConfig(label, data, timeSeriesType),
  borderColor: BRAND_COLORS.SECONDARY,
  yAxisID: 'right-axis',
})

const getDatasetCommonConfig = (label: string, data: ChartDataPoint[], timeSeriesType: TimeSeriesType) => ({
  label,
  data,
  tension: 0.2,
  id: timeSeriesType,
  pointRadius: 4,
  pointHitRadius: 4,
})

const getLeftYAxis = (
  correspondingTimeSeriesType: TimeSeriesType,
  timeSeriesData: TimeSeriesData
): CustomVerticalScale => {
  const result: CustomVerticalScale = {
    position: 'left',
    ticks:
      correspondingTimeSeriesType === TimeSeriesType.BYTES
        ? {
            color: BRAND_COLORS.ACCENT,
            callback: (value: string | number) => bytesToReadable(value as number),
          }
        : { color: BRAND_COLORS.ACCENT },
    title: {
      display: true,
      color: BRAND_COLORS.ACCENT,
      text: timeSeriesData[correspondingTimeSeriesType].label,
    },
  }
  return result
}

const getRightYAxis = (
  correspondingTimeSeriesType: TimeSeriesType,
  timeSeriesData: TimeSeriesData
): CustomVerticalScale => {
  const result: CustomVerticalScale = {
    position: 'right',
    ticks:
      correspondingTimeSeriesType === TimeSeriesType.BYTES
        ? {
            color: BRAND_COLORS.SECONDARY,
            callback: (value: string | number) => bytesToReadable(value as number),
          }
        : { color: BRAND_COLORS.SECONDARY },
    title: {
      display: true,
      color: BRAND_COLORS.SECONDARY,
      text: timeSeriesData[correspondingTimeSeriesType].label,
    },
  }
  return result
}

export const Timeline = ({
  canvasId,
  tableName,
  timeSeriesData,
  onTimeSeriesWindowChange,
  currentTimeSeriesWindow,
  initialLeftTimeSeries,
  initialRightTimeSeries,
  isOverviewMode = false,
}: TimelineProps) => {
  const { chartNavigationMode, setChartNavigationMode } = useAddEventsForChartZoom()

  const chartRef = useRef<Chart>(null)

  const [chosenLeftTimeSeries, setChosenLeftTimeSeries] = useState<TimeSeriesType>(initialLeftTimeSeries)
  const [chosenRightTimeSeries, setChosenRightTimeSeries] = useState<TimeSeriesType>(initialRightTimeSeries)

  const resetTimeline = () => {
    if (chartRef.current) {
      chartRef.current.destroy()
    }

    chartRef.current = new Chart(document.getElementById(canvasId) as HTMLCanvasElement, {
      type: 'line',
      data: {
        datasets: [
          getDatasetBelongingToLeftYAxis(
            timeSeriesData[chosenLeftTimeSeries].label,
            timeSeriesData[chosenLeftTimeSeries].data,
            chosenLeftTimeSeries
          ),
          getDatasetBelongingToRightYAxis(
            timeSeriesData[chosenRightTimeSeries].label,
            timeSeriesData[chosenRightTimeSeries].data,
            chosenRightTimeSeries
          ),
        ],
      },
      options: {
        plugins: {
          zoom: getZoomConfig(chartNavigationMode, canvasId),
          tooltip: {
            titleFont: {
              size: 16,
            },
            bodyFont: {
              size: 16,
            },
            callbacks: {
              title: (context: TooltipItem<'line'>[]) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const datasetId = (context[0].dataset as CustomDataset).id
                if (datasetId === TimeSeriesType.BYTES) {
                  return bytesToReadable((context[0].raw as ChartDataPoint).y)
                } else {
                  return toMaxTwoFloatingPoints((context[0].raw as ChartDataPoint).y)
                }
              },
              label: (context) => {
                return timestampToReadableDatetime(parseNumberFromStringWithComas(context.label))
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 1000,
          easing: 'easeInOutCirc',
        },
        animations: {
          tension: {
            duration: 1000,
            easing: 'easeInOutCirc',
            from: 0,
            to: 0.2,
          },
        },
        scales: {
          x: {
            type: 'linear',
            ticks: {
              callback: (value) => {
                return timestampToReadableDateAndTimeInTwoLines(value as number)
              },
            },
          },
          'left-axis': getLeftYAxis(chosenLeftTimeSeries, timeSeriesData),
          'right-axis': getRightYAxis(chosenRightTimeSeries, timeSeriesData),
        },
      },
    })
  }

  useEffect(() => {
    resetTimeline()
  }, [tableName])

  useEffect(() => {
    chartRef.current.options.plugins.zoom = getZoomConfig(chartNavigationMode, canvasId)
    chartRef.current.update('none')
  }, [chartNavigationMode])

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
      }
    }
  }, [])

  useEffect(() => {
    const newDataset = []
    newDataset.push(
      getDatasetBelongingToLeftYAxis(
        timeSeriesData[chosenLeftTimeSeries].label,
        timeSeriesData[chosenLeftTimeSeries].data,
        chosenLeftTimeSeries
      )
    )
    newDataset.push(
      getDatasetBelongingToRightYAxis(
        timeSeriesData[chosenRightTimeSeries].label,
        timeSeriesData[chosenRightTimeSeries].data,
        chosenRightTimeSeries
      )
    )
    chartRef.current.data.datasets = newDataset
    chartRef.current.options.scales['left-axis'] = getLeftYAxis(chosenLeftTimeSeries, timeSeriesData)
    chartRef.current.options.scales['right-axis'] = getRightYAxis(chosenRightTimeSeries, timeSeriesData)
    chartRef.current.update()
  }, [chosenLeftTimeSeries, chosenRightTimeSeries, timeSeriesData])

  const handleTimeSeriesChoice = (choices: [TimeSeriesType, TimeSeriesType]) => {
    setChosenLeftTimeSeries(choices[0])
    setChosenRightTimeSeries(choices[1])
  }

  return (
    <div className='w-full'>
      <ChartCanvas canvasId={canvasId} />
      {!isOverviewMode && (
        <>
          <TimelineAggregationDropdown
            onTimeSeriesWindowChange={onTimeSeriesWindowChange}
            currentTimeSeriesWindow={currentTimeSeriesWindow}
          />
          <TimelineComparisonDropdowns
            onTimeSeriesChoice={handleTimeSeriesChoice}
            rightTimeSeries={chosenLeftTimeSeries}
            leftTimeSeries={chosenRightTimeSeries}
            possibleChoices={Object.keys(timeSeriesData) as TimeSeriesType[]}
          />
          <ChartNavigationButtons
            handleNavigationButtonClick={setChartNavigationMode}
            chartNavigationMode={chartNavigationMode}
            handleResetHistogram={resetTimeline}
          />
        </>
      )}
    </div>
  )
}
