import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app-store'
import { TimeSeries } from '../../transport/response-models'
import { ChartDataPoint } from '../../components/common/chart/types'
import { zipTimeSeriesOrGetEmptyIfFalsy } from '../../utils/array-utils'
import { TimeSeriesWindow } from '../../components/data-explorer/data-explorer-right-side/table-details/types'
import { IngestionTimeSeries } from '../../transport/data-transformers/ingestion-insights/ingestion-insights-data-transformer'

export interface IngestionInsightsState {
  namesOfFetchedTables: string[]
  namesOfTablesBeingFetched: string[]
  areIngestionTimeSeriesBeingCalculated: boolean
  isFetched: boolean
  ingestionTimeSeries: IngestionTimeSeries
  timeSeriesWindow: TimeSeriesWindow
}

const initialState: IngestionInsightsState = {
  namesOfFetchedTables: [],
  namesOfTablesBeingFetched: [],
  areIngestionTimeSeriesBeingCalculated: false,
  isFetched: false,
  ingestionTimeSeries: null,
  timeSeriesWindow: TimeSeriesWindow.WEEK,
}

export interface AddIngestionInsightsPayload {
  ingestionTimeSeries: IngestionTimeSeries
  forTimeSeriesWindow: TimeSeriesWindow
}

const ingestionInsightsSlice = createSlice({
  name: 'ingestionInsights',
  initialState,
  reducers: {
    startFetchingIngestionInsights: (state, { payload }: PayloadAction<string>) => {
      state.namesOfTablesBeingFetched.push(payload)
    },
    addIngestionInsights: (state, { payload }: PayloadAction<string>) => {
      const nameOfFetchedTable = payload
      state.namesOfFetchedTables.push(nameOfFetchedTable)
      state.namesOfTablesBeingFetched = state.namesOfTablesBeingFetched.filter((name) => name !== nameOfFetchedTable)
    },
    startCalculatingIngestionInsightsTimeSeries: (state) => {
      state.ingestionTimeSeries = null
    },
    addIngestionInsightsTimeLine: (
      state,
      { payload: { ingestionTimeSeries, forTimeSeriesWindow } }: PayloadAction<AddIngestionInsightsPayload>
    ) => {
      if (state.timeSeriesWindow === forTimeSeriesWindow) {
        state.ingestionTimeSeries = ingestionTimeSeries
        state.timeSeriesWindow = forTimeSeriesWindow
      }
    },
    chooseTimeSeriesWindow: (state, { payload }: PayloadAction<TimeSeriesWindow>) => {
      state.timeSeriesWindow = payload
    },
  },
})

const selectAverageDuration = (state: RootState): TimeSeries<number> =>
  state.ingestionInsights.ingestionTimeSeries?.averageDuration

const selectCpuConsumption = (state: RootState): TimeSeries<number> =>
  state.ingestionInsights.ingestionTimeSeries?.cpuConsumption
const selectBytesIngested = (state: RootState): TimeSeries<number> =>
  state.ingestionInsights.ingestionTimeSeries?.bytesIngested
const selectFilesIngested = (state: RootState): TimeSeries<number> =>
  state.ingestionInsights.ingestionTimeSeries?.numberOfFilesIngested

const selectMaxIngestionDelay = (state: RootState): TimeSeries<number> =>
  state.ingestionInsights.ingestionTimeSeries?.maxIngestionDelay
const selectNamesOfIngestionInsightsTablesBeingFetched = (state: RootState): string[] =>
  state.ingestionInsights.namesOfTablesBeingFetched

const selectNamesOfFetchedTables = (state: RootState): string[] => state.ingestionInsights.namesOfFetchedTables

export const selectAreIngestionInsightsFetched: (state: RootState) => (tableName: string) => boolean = createSelector(
  selectNamesOfFetchedTables,
  (namesOfFetchedTables) => {
    return (tableName: string) => {
      return namesOfFetchedTables.includes(tableName)
    }
  }
)
export const selectShouldFetchIngestionTableWithName: (state: RootState) => (tableName: string) => boolean =
  createSelector(
    selectNamesOfIngestionInsightsTablesBeingFetched,
    selectNamesOfFetchedTables,
    (namesOfTablesBeingFetched, namesOfFetchedTables) => {
      return (tableName: string) => {
        return !namesOfTablesBeingFetched.includes(tableName) && !namesOfFetchedTables.includes(tableName)
      }
    }
  )
export const selectIngestionTimeSeriesWindow = (state: RootState): TimeSeriesWindow =>
  state.ingestionInsights.timeSeriesWindow

export const selectIngestionInsightsAverageDurationData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectAverageDuration,
  (averageDuration: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(averageDuration)
)
export const selectIngestionInsightsCpuConsumptionData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectCpuConsumption,
  (cpuDuration: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(cpuDuration)
)
export const selectIngestionInsightsFilesIngestedData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectFilesIngested,
  (filesIngested: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(filesIngested)
)
export const selectIngestionInsightsBytesIngestedData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectBytesIngested,
  (bytesIngested: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(bytesIngested)
)

export const selectIngestionInsightsMaxIngestionDelayData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectMaxIngestionDelay,
  (maxIngestionDelay: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(maxIngestionDelay)
)

export const selectIsIngestionInsightsTimelineCalculated = (state: RootState): boolean => {
  return state.ingestionInsights.ingestionTimeSeries !== null
}

export const actions = ingestionInsightsSlice.actions
export default ingestionInsightsSlice.reducer
