import { useDispatch, useSelector } from 'react-redux'
import { loadGlobalFiles, WebClientResultWithoutData } from '../../../../transport/spark-api'
import { actions as modalActions } from '../../../../state/slices/modal-slice'
import * as Sentry from '@sentry/react'
import { actions, selectShouldFetchGlobalFiles } from '../../../../state/slices/global-files-insights-slice'

export const useFetchGlobalFilesInsights = () => {
  const dispatch = useDispatch()
  const shouldFetch = useSelector(selectShouldFetchGlobalFiles)

  return async (accessToken: string) => {
    if (shouldFetch) {
      dispatch(actions.startGlobalFetchingFilesInsights())
      const result: WebClientResultWithoutData = await loadGlobalFiles(accessToken)
      if (result.hasFailed) {
        dispatch(
          modalActions.showModal({
            message: 'There has been an error when retrieving table insights. Please reload the page.',
          })
        )
        Sentry.captureException('There has been an error when retrieving table insights. ' + JSON.stringify(result))
      } else {
        dispatch(actions.addGlobalFilesInsights())
      }
    }
  }
}
