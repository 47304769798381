import { OptimizationEvent } from '../../../transport/data-transformers/optimization-events/optimization-events-data-transformer'
import { bytesToReadable, timestampToReadableDatetime } from '../../../utils/conversion-utils'

interface OptimizationTableProps {
  data: OptimizationEvent[]
}

const OptimizationTable: React.FC<OptimizationTableProps> = ({ data }) => {
  return (
    <div className='overflow-x-auto'>
      <table className='min-w-full border border-gray-300 bg-white'>
        <thead>
          <tr>
            <th className='border-b-2 border-gray-300 px-4 py-2'>Start Time</th>
            <th className='border-b-2 border-gray-300 px-4 py-2'>Duration (s)</th>
            <th className='border-b-2 border-gray-300 px-4 py-2'>Optimization Method</th>
            <th className='border-b-2 border-gray-300 px-4 py-2'>Optimized Files</th>
            <th className='border-b-2 border-gray-300 px-4 py-2'>Optimized Bytes</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <tr key={index} className='hover:bg-gray-100'>
              <td className='border-b border-gray-300 px-4 py-2'>{timestampToReadableDatetime(row.started_at)}</td>
              <td className='border-b border-gray-300 px-4 py-2'>{row.duration}</td>
              <td className='border-b border-gray-300 px-4 py-2'>{row.optimization_name}</td>
              <td className='border-b border-gray-300 px-4 py-2'>{row.num_files}</td>
              <td className='border-b border-gray-300 px-4 py-2'>{bytesToReadable(row.bytes)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default OptimizationTable
