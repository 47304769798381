import { StatsCard } from './stats-card'
import { TableInsightsHistogram } from './table-insights-histogram'
import { Stats } from '../../../../../transport/response-models'
import { Period } from '../types'
import { H2PageTitle } from '../../../atoms'

export const TableInsightsMetric = ({
  data,
  canvasId,
  conversionFunction,
  label,
  labelForTotal,
  labelForCount,
  labelForBarHover,
  period,
  handlePeriodClick,
}: {
  data: Stats<number>
  canvasId: string
  conversionFunction: (value: number) => string
  label: string
  labelForTotal: string
  labelForCount: string
  labelForBarHover: string
  period: Period
  handlePeriodClick: (period: Period) => void
}) => {
  return (
    <>
      <div className='flex w-full flex-col items-center gap-4'>
        <H2PageTitle title={label} />
        <div className='flex flex-row'>
          <div
            className={
              'h-7 cursor-pointer rounded-l border-b-2 border-l-2 border-t-2 border-primaryLight px-4 duration-75 hover:bg-primaryLight' +
              (period === Period.LAST_6HOUR ? ' bg-primaryLight text-white' : ' bg-white text-black hover:text-white')
            }
            onClick={() => {
              handlePeriodClick(Period.LAST_6HOUR)
            }}
          >
            6HR
          </div>
          <div
            className={
              'h-7 cursor-pointer border-b-2 border-l-2 border-t-2 border-primaryLight px-4 duration-75 hover:bg-primaryLight' +
              (period === Period.LAST_DAY ? ' bg-primaryLight text-white' : ' bg-white text-black hover:text-white')
            }
            onClick={() => {
              handlePeriodClick(Period.LAST_DAY)
            }}
          >
            1D
          </div>
          <div
            className={
              'h-7 cursor-pointer border-b-2 border-l-2 border-t-2 border-primaryLight px-4 duration-75 hover:bg-primaryLight' +
              (period === Period.LAST_WEEK ? ' bg-primaryLight text-white' : ' bg-white text-black hover:text-white')
            }
            onClick={() => {
              handlePeriodClick(Period.LAST_WEEK)
            }}
          >
            7D
          </div>
          <div
            className={
              'h-7 cursor-pointer border-b-2 border-l-2 border-t-2 border-primaryLight px-4 duration-75 hover:bg-primaryLight' +
              (period === Period.LAST_MONTH ? ' bg-primaryLight text-white' : ' bg-white text-black hover:text-white')
            }
            onClick={() => {
              handlePeriodClick(Period.LAST_MONTH)
            }}
          >
            1M
          </div>
          <div
            className={
              'h-7 cursor-pointer rounded-r border-2 border-primaryLight px-4 transition duration-75 hover:bg-primaryLight ' +
              (period === Period.ALL ? ' bg-primaryLight text-white' : ' bg-white text-black hover:text-white')
            }
            onClick={() => {
              handlePeriodClick(Period.ALL)
            }}
          >
            All
          </div>
        </div>
      </div>
      <div className='mb-2 mt-4 hidden w-full flex-row justify-center gap-2 p-4 xl:flex'>
        <StatsCard title={`Min ${label}`} value={`${conversionFunction(data.min)}`} />
        <StatsCard title={`Max ${label}`} value={`${conversionFunction(data.max)}`} />
        <StatsCard title={`Average ${label}`} value={`${conversionFunction(data.avg)}`} />
        <StatsCard title={`Total ${labelForTotal}`} value={`${conversionFunction(data.total)}`} />
        <StatsCard title={labelForCount} value={`${data.count}`} />
      </div>
      <div className='mb-4 mt-8 flex w-full flex-col items-center gap-4 p-8 xl:hidden'>
        <div className='flex flex-row gap-4'>
          <StatsCard title={`Min ${label}`} value={`${conversionFunction(data.min)}`} />
          <StatsCard title={`Max ${label}`} value={`${conversionFunction(data.max)}`} />
          <StatsCard title={`Average ${label}`} value={`${conversionFunction(data.avg)}`} />
        </div>
        <div className='flex flex-row gap-4'>
          <StatsCard title={`Total ${labelForTotal}`} value={`${conversionFunction(data.total)}`} />
          <StatsCard title={labelForCount} value={`${data.count}`} />
        </div>
      </div>
      <div className='mb-20 flex h-full w-10/12 flex-row items-center justify-center 3xl:w-3/4'>
        <TableInsightsHistogram
          stats={data}
          canvasId={canvasId}
          label={label}
          labelForBarHover={labelForBarHover}
          conversionFunction={conversionFunction}
        />
      </div>
    </>
  )
}
