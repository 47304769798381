import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app-store'
import { TimeSeries } from '../../transport/response-models'
import { ChartDataPoint } from '../../components/common/chart/types'
import { zipTimeSeriesOrGetEmptyIfFalsy } from '../../utils/array-utils'
import { TimeSeriesWindow } from '../../components/data-explorer/data-explorer-right-side/table-details/types'
import { GlobalIngestionTimeSeries } from '../../transport/data-transformers/global-ingestion-insights/global-ingestion-insights-data-transformer'

export interface GlobalIngestionInsightsState {
  areIngestionTimeSeriesBeingCalculated: boolean
  isFetched: boolean
  isFetching: boolean
  isNotAvailableFromBackend: boolean
  globalIngestionTimeSeries: GlobalIngestionTimeSeries
  timeSeriesWindow: TimeSeriesWindow
}

const initialState: GlobalIngestionInsightsState = {
  areIngestionTimeSeriesBeingCalculated: false,
  isFetched: false,
  isFetching: false,
  isNotAvailableFromBackend: false,
  globalIngestionTimeSeries: null,
  timeSeriesWindow: TimeSeriesWindow.HOUR,
}

export interface AddGlobalIngestionInsightsPayload {
  globalIngestionTimeSeries: GlobalIngestionTimeSeries
  forTimeSeriesWindow: TimeSeriesWindow
}

const globalIngestionInsightsSlice = createSlice({
  name: 'GlobalIngestionInsights',
  initialState,
  reducers: {
    startFetchingGlobalIngestionInsights: (state) => {
      state.isFetching = true
    },
    addGlobalIngestionInsights: (state) => {
      state.isFetching = false
      state.isFetched = true
    },
    setNotAvailableFromBackend: (state) => {
      state.isNotAvailableFromBackend = true
    },
    startCalculatingGlobalIngestionInsightsTimeSeries: (state) => {
      state.globalIngestionTimeSeries = null
    },
    addGlobalIngestionInsightsTimeLine: (
      state,
      { payload: { globalIngestionTimeSeries, forTimeSeriesWindow } }: PayloadAction<AddGlobalIngestionInsightsPayload>
    ) => {
      if (state.timeSeriesWindow === forTimeSeriesWindow) {
        state.globalIngestionTimeSeries = globalIngestionTimeSeries
        state.timeSeriesWindow = forTimeSeriesWindow
      }
    },
    chooseTimeSeriesWindow: (state, { payload }: PayloadAction<TimeSeriesWindow>) => {
      state.timeSeriesWindow = payload
    },
  },
})

const selectTotalInputBytes = (state: RootState): TimeSeries<number> =>
  state.globalIngestionInsights.globalIngestionTimeSeries?.totalInputBytes

const selectTotalInputFiles = (state: RootState): TimeSeries<number> =>
  state.globalIngestionInsights.globalIngestionTimeSeries?.totalInputFiles
const selectNumberOfIngestedTable = (state: RootState): TimeSeries<number> =>
  state.globalIngestionInsights.globalIngestionTimeSeries?.numberOfIngestedTable

export const selectIsGlobalIngestionInsightsFetched = (state: RootState): boolean =>
  state.globalIngestionInsights.isFetched
export const selectGlobalIngestionTimeSeriesWindow = (state: RootState): TimeSeriesWindow =>
  state.globalIngestionInsights.timeSeriesWindow

export const selectGlobalIngestionInsightsTotalInputBytes: (state: RootState) => ChartDataPoint[] = createSelector(
  selectTotalInputBytes,
  (bytesIngested: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(bytesIngested)
)
export const selectGlobalIngestionInsightsTotalInputFiles: (state: RootState) => ChartDataPoint[] = createSelector(
  selectTotalInputFiles,
  (cpuDuration: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(cpuDuration)
)
export const selectGlobalIngestionInsightsNumberOfIngestedTable: (state: RootState) => ChartDataPoint[] =
  createSelector(selectNumberOfIngestedTable, (filesIngested: TimeSeries<number>) =>
    zipTimeSeriesOrGetEmptyIfFalsy(filesIngested)
  )

export const selectIsGlobalIngestionInsightsTimelineCalculated = (state: RootState): boolean => {
  return state.globalIngestionInsights.globalIngestionTimeSeries !== null
}

export const selectGlobalIngestionInsightsNotAvailableFromBackend = (state: RootState): boolean => {
  return state.globalIngestionInsights.isNotAvailableFromBackend
}

export const actions = globalIngestionInsightsSlice.actions
export default globalIngestionInsightsSlice.reducer
