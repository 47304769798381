import {
  Cell2,
  ColumnTitle2,
  Table,
  TableBodyRow,
  TableHeadRow,
  TableWrapper,
} from '../../../../common/table/table-atoms'
import { useSelector } from 'react-redux'
import { selectChosenTable } from '../../../../../state/slices/catalogs-slice'
import { timestampToReadableDatetime } from '../../../../../utils/conversion-utils'

const MetadataDetailsTableRow = ({ title, value }: { title: string; value: string }) => (
  <TableBodyRow>
    <Cell2 text={title} />
    <Cell2 text={value} />
  </TableBodyRow>
)
export const TableMetadataDetails = () => {
  const { id, description, tableType, owner, columns, createdTime, createdBy, location, index, cubeSize } =
    useSelector(selectChosenTable)

  return (
    <div className='mt-16 flex w-full'>
      <div className='xl:w-1/6 2xl:w-1/4' />
      <div className='flex w-full flex-col items-center justify-center xl:w-2/3 2xl:w-1/2'>
        <div className='flex flex-row gap-x-4 py-4'>
          <h2 className='text-bold mb-4 text-center text-3xl font-bold'>Full property list of {id} table</h2>
        </div>
        <TableWrapper>
          <Table>
            <TableHeadRow>
              <ColumnTitle2 title='Property Name' />
              <ColumnTitle2 title='Property Value' />
            </TableHeadRow>
            <tbody>
              <MetadataDetailsTableRow title='Description' value={description} />
              <MetadataDetailsTableRow title='Type' value={tableType} />
              <MetadataDetailsTableRow title='Created by' value={createdBy} />
              <MetadataDetailsTableRow title='Created time' value={timestampToReadableDatetime(createdTime)} />
              <MetadataDetailsTableRow title='Owner' value={owner} />
              <MetadataDetailsTableRow title='Location' value={location} />
              <MetadataDetailsTableRow title='Number of Columns' value={columns.length.toString()} />
              {tableType === 'qbeast' && index && cubeSize && (
                <>
                  <MetadataDetailsTableRow title='Indexed Column(s)' value={index} />
                  <MetadataDetailsTableRow title='Desired Cube Size' value={cubeSize} />
                </>
              )}
            </tbody>
          </Table>
        </TableWrapper>
      </div>
    </div>
  )
}
