import { Notifications } from './notifications'
import { NavigationWrapper } from '../common/app-navigation/navigation-wrapper'

export const NotificationsView = () => {
  return (
    <NavigationWrapper>
      <Notifications />
    </NavigationWrapper>
  )
}
