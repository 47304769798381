import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app-store'
import { TimeSeries } from '../../transport/response-models'
import { ChartDataPoint } from '../../components/common/chart/types'
import { zipTimeSeriesOrGetEmptyIfFalsy } from '../../utils/array-utils'
import { TimeSeriesWindow } from '../../components/data-explorer/data-explorer-right-side/table-details/types'
import { ConsumptionTimeSeries } from '../../transport/data-transformers/consumption-insights/consuption-insights-data-transformer'

export interface ConsumptionInsightsState {
  namesOfFetchedTables: string[]
  namesOfTablesBeingFetched: string[]
  areConsumptionTimeSeriesBeingCalculated: boolean
  isFetched: boolean
  consumptionTimeSeries: ConsumptionTimeSeries
  timeSeriesWindow: TimeSeriesWindow
}

const initialState: ConsumptionInsightsState = {
  namesOfFetchedTables: [],
  namesOfTablesBeingFetched: [],
  areConsumptionTimeSeriesBeingCalculated: false,
  isFetched: false,
  consumptionTimeSeries: null,
  timeSeriesWindow: TimeSeriesWindow.WEEK,
}

export interface AddConsumptionInsightsPayload {
  consumptionTimeSeries: ConsumptionTimeSeries
  forTimeSeriesWindow: TimeSeriesWindow
}

const consumptionInsightsSlice = createSlice({
  name: 'consumptionInsights',
  initialState,
  reducers: {
    startFetchingConsumptionInsights: (state, { payload }: PayloadAction<string>) => {
      state.namesOfTablesBeingFetched.push(payload)
    },
    addConsumptionInsights: (state, { payload }: PayloadAction<string>) => {
      const nameOfFetchedTable = payload
      state.namesOfFetchedTables.push(nameOfFetchedTable)
      state.namesOfTablesBeingFetched = state.namesOfTablesBeingFetched.filter((name) => name !== nameOfFetchedTable)
    },
    startCalculatingConsumptionInsightsTimeSeries: (state) => {
      state.consumptionTimeSeries = null
    },
    addConsumptionInsightsTimeLine: (
      state,
      { payload: { consumptionTimeSeries, forTimeSeriesWindow } }: PayloadAction<AddConsumptionInsightsPayload>
    ) => {
      if (state.timeSeriesWindow === forTimeSeriesWindow) {
        state.consumptionTimeSeries = consumptionTimeSeries
        state.timeSeriesWindow = forTimeSeriesWindow
      }
    },
    chooseTimeSeriesWindow: (state, { payload }: PayloadAction<TimeSeriesWindow>) => {
      state.timeSeriesWindow = payload
    },
  },
})

const selectBytesRead = (state: RootState): TimeSeries<number> =>
  state.consumptionInsights.consumptionTimeSeries?.numberOfBytesRead
const selectFilesRead = (state: RootState): TimeSeries<number> =>
  state.consumptionInsights.consumptionTimeSeries?.numberOfFilesRead
/*
const selectRowsRead = (state: RootState): TimeSeries<number> =>
  state.consumptionInsights.consumptionTimeSeries?.numberOfRowsRead
*/
const selectNamesOfConsumptionInsightsTablesBeingFetched = (state: RootState): string[] =>
  state.consumptionInsights.namesOfTablesBeingFetched

const selectNamesOfFetchedTables = (state: RootState): string[] => state.consumptionInsights.namesOfFetchedTables

export const selectAreConsumptionInsightsFetched: (state: RootState) => (tableName: string) => boolean = createSelector(
  selectNamesOfFetchedTables,
  (namesOfFetchedTables) => {
    return (tableName: string) => {
      return namesOfFetchedTables.includes(tableName)
    }
  }
)
export const selectShouldFetchConsumptionTableWithName: (state: RootState) => (tableName: string) => boolean =
  createSelector(
    selectNamesOfConsumptionInsightsTablesBeingFetched,
    selectNamesOfFetchedTables,
    (namesOfTablesBeingFetched, namesOfFetchedTables) => {
      return (tableName: string) => {
        return !namesOfTablesBeingFetched.includes(tableName) && !namesOfFetchedTables.includes(tableName)
      }
    }
  )

export const selectConsumptionTimeSeriesWindow = (state: RootState): TimeSeriesWindow =>
  state.consumptionInsights.timeSeriesWindow
export const selectConsumptionInsightsBytesReadData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectBytesRead,
  (bytesRead: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(bytesRead)
)

export const selectConsumptionInsightsFilesReadData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectFilesRead,
  (filesRead: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(filesRead)
)
/*
export const selectConsumptionInsightsRowsReadData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectRowsRead,
  (rowsRead: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(rowsRead)
)
*/

export const selectIsConsumptionInsightsTimelineCalculated = (state: RootState): boolean => {
  return state.consumptionInsights.consumptionTimeSeries !== null
}

export const actions = consumptionInsightsSlice.actions
export default consumptionInsightsSlice.reducer
