import {
  selectChosenConsumptionInsightsArrowTableName,
  selectChosenIngestionInsightsArrowTableName,
} from '../../../../../state/slices/catalogs-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  loadConsumptionsForTable,
  loadIngestionForTable,
  WebClientResultWithoutData,
} from '../../../../../transport/spark-api'
import { actions as modalActions } from '../../../../../state/slices/modal-slice'
import * as Sentry from '@sentry/react'
import { actions } from '../../../../../state/slices/ingestion-insights-slice'

export const useFetchIngestionInsights = () => {
  const dispatch = useDispatch()
  const ingestionInsightsTableName = useSelector(selectChosenIngestionInsightsArrowTableName)

  return async (accessToken: string) => {
    dispatch(actions.startFetchingIngestionInsights(ingestionInsightsTableName))
    const result: WebClientResultWithoutData = await loadIngestionForTable(accessToken, ingestionInsightsTableName)
    if (result.hasFailed) {
      dispatch(
        modalActions.showModal({
          message: 'There has been an error when retrieving ingestion insights. Please reload the page.',
        })
      )
      Sentry.captureException('There has been an error when retrieving ingestion insights. ' + JSON.stringify(result))
    } else {
      dispatch(actions.addIngestionInsights(ingestionInsightsTableName))
    }
  }
}
