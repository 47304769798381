export enum Period {
  LAST_6HOUR = '6hr',
  LAST_DAY = '1D',
  LAST_WEEK = '1W',
  LAST_MONTH = '1M',
  ALL = 'all',
}

export enum TimeSeriesWindow {
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}
