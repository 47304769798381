import { TableDetailsTab, TableInsightsTab, TableMetadataTab } from '../state/slices/catalogs-slice'

export const ROUTES = {
  OVERVIEW: '/overview',
  DATA_EXPLORER: '/data-explorer',
  DATA_EXPLORER_TABLE_WITH_TOP: '/data-explorer/catalogs/:catalogId/namespaces/:namespaceId/tables/:tableId/:topTab',
  DATA_EXPLORER_TABLE_WITH_TOP_AND_BOTTOM_TABS:
    '/data-explorer/catalogs/:catalogId/namespaces/:namespaceId/tables/:tableId/:topTab/:bottomTab',
  // DATA_EXPLORER_TABLE: '/data-explorer/catalogs/:catalogId/namespaces/:namespaceId/tables/:tableId/topTab',
  DATA_EXPLORER_NAMESPACE: '/data-explorer/catalogs/:catalogId/namespaces/:namespaceId',
  DATA_EXPLORER_CATALOG: '/data-explorer/catalogs/:catalogId',
  NOTIFICATIONS: '/notifications',
  PROFILE: '/profile',
  HELP: '/help',
  CONTACT: '/contact',
  ACCOUNT: '/account',
}

export const buildDataExplorerCatalogNamespaceTableRoute = (
  catalogId: string,
  namespaceId: string,
  tableId: string,
  topTab: TableDetailsTab,
  bottomTab: TableInsightsTab | TableMetadataTab | null
) => {
  const result = [`/data-explorer/catalogs/${catalogId}/namespaces/${namespaceId}/tables/${tableId}/${topTab}`]
  if (bottomTab) {
    result.push(bottomTab)
  }
  return result.join('/')
}
export const buildDataExplorerCatalogNamespaceRoute = (catalogId: string, namespaceId: string) =>
  `/data-explorer/catalogs/${catalogId}/namespaces/${namespaceId}`
export const buildDataExplorerCatalogRoute = (catalogId: string) => `/data-explorer/catalogs/${catalogId}`
