export const bytesToReadable = (bytes: number): string => {
  const units: string[] = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB']
  const factor: number = 1024
  let unitIndex: number = 0

  while (bytes >= factor && unitIndex < units.length - 1) {
    bytes /= factor
    unitIndex++
  }

  let formattedNumber: string
  if (bytes % 1 === 0) {
    formattedNumber = String(bytes)
  } else if ((bytes * 10) % 1 === 0) {
    formattedNumber = bytes.toFixed(1)
  } else {
    formattedNumber = bytes.toFixed(2)
  }

  return `${formattedNumber} ${units[unitIndex]}`
}

export const toMaxTwoFloatingPoints = (number: number): string => {
  let formattedNumber: string
  if (number % 1 === 0) {
    formattedNumber = String(number)
  } else if ((number * 10) % 1 === 0) {
    formattedNumber = number.toFixed(1)
  } else {
    formattedNumber = number.toFixed(2)
  }
  return `${formattedNumber}`
}

export const timestampToReadableDateAndTimeInTwoLines = (timestamp: number): [string, string] => {
  const date = new Date(timestamp)
  return [date.toDateString(), date.toLocaleTimeString()]
}

export const timestampToReadableDatetime = (timestamp: number): string => {
  const date = new Date(timestamp)
  return date.toLocaleString()
}

// chart.js changes timestamps in milliseconds like this one: 1619712000000 into a string with comas like this one: "1,619,712,000,000"
export const parseNumberFromStringWithComas = (str: string): number | null => {
  const num = parseFloat(str.replace(/,/g, ''))
  return isNaN(num) ? null : num
}
