import * as duckdb from '@duckdb/duckdb-wasm'
import duckdb_wasm from '@duckdb/duckdb-wasm/dist/duckdb-mvp.wasm?url'
import mvp_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-mvp.worker.js?url'
import duckdb_wasm_eh from '@duckdb/duckdb-wasm/dist/duckdb-eh.wasm?url'
import eh_worker from '@duckdb/duckdb-wasm/dist/duckdb-browser-eh.worker.js?url'
import { Table, tableToIPC } from 'apache-arrow'
import { DuckSession } from './duck'
import { getConfig } from '../config/environment-config'

export const createWasmDuckSession: () => Promise<DuckSession> = async () => {
  const connection = await getDB().then((a) => a.connect())
  return new DuckSessionWasm(connection)
}

let _db: duckdb.AsyncDuckDB = undefined
export const getDB: () => Promise<duckdb.AsyncDuckDB> = async () => {
  if (_db) {
    return new Promise((resolve) => {
      resolve(_db)
    })
  } else {
    const MANUAL_BUNDLES: duckdb.DuckDBBundles = {
      mvp: {
        mainModule: duckdb_wasm,
        mainWorker: mvp_worker,
      },
      eh: {
        mainModule: duckdb_wasm_eh,
        mainWorker: eh_worker,
      },
    }
    const bundle = await duckdb.selectBundle(MANUAL_BUNDLES)
    const worker = new Worker(bundle.mainWorker!)
    const logger = new duckdb.ConsoleLogger()

    const db = new duckdb.AsyncDuckDB(logger, worker)
    await db.instantiate(bundle.mainModule, bundle.pthreadWorker)
    _db = db
    return db
  }
}

class DuckSessionWasm implements DuckSession {
  private connection: duckdb.AsyncDuckDBConnection
  constructor(connection: duckdb.AsyncDuckDBConnection) {
    this.connection = connection
  }
  async runQuery(query: string): Promise<Table> {
    const result = await performance.now()
    try {
      return await this.connection.query(query)
    } finally {
      if (getConfig().shouldLogDebug) console.debug(`[DUCK-CLIENT] query completed in ${performance.now() - result} ms`)
    }
  }

  async checkTableExists(tableId: string): Promise<boolean> {
    return (
      await this.runQuery(
        `SELECT count(*) > 0 as table_exists FROM information_schema.tables WHERE table_name = '${tableId}'`
      )
    ).get(0).table_exists
  }

  async insertDuckArrowTable(table: Table, tableName: string): Promise<void> {
    await this.connection.insertArrowTable(table, { name: tableName })
  }
  async insertExternalArrowTableByIPC(table: Table, tableName: string): Promise<void> {
    const buffer = tableToIPC(table, 'stream')
    await this.connection.insertArrowFromIPCStream(buffer, { name: tableName })
  }

  async close(): Promise<void> {
    await this.connection.close()
  }
}
