export const ChosenTab = ({ title, handleClick }: { title: string; handleClick: () => void }) => (
  <li onClick={handleClick}>
    <div className='cursor-pointer border-b-2 border-black p-4 text-center font-extrabold text-gray-800'>{title}</div>
  </li>
)
export const NotChosenTab = ({ title, handleClick }: { title: string; handleClick: () => void }) => (
  <li onClick={handleClick}>
    <div className='cursor-pointer p-4 text-center font-extrabold text-gray-600 duration-300 hover:text-gray-800'>
      {title}
    </div>
  </li>
)
