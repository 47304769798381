import { TableOverview } from './table-overview/table-overview'
import { TableMetadata } from './table-metadata/table-metadata'
import { TableTearDown } from './table-tear-down'
import { getConfig } from '../../../../config/environment-config'
import { TableBasicStats } from './table-basic-stats'
import { ChosenTab, NotChosenTab } from '../../../common/catalog-navigation/catalog-navigation-atoms'
import { TableInsights } from './table-insights/table-insights'
import { ConsumptionInsights } from './consumption-insights/consumption-insights'
import { IngestionInsights } from './ingestion-insights/ingestion-insights'
import { useDispatch, useSelector } from 'react-redux'
import {
  chooseTableDetailsTab,
  selectChosenTableDetailsTab,
  TableDetailsTab,
} from '../../../../state/slices/catalogs-slice'

export const TableDetails = () => {
  const chosenTab = useSelector(selectChosenTableDetailsTab)
  const dispatch = useDispatch()

  const tabs = [
    [TableDetailsTab.OVERVIEW, 'OVERVIEW'],
    [TableDetailsTab.TABLE_METADATA, 'METADATA'],
    [TableDetailsTab.TABLE_INSIGHTS, 'TABLE INSIGHTS'],
    [TableDetailsTab.CONSUMPTION_INSIGHTS, 'CONSUMPTION INSIGHTS'],
    [TableDetailsTab.INGESTION_INSIGHTS, 'INGESTION INSIGHTS'],
  ]
  if (getConfig().featureFlags.isTableTearDownEnabled) {
    tabs.push([TableDetailsTab.TABLE_TEAR_DOWN, 'TABLE TEAR DOWN'])
  }
  if (getConfig().featureFlags.isBasicStatsEnabled) {
    tabs.push([TableDetailsTab.TABLE_BASIC_STATS, 'TABLE BASIC STATS'])
  }
  return (
    <>
      <ul className='sticky top-0 z-10 flex h-20 w-full flex-row items-center justify-around border-gray-200 bg-gray-100 shadow'>
        {tabs.map((tuple) => {
          const [tab, title] = tuple as [TableDetailsTab, string]
          if (tab === chosenTab) {
            return (
              <ChosenTab
                key={title}
                title={title}
                handleClick={() => {
                  dispatch(chooseTableDetailsTab(tab))
                }}
              />
            )
          } else {
            return (
              <NotChosenTab
                key={title}
                title={title as string}
                handleClick={() => {
                  dispatch(chooseTableDetailsTab(tab))
                }}
              />
            )
          }
        })}
      </ul>
      <div className='w-full'>
        {
          {
            [TableDetailsTab.OVERVIEW]: <TableOverview />,
            [TableDetailsTab.TABLE_METADATA]: <TableMetadata />,
            [TableDetailsTab.TABLE_INSIGHTS]: <TableInsights />,
            [TableDetailsTab.CONSUMPTION_INSIGHTS]: <ConsumptionInsights />,
            [TableDetailsTab.INGESTION_INSIGHTS]: <IngestionInsights />,
            [TableDetailsTab.TABLE_TEAR_DOWN]: <TableTearDown />,
            [TableDetailsTab.TABLE_BASIC_STATS]: <TableBasicStats />,
          }[chosenTab]
        }
      </div>
    </>
  )
}
