import { ChartDataPoint } from '../types'

export enum TimeSeriesType {
  BYTES = 'bytes',
  ROWS = 'rows',
  FILES = 'files',
  CPU_CONSUMPTION = 'cpu_consumption',
  AVERAGE_DURATION = 'average_duration',
  MAX_INGESTION_DELAY = 'max_ingestion_delay',
  MAX_NUMBER_OF_MANAGED_INGESTIONS = 'max_number_of_managed_ingestions',
  MAX_NUMBER_OF_TABLES = 'max_number_of_tables',
}

export interface CustomVerticalScale {
  position: 'left' | 'right'
  title: {
    display: boolean
    text: string
    color: string
  }
  ticks: {
    color: string
    callback?: (tickValue: number | string) => string
  }
}

export type TimeSeriesData = Partial<{ [K in TimeSeriesType]: { data: ChartDataPoint[]; label: string } }>
