import { CANVAS_IDS } from '../../../../../constants/canvas-ids'
import { useDispatch, useSelector } from 'react-redux'
import {
  actions,
  selectIngestionInsightsAverageDurationData,
  selectIngestionInsightsBytesIngestedData,
  selectIngestionInsightsCpuConsumptionData,
  selectIngestionInsightsFilesIngestedData,
  selectIngestionInsightsMaxIngestionDelayData,
  selectIngestionTimeSeriesWindow,
} from '../../../../../state/slices/ingestion-insights-slice'
import { ChartDataPoint } from '../../../../common/chart/types'
import { Timeline } from '../../../../common/chart/timeline/timeline'
import { TimeSeriesType } from '../../../../common/chart/timeline/timeline-types'
import { TimeSeriesWindow } from '../types'
import { selectChosenIngestionInsightsArrowTableName } from '../../../../../state/slices/catalogs-slice'

export const IngestionInsightsTimelineConnector = () => {
  const dispatch = useDispatch()
  const timeSeriesWindow = useSelector(selectIngestionTimeSeriesWindow)
  const ingestionInsightsTableName = useSelector(selectChosenIngestionInsightsArrowTableName)
  const averageDuration: ChartDataPoint[] = useSelector(selectIngestionInsightsAverageDurationData)
  const cpuConsumption: ChartDataPoint[] = useSelector(selectIngestionInsightsCpuConsumptionData)
  const filesIngested: ChartDataPoint[] = useSelector(selectIngestionInsightsFilesIngestedData)
  const bytesIngested: ChartDataPoint[] = useSelector(selectIngestionInsightsBytesIngestedData)
  const maxIngestionDelay: ChartDataPoint[] = useSelector(selectIngestionInsightsMaxIngestionDelayData)

  return (
    <Timeline
      canvasId={CANVAS_IDS.INGESTION_DETAILS_TIMELINE_ID}
      tableName={ingestionInsightsTableName}
      timeSeriesData={{
        [TimeSeriesType.AVERAGE_DURATION]: {
          data: averageDuration,
          label: 'Average Duration',
        },
        [TimeSeriesType.CPU_CONSUMPTION]: {
          data: cpuConsumption,
          label: 'Cpu Consumption',
        },
        [TimeSeriesType.FILES]: {
          data: filesIngested,
          label: 'Files Ingested',
        },
        [TimeSeriesType.BYTES]: {
          data: bytesIngested,
          label: 'Bytes Ingested',
        },
        [TimeSeriesType.MAX_INGESTION_DELAY]: {
          data: maxIngestionDelay,
          label: 'Max Ingestion Delay',
        },
      }}
      onTimeSeriesWindowChange={(timeSeriesWindow: TimeSeriesWindow) => {
        dispatch(actions.chooseTimeSeriesWindow(timeSeriesWindow))
      }}
      currentTimeSeriesWindow={timeSeriesWindow}
      initialLeftTimeSeries={TimeSeriesType.FILES}
      initialRightTimeSeries={TimeSeriesType.AVERAGE_DURATION}
    />
  )
}
