import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { getConfig } from '../../config/environment-config'
import { useDispatch } from 'react-redux'
import { setAccessToken, setLoggedIn } from '../../state/slices/account-slice'
import axios from 'axios'
import { UserDataResponseModel } from '../../transport/response-models'
import * as Sentry from '@sentry/react'

const SilentLogin = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const dispatch = useDispatch()

  useEffect(() => {
    const getTokenAndUserData = async () => {
      if (isAuthenticated && getAccessTokenSilently && user) {
        try {
          const accessToken = await getAccessTokenSilently({
            authorizationParams: {
              audience: `https://${getConfig().auth0Domain}/api/v2/`,
              scope: 'read:current_user',
            },
          })
          dispatch(setAccessToken({ accessToken }))
          const userDetailsByIdUrl = `https://${getConfig().auth0Domain}/api/v2/users/${user.sub}`
          try {
            const userDataResponse = await axios.get<UserDataResponseModel>(userDetailsByIdUrl, {
              headers: {
                authorization: `Bearer ${accessToken}`,
              },
            })
            if (userDataResponse.status === 200) {
              const { email, user_id, family_name, given_name, picture } = userDataResponse.data
              dispatch(
                setLoggedIn({
                  id: user_id,
                  email,
                  name: given_name,
                  lastName: family_name,
                  picture,
                })
              )
            }
          } catch (error) {
            const errorMessage = `Silent login. GetUserData. There was an error ${error}`
            console.error(errorMessage)
            Sentry.captureException(errorMessage)
          }
        } catch (e) {
          const errorMessage = `Silent login. There was an error: ${e.message}`
          console.error(errorMessage)
          Sentry.captureException(errorMessage)
        }
      }
    }

    getTokenAndUserData().then()
  }, [isAuthenticated, getAccessTokenSilently, user?.sub])

  return <></>
}

export default SilentLogin
