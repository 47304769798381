import { TimeSeriesWindow } from '../../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { generateDenseSeries, packColumns } from '../../sql-utils'

export const fileSummaryTimeSeries = (fileInsightsTableName: string, timeWindow: TimeSeriesWindow): string => {
  const duckTableName = fileInsightsTableName.replace(/\./g, '_')
  const rangeSQL = generateDenseSeries(duckTableName, timeWindow)
  return `
WITH
    ${rangeSQL},
    NumberOf AS (
                SELECT r.time_window,
                SUM(CASE WHEN file_rows_hist is null THEN 0 ELSE file_rows_hist.count_value END) as number_of_files,
                SUM(CASE WHEN file_rows_hist is null THEN 0 ELSE file_rows_hist.sum_value END) as number_of_rows,
                SUM(CASE WHEN file_bytes_hist is null THEN 0 ELSE file_bytes_hist.sum_value END) as number_of_bytes 
                FROM Windowed w RIGHT JOIN RANGES r on (r.time_window = w.time_window)
                GROUP BY r.time_window
                ORDER BY r.time_window ASC
    )
   ${packColumns(['number_of_files', 'number_of_rows', 'number_of_bytes'], 'NumberOf')} 
`
}
