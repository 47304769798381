import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  selectAreConsumptionInsightsFetched,
  selectConsumptionTimeSeriesWindow,
  selectIsConsumptionInsightsTimelineCalculated,
  selectShouldFetchConsumptionTableWithName,
} from '../../../../../state/slices/consumption-insights-slice'
import { selectAccountAccessToken } from '../../../../../state/slices/account-slice'
import {
  selectChosenCatalogId,
  selectChosenConsumptionInsightsArrowTableName,
  selectChosenNamespaceId,
  selectChosenTableId,
  selectHasConsumptionInsights,
} from '../../../../../state/slices/catalogs-slice'
import { Loader } from '../../../../common/loader'
import { ConsumptionInsightsTimelineConnector } from './consumption-insights-timeline-connector'
import { useFetchConsumptionInsights } from './use-fetch-consumption-insights'
import { useFetchConsumptionTimeSeries } from './use-fetch-consumption-time-series'

export const ConsumptionInsights = () => {
  const hasConsumptionInsights = useSelector(selectHasConsumptionInsights)

  const isFetched: (tableName: string) => boolean = useSelector(selectAreConsumptionInsightsFetched)
  const isTimelineCalculated: boolean = useSelector(selectIsConsumptionInsightsTimelineCalculated)
  const shouldFetch: (tableName: string) => boolean = useSelector(selectShouldFetchConsumptionTableWithName)
  const accessToken = useSelector(selectAccountAccessToken)

  const catalogId = useSelector(selectChosenCatalogId)
  const namespaceId = useSelector(selectChosenNamespaceId)
  const tableId = useSelector(selectChosenTableId)

  const consumptionInsightsTableName = useSelector(selectChosenConsumptionInsightsArrowTableName)
  const timeSeriesWindow = useSelector(selectConsumptionTimeSeriesWindow)
  const fetchConsumptionInsights = useFetchConsumptionInsights()
  const fetchConsumptionTimeSeries = useFetchConsumptionTimeSeries()

  useEffect(() => {
    if (hasConsumptionInsights && catalogId && namespaceId && tableId && shouldFetch(consumptionInsightsTableName)) {
      fetchConsumptionInsights(accessToken).then()
    }
  }, [consumptionInsightsTableName])

  useEffect(() => {
    if (hasConsumptionInsights) {
      fetchConsumptionTimeSeries().then()
    }
  }, [timeSeriesWindow, consumptionInsightsTableName, isFetched(consumptionInsightsTableName)])

  if (!hasConsumptionInsights) {
    return (
      <div className='mt-24 flex w-full flex-col items-center p-4'>
        <div className='w-full'>
          <h1 className='text-center text-xl font-bold'>Consumption Insights are not available for this table</h1>
        </div>
      </div>
    )
  } else {
    return (
      <div className='mt-24 flex w-full flex-col items-center p-4'>
        {!isFetched(consumptionInsightsTableName) || !isTimelineCalculated ? (
          <div className='w-full'>
            <Loader />
          </div>
        ) : (
          <div className='mb-20 flex h-full w-10/12 flex-col items-center justify-center 3xl:w-3/4'>
            <ConsumptionInsightsTimelineConnector />
          </div>
        )}
      </div>
    )
  }
}
