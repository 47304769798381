import { Reducer } from 'redux'
import { CatalogsState } from './slices/catalogs-slice'
import { AppReducer } from './app-reducer'
import { AccountState } from './slices/account-slice'
import { ConsumptionInsightsState } from './slices/consumption-insights-slice'
import { TableInsightsState } from './slices/table-insights-slice'
import { IngestionInsightsState } from './slices/ingestion-insights-slice'
import { ModalState } from './slices/modal-slice'
import { GlobalIngestionInsightsState } from './slices/global-ingestion-insights-slice'
import { GlobalFilesInsightsState } from './slices/global-files-insights-slice'

export class ReducerBuilder {
  private catalogs: Reducer<CatalogsState>
  private account: Reducer<AccountState>
  private consumptionInsights: Reducer<ConsumptionInsightsState>
  private ingestionInsights: Reducer<IngestionInsightsState>
  private globalIngestionInsights: Reducer<GlobalIngestionInsightsState>
  private globalFilesInsights: Reducer<GlobalFilesInsightsState>

  private tableInsights: Reducer<TableInsightsState>
  private modal: Reducer<ModalState>

  setCatalogsReducer(catalogs: Reducer<CatalogsState>) {
    this.catalogs = catalogs
    return this
  }

  setAccountReducer(account: Reducer<AccountState>) {
    this.account = account
    return this
  }

  setConsumptionInsightsReducer(consumptionInsights: Reducer<ConsumptionInsightsState>) {
    this.consumptionInsights = consumptionInsights
    return this
  }

  setIngestionInsightsReducer(ingestionInsights: Reducer<IngestionInsightsState>) {
    this.ingestionInsights = ingestionInsights
    return this
  }

  setGlobalIngestionInsightsReducer(globalIngestionInsights: Reducer<GlobalIngestionInsightsState>) {
    this.globalIngestionInsights = globalIngestionInsights
    return this
  }
  setGlobalFilesInsightsReducer(globalFilesInsights: Reducer<GlobalFilesInsightsState>) {
    this.globalFilesInsights = globalFilesInsights
    return this
  }

  setTableInsightsReducer(tableInsights: Reducer<TableInsightsState>) {
    this.tableInsights = tableInsights
    return this
  }

  setModalReducer(modal: Reducer<ModalState>) {
    this.modal = modal
    return this
  }

  build() {
    const result: AppReducer = {}
    if (this.catalogs) {
      result.catalogs = this.catalogs
    }
    if (this.account) {
      result.account = this.account
    }
    if (this.consumptionInsights) {
      result.consumptionInsights = this.consumptionInsights
    }
    if (this.ingestionInsights) {
      result.ingestionInsights = this.ingestionInsights
    }
    if (this.globalIngestionInsights) {
      result.globalIngestionInsights = this.globalIngestionInsights
    }
    if (this.globalFilesInsights) {
      result.globalFilesInsights = this.globalFilesInsights
    }
    if (this.tableInsights) {
      result.tableInsights = this.tableInsights
    }
    if (this.modal) {
      result.modal = this.modal
    }
    return result
  }
}
