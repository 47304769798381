import { Cell2, ColumnTitle2, Table, TableBodyRow, TableHeadRow, TableWrapper } from '../common/table/table-atoms'
import { useDispatch, useSelector } from 'react-redux'
import { expandNamespace, NamespaceFlatStructure, selectNamespacesList } from '../../state/slices/catalogs-slice'

export const NamespaceRow = ({ namespace: { description, id } }: { namespace: NamespaceFlatStructure }) => {
  const dispatch = useDispatch()

  return (
    <TableBodyRow>
      <Cell2
        text={id}
        handleClick={() => {
          dispatch(expandNamespace(id))
        }}
      />
      <Cell2 text={description || 'NO DESCRIPTION'} />
    </TableBodyRow>
  )
}
export const CatalogDetails = () => {
  const namespaces = useSelector(selectNamespacesList)

  return (
    <>
      {namespaces.length === 0 ? (
        <div className='flex h-full w-full flex-col items-center justify-center'>
          <h1 className='text-3xl font-bold text-gray-700'>There are no namespaces in the selected catalog</h1>
        </div>
      ) : (
        <div className='mt-16 flex w-full flex-col items-center'>
          <h1 className='text-3xl font-bold text-gray-700'>Namespaces</h1>
          <div className='mt-16 flex w-full flex-col items-center justify-center p-4 xl:w-2/3 2xl:w-1/2'>
            <TableWrapper>
              <Table>
                <TableHeadRow>
                  <ColumnTitle2 title='name' />
                  <ColumnTitle2 title='description' />
                </TableHeadRow>
                <tbody>
                  {namespaces.map((n: NamespaceFlatStructure) => (
                    <NamespaceRow key={n.id} namespace={n} />
                  ))}
                </tbody>
              </Table>
            </TableWrapper>
          </div>
        </div>
      )}
    </>
  )
}
