import { TimeSeriesWindow } from '../../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { generateDenseSeries, packColumns } from '../../sql-utils'

export const ingestionTimeSeriesQuery = (fileInsightsTableName: string, timeWindow: TimeSeriesWindow): string => {
  const duckTableName = fileInsightsTableName.replace(/\./g, '_')
  const seriesSQL = generateDenseSeries(duckTableName, timeWindow)
  return `WITH
  ${seriesSQL},
  NumberOf AS (
    SELECT r.time_window,
      SUM(CASE WHEN ingestion_wall_duration_ms_hist is null THEN 0 
       ELSE ingestion_wall_duration_ms_hist.sum_value/ingestion_wall_duration_ms_hist.count_value
       END) as averageDuration,
      SUM(CASE WHEN ingestion_process_duration_ms_hist is null THEN 0
       ELSE ingestion_process_duration_ms_hist.sum_value END) as cpuConsumption,
      SUM(CASE WHEN num_input_files_hist is null THEN 0
       ELSE num_input_files_hist.sum_value END) as numberOfFilesIngested,
      SUM(CASE WHEN byte_size_input_files_hist is null THEN 0
       ELSE byte_size_input_files_hist.sum_value END) as bytesIngested,
      SUM(CASE WHEN max_ingestion_delay_ms_hist is null THEN 0
       ELSE max_ingestion_delay_ms_hist.sum_value END) as maxIngestionDelay 
    FROM Windowed w RIGHT JOIN RANGES r on (r.time_window = w.time_window)
    GROUP BY r.time_window
    ORDER BY r.time_window ASC
  )
  ${packColumns(
    ['averageDuration', 'cpuConsumption', 'numberOfFilesIngested', 'bytesIngested', 'maxIngestionDelay'],
    'NumberOf'
  )}
`
}
