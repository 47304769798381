import { useDispatch, useSelector } from 'react-redux'
import {
  actions,
  selectGlobalIngestionTimeSeriesWindow,
  selectIsGlobalIngestionInsightsFetched,
} from '../../../../state/slices/global-ingestion-insights-slice'
import { withExecutionTimeLogging } from '../../../../utils/logging-utils'
import {
  getGlobalIngestionsTimeSeries,
  GlobalIngestionTimeSeries,
} from '../../../../transport/data-transformers/global-ingestion-insights/global-ingestion-insights-data-transformer'

export const useFetchGlobalIngestionTimeSeries = () => {
  const dispatch = useDispatch()
  const isFetched = useSelector(selectIsGlobalIngestionInsightsFetched)
  const timeSeriesWindow = useSelector(selectGlobalIngestionTimeSeriesWindow)

  return async () => {
    const forTimeSeriesWindow = timeSeriesWindow
    if (isFetched) {
      const globalIngestionTimeSeries: GlobalIngestionTimeSeries = await withExecutionTimeLogging(
        async () => await getGlobalIngestionsTimeSeries(forTimeSeriesWindow),
        'Ingestion time series aggregation'
      )
      dispatch(
        actions.addGlobalIngestionInsightsTimeLine({
          globalIngestionTimeSeries: globalIngestionTimeSeries,
          forTimeSeriesWindow,
        })
      )
    }
  }
}
