import { selectChosenIngestionInsightsArrowTableName } from '../../../../../state/slices/catalogs-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  actions,
  selectAreIngestionInsightsFetched,
  selectIngestionTimeSeriesWindow,
} from '../../../../../state/slices/ingestion-insights-slice'
import {
  getIngestionsTimeSeries,
  IngestionTimeSeries,
} from '../../../../../transport/data-transformers/ingestion-insights/ingestion-insights-data-transformer'
import { withExecutionTimeLogging } from '../../../../../utils/logging-utils'

export const useFetchIngestionTimeSeries = () => {
  const dispatch = useDispatch()
  const isFetched: (tableName: string) => boolean = useSelector(selectAreIngestionInsightsFetched)
  const timeSeriesWindow = useSelector(selectIngestionTimeSeriesWindow)
  const ingestionInsightsTableName = useSelector(selectChosenIngestionInsightsArrowTableName)

  return async () => {
    const forTimeSeriesWindow = timeSeriesWindow
    if (isFetched(ingestionInsightsTableName)) {
      const ingestionTimeSeries: IngestionTimeSeries = await withExecutionTimeLogging(
        async () => await getIngestionsTimeSeries(forTimeSeriesWindow, ingestionInsightsTableName),
        'Ingestion time series aggregation'
      )
      dispatch(actions.addIngestionInsightsTimeLine({ ingestionTimeSeries, forTimeSeriesWindow }))
    }
  }
}
