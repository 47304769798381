import * as amplitude from '@amplitude/analytics-browser'
import { ROUTES } from '../../routing/routes'

export const AMPLITUDE_EVENTS = {
  viewHelp: () => {
    viewPage(ROUTES.HELP)
  },
  viewContact: () => {
    viewPage(ROUTES.CONTACT)
  },
  viewDataExplorer: () => {
    viewPage(ROUTES.DATA_EXPLORER)
  },
  viewAccount: () => {
    viewPage(ROUTES.ACCOUNT)
  },
  click: (location: string) => {
    amplitude.track('click', { location })
  },
}

const viewPage = (route: string) => {
  amplitude.track('view_page', { route })
}
