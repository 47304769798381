import { Field, Utf8, Int64, Struct, tableFromArrays, vectorFromArray } from 'apache-arrow'

/* This will be used to display mocked optimization events on tables with qbeast format */
const mockedOptimizationEventsData = [
  {
    optimization_name: 'CubeDefragmentation',
    write_id: '172be017-d0e2-4c17-8af0-02ed656070a1',
    table_id: 's3a://qb-testing/output/126/github_data_ingestion',
    revision_id: 481,
    started_at: 1722325941586,
    finished_at: 1722325958702,
    process_time_ms: -1,
    input_files_stats: {
      files: [
        'ffcb12ba-713f-46ed-82ab-a7ef5f5c2bbb.parquet',
        '0274c10b-4888-4c51-8e31-ce189d435fe5.parquet',
        '0391fa53-4b4b-4edf-9478-fccbe3c55bf3.parquet',
        '68bb2966-8a36-4894-a0c7-09249bb73804.parquet',
        '1dffb087-bee7-4bb3-90d2-fb75103fff07.parquet',
        '24ebff90-727b-4a7e-b3c7-1b09bb83e876.parquet',
        '352de9b3-3559-4905-ba80-3c09fceb7db7.parquet',
        'feb99225-718e-443b-8851-e2f016881985.parquet',
        '0cd565ca-7a64-4bec-8a6d-55b6b9f4f84f.parquet',
        '65faaac8-ac8c-4bef-91aa-453827b1a8de.parquet',
        '88396509-2367-4c63-8ca3-40ee69ed933b.parquet',
        'a04b3758-387e-4eff-9ac5-7fcc81022d26.parquet',
        '6c97e279-22d4-4559-9854-30bb88e203ce.parquet',
        '80ae96a1-fa53-409e-bcd1-2f14798ea655.parquet',
        '2146da69-c866-4e50-bad4-ba65fa17ad29.parquet',
        'c9c0e846-9eef-4710-97b1-897d23874d45.parquet',
        '21e1ca31-9ebb-43d9-9623-c288d08d46d3.parquet',
        '6769a97c-91f3-43a7-b6ca-9cb0a3319ff8.parquet',
        '78a292b8-6d6d-4948-bc84-c8007944316b.parquet',
        'f39b398f-f75e-4082-97ce-419629e02ead.parquet',
        '35121093-40f4-42c1-b9dd-11b64ea6e27a.parquet',
        'e7fc7a4e-f0f7-425f-93a0-b72b392b7701.parquet',
        '13a0c32f-b56c-4881-b787-b37335f2b417.parquet',
        '29cb91f5-4bb2-4f30-863d-2c28242b60de.parquet',
      ],
      file_levels: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1],
      element_count: 41260,
      bytes: 31053877,
      num_files: 24,
      num_cubes: 99,
      num_blocks: 864,
      avg_num_blocks_per_file: 36.0,
      avg_block_element_count_per_file: 48.6492248062,
    },
    output_files_stats: {
      files: [
        '1a7c2a86-59d9-46ff-abd8-2474a4d39570.parquet',
        '8a071608-0ea5-4e66-86b0-b85c85e8f625.parquet',
        '5b1f712e-ea89-48dc-8963-2fb10cffa0d7.parquet',
      ],
      file_levels: [2, 2, 2],
      element_count: 41260,
      bytes: 30794989,
      num_files: 3,
      num_cubes: 121,
      num_blocks: 121,
      avg_num_blocks_per_file: 40.3333333333,
      avg_block_element_count_per_file: 269.1478696742,
    },
  },
  {
    optimization_name: 'CubeDefragmentation',
    write_id: 'f5f63c76-9967-46a9-858b-14e22888895d',
    table_id: 's3a://qb-testing/output/126/github_data_ingestion',
    revision_id: 13,
    started_at: 1722010777950,
    finished_at: 1722010793294,
    process_time_ms: -1,
    input_files_stats: {
      files: [
        'fdcc037e-f5f9-4d14-b2fe-2d5af39c3a3f.parquet',
        'f41280ba-f6fb-41aa-8f2d-440a81a15b74.parquet',
        '8db4f116-4b37-489e-8631-c7920c7b796c.parquet',
        'cbfc5469-9f7c-4316-b1b2-4043a2e802e2.parquet',
        '172f7bcd-9ab1-4856-a0a2-f104c482cf10.parquet',
        'a5102689-1752-41c1-b537-c69b5c407a7c.parquet',
        'c8c10e0d-67b0-4cba-9179-4dd47f2aee91.parquet',
        '8d155870-f3a7-4d6d-9651-37f2ecc4a119.parquet',
        '70404411-56ab-43a7-b2cb-7960c7388f50.parquet',
        'cf210a5e-9532-44e8-ba32-e0a175eebbef.parquet',
      ],
      file_levels: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
      element_count: 174655,
      bytes: 134232826,
      num_files: 10,
      num_cubes: 54,
      num_blocks: 74,
      avg_num_blocks_per_file: 7.4,
      avg_block_element_count_per_file: 3366.6314285714,
    },
    output_files_stats: {
      files: [
        '6a4e2893-0179-4580-8194-5feed90c9156.parquet',
        'b95b5660-494c-49e0-bb21-d4219a86d2e2.parquet',
        '77d4cb5b-f96a-4932-9dd5-0d8823fd7ff8.parquet',
        'a0f51bac-fb15-405a-9435-21d990a0ccde.parquet',
        '79eac472-e919-4b8a-8830-1de39dbeb19e.parquet',
        '2c43ffd0-5769-47f9-a097-6ddda4d94014.parquet',
        'b90e22ca-e803-4154-97b4-1c1ebefc6324.parquet',
        '38659539-40da-4446-a78e-9b2b7e6ae4aa.parquet',
        '5d2b4df4-7ead-4363-8cbf-0ab0e8a9aa3a.parquet',
      ],
      file_levels: [3, 4, 3, 4, 3, 4, 3, 4, 4],
      element_count: 174655,
      bytes: 134912778,
      num_files: 9,
      num_cubes: 59,
      num_blocks: 59,
      avg_num_blocks_per_file: 6.5555555556,
      avg_block_element_count_per_file: 3345.0839506173,
    },
  },
]

// I did not convert all the fields, but just the one required for the query
const dataArray = {
  optimization_name: vectorFromArray(
    mockedOptimizationEventsData.map((event) => event.optimization_name),
    new Utf8()
  ),
  write_id: vectorFromArray(
    mockedOptimizationEventsData.map((event) => event.write_id),
    new Utf8()
  ),
  revision_id: vectorFromArray(
    mockedOptimizationEventsData.map((event) => event.revision_id),
    new Utf8()
  ),
  started_at: vectorFromArray(
    mockedOptimizationEventsData.map((event) => BigInt(event.started_at)),
    new Int64()
  ),
  finished_at: vectorFromArray(
    mockedOptimizationEventsData.map((event) => BigInt(event.finished_at)),
    new Int64()
  ),
  input_files_stats: vectorFromArray(
    mockedOptimizationEventsData.map(
      (event) => ({
        bytes: BigInt(event.input_files_stats.bytes),
        num_files: BigInt(event.input_files_stats.num_files),
      }),
      new Struct([new Field('bytes', new Int64()), new Field('num_files', new Int64())])
    )
  ),
}

// @ts-expect-error tableFromArrays type mismatch, but the code is working
export const mockedOptimizationEvents = tableFromArrays(dataArray)
