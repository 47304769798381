import { createSelector, PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../app-store'

export interface ModalState {
  isOpen: boolean
  message: string
  level: string
  buttonLabel: string
}

const initialModalState: ModalState = {
  isOpen: false,
  message: '',
  level: 'Error',
  buttonLabel: 'Reload',
}

// Define the payload for the showModal action
interface ShowModalPayload {
  message: string
  level?: string
  buttonLabel?: string
}

const modalSlice = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    showModal: (state, action: PayloadAction<ShowModalPayload>) => {
      state.isOpen = true
      state.message = action.payload.message
      state.level = action.payload.level ?? 'Error'
      state.buttonLabel = action.payload.buttonLabel ?? 'Reload'
    },
    closeModal: (state) => {
      state.isOpen = false
    },
  },
})
export const actions = modalSlice.actions

export const selectModalState = createSelector(
  (state: RootState) => state.modal,
  (modal) => ({
    isOpen: modal.isOpen,
    message: modal.message,
    level: modal.level,
    buttonLabel: modal.buttonLabel,
  })
)

export default modalSlice.reducer
