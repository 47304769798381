export const TableBasicStats = () => {
  return (
    <div className='flex grow flex-row items-center justify-center'>
      <div className=''>
        <h2 className='text-center text-2xl'>Table Basic Stats</h2>
        <h2 className='text-center text-2xl'>This is a functionality available to most of the clients</h2>
      </div>
    </div>
  )
}
