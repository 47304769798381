import { CatalogsResponseModel } from '../response-models'

const dummyTable = (id: string, description: string) => ({
  id: id,
  description: description,
  tableType: 'qbeast',
  createdBy: 'Spark 3.4.0',
  createdTime: 1699541121000,
  owner: 'public',
  location: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse',
  columns: [
    {
      name: 'column1',
      description: 'some description',
      dataType: 'int',
    },
  ],
})
export const MOCKED_CATALOGS: CatalogsResponseModel = {
  catalogs: [
    {
      id: 'spark_catalog',
      description: 'Default Spark Catalog',
      namespacesIds: ['default', 'ns1', 'ns2', 'ns3'],
    },
    {
      id: 'empty_catalog',
      description: 'an empty catalog',
      namespacesIds: [],
    },
    {
      id: 'spark_catalog3',
      description: 'Default Spark Catalog 3',
      namespacesIds: ['default2', 'ns4', 'ns5', 'ns6'],
    },
    {
      id: 'spark_catalog4',
      description: 'Default Spark Catalog 4',
      namespacesIds: ['super long namespace with tens of characters'],
    },
    {
      id: 'spark_catalog5',
      description: 'Default Spark Catalog 5',
      namespacesIds: ['namespace with a table with a very long name'],
    },
    {
      id: 'spark_catalog6',
      description: 'Default Spark Catalog 6',
      namespacesIds: ['ns10'],
    },
  ],
  namespaces: [
    {
      id: 'default',
      description: 'Default Hive database',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse',
      owner: 'public',
      properties: null,
      tablesIds: ['customers_avg_age', 'customers'],
    },
    {
      id: 'ns1',
      description: '',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns1.db',
      tablesIds: ['customers_qbeast'],
    },
    {
      id: 'ns2',
      description: '',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns2.db',
      tablesIds: [],
    },
    {
      id: 'ns3',
      description: '',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns3.db',
      tablesIds: [],
    },
    {
      id: 'default2',
      description: 'Default Hive database',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse',
      owner: 'public',
      properties: null,
      tablesIds: ['customers_salaries', 'customers2'],
    },
    {
      id: 'ns4',
      description: '',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns1.db',
      tablesIds: ['customers_qbeast2'],
    },
    {
      id: 'ns5',
      description: '',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns2.db',
      tablesIds: [],
    },
    {
      id: 'ns6',
      description: '',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns3.db',
      tablesIds: [],
    },
    {
      id: 'super long namespace with tens of characters',
      description: '',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns3.db',
      tablesIds: ['table1', 'table2', 'table3', 'table4', 'table5'],
    },
    {
      id: 'namespace with a table with a very long name',
      description: '',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns3.db',
      tablesIds: ['table with a lengthy id of tens of characters'],
    },
    {
      id: 'ns10',
      description: '',
      location_uri: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns3.db',
      tablesIds: [
        'table10',
        'table11',
        'table12',
        'table13',
        'table14',
        'table15',
        'table16',
        'table17',
        'table18',
        'table19',
        'table20',
        'table21',
        'table22',
        'table23',
        'table24',
        'table25',
        'table26',
        'table27',
        'table28',
        'table29',
        'table30',
        'table31',
        'table32',
        'table33',
        'table34',
        'table35',
        'table36',
        'table37',
        'table38',
        'table39',
        'table40',
      ],
    },
  ],
  tables: [
    {
      id: 'customers',
      description: 'test table',
      tableType: 'qbeast',
      is_temporary: false,
      provider: 'parquet',
      location: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns3.db/customer',
      owner: 'cesare',
      createdTime: 1699541121000,
      last_access: 'UNKNOWN',
      createdBy: 'Spark 3.4.0',
      statistics: '12194 bytes',
      serde_library: 'org.apache.hadoop.hive.ql.io.parquet.serde.ParquetHiveSerDe',
      input_format: 'org.apache.hadoop.hive.ql.io.parquet.MapredParquetInputFormat',
      output_format: 'org.apache.hadoop.hive.ql.io.parquet.MapredParquetOutputFormat',
      columns: [
        {
          name: 'customer_id',
          description: null,
          dataType: 'bigint',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'customer_name',
          description: null,
          dataType: 'string',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'age',
          description: null,
          dataType: 'int',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'email',
          description: null,
          dataType: 'string',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
      ],
    },
    {
      id: 'customers_avg_age',
      description: 'View on average age of customers',
      tableType: 'VIEW',
      is_temporary: false,
      owner: 'cesare',
      createdTime: 1699542121000,
      last_access: 'UNKNOWN',
      createdBy: 'Spark 3.4.0',
      type: 'VIEW',
      view_text: 'SELECT AVG(age) as avg_age from ns1.customers',
      view_original_text: 'SELECT AVG(age) as avg_age from ns1.customers',
      view_catalog_and_namespace: 'spark_catalog.default',
      view_query_output_columns: ['avg_age'],
      location: ' file:/opt/spark/worskace/top/jointest.db/customers_avg_age',
      table_properties: {
        transient_last_Ddl_time: '1693839288',
      },
      serde_library: 'org.apache.hadoop.hive.serde2.lazy.LazySimpleSerDe',
      input_format: 'org.apache.hadoop.mapred.SequenceFileInputFormat',
      output_format: 'org.apache.hadoop.hive.ql.io.HiveSequenceFileOutputFormat',
      storage_properties: {
        'serialization.format': '1',
      },
      columns: [
        {
          name: 'avg_age',
          description: null,
          dataType: 'double',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
      ],
    },
    {
      id: 'customers_qbeast',
      description: 'Customer info indexed with Qbeast',
      tableType: 'MANAGED',
      is_temporary: false,
      provider: 'qbeast',
      location: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns2.db/customers_qbeast',

      owner: 'cesare',
      createdTime: 1699541121000,
      last_access: 'UNKNOWN',
      createdBy: 'Spark 3.4.0',
      table_properties: {
        columnsToIndex: ['customer_id', 'customer_name'],
      },
      is_managed_location: true,
      columns: [
        {
          name: 'customer_id',
          description: null,
          dataType: 'bigint',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'customer_name',
          description: null,
          dataType: 'string',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'age',
          description: null,
          dataType: 'int',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'email',
          description: null,
          dataType: 'string',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
      ],
    },
    {
      id: 'customers2',
      description: 'test table',
      tableType: 'MANAGED',
      is_temporary: false,
      provider: 'parquet',
      location: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns3.db/customer',
      owner: 'cesare',
      createdTime: 1699545021000,
      last_access: 'UNKNOWN',
      createdBy: 'Spark 3.4.0',
      statistics: '12194 bytes',
      serde_library: 'org.apache.hadoop.hive.ql.io.parquet.serde.ParquetHiveSerDe',
      input_format: 'org.apache.hadoop.hive.ql.io.parquet.MapredParquetInputFormat',
      output_format: 'org.apache.hadoop.hive.ql.io.parquet.MapredParquetOutputFormat',
      columns: [
        {
          name: 'customer_id',
          description: null,
          dataType: 'bigint',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'customer_name',
          description: null,
          dataType: 'string',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'age',
          description: null,
          dataType: 'int',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'email',
          description: null,
          dataType: 'string',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
      ],
    },
    {
      id: 'customers_salaries',
      description: 'View on customers salaries',
      tableType: 'VIEW',
      is_temporary: false,
      owner: 'cesare',
      createdTime: 1699545111000,
      last_access: 'UNKNOWN',
      createdBy: 'Spark 3.4.0',
      type: 'VIEW',
      view_text: 'SELECT AVG(age) as avg_age from ns1.customers',
      view_original_text: 'SELECT AVG(age) as avg_age from ns1.customers',
      view_catalog_and_namespace: 'spark_catalog.default',
      location: ' file:/opt/spark/worskace/top/jointest.db/customers_avg_age',
      view_query_output_columns: ['avg_age'],
      table_properties: {
        transient_last_Ddl_time: '1693839288',
      },
      serde_library: 'org.apache.hadoop.hive.serde2.lazy.LazySimpleSerDe',
      input_format: 'org.apache.hadoop.mapred.SequenceFileInputFormat',
      output_format: 'org.apache.hadoop.hive.ql.io.HiveSequenceFileOutputFormat',
      storage_properties: {
        'serialization.format': '1',
      },
      columns: [
        {
          name: 'avg_age',
          description: null,
          dataType: 'double',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
      ],
    },
    {
      id: 'customers_qbeast2',
      description: 'Customer info indexed with Qbeast',
      tableType: 'MANAGED',
      is_temporary: false,
      provider: 'qbeast',
      location: 'file:/Users/cesare/workspace/spark-3.4.0-bin-hadoop3/spark-warehouse/ns2.db/customers_qbeast',

      owner: 'cesare',
      createdTime: 1699545121234,
      last_access: 'UNKNOWN',
      createdBy: 'Spark 3.4.0',
      table_properties: {
        columnsToIndex: ['customer_id', 'customer_name'],
      },
      is_managed_location: true,
      columns: [
        {
          name: 'customer_id',
          description: null,
          dataType: 'bigint',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'customer_name',
          description: null,
          dataType: 'string',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'age',
          description: null,
          dataType: 'int',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
        {
          name: 'email',
          description: null,
          dataType: 'string',
          nullable: true,
          is_partition: false,
          is_bucket: false,
        },
      ],
    },
    dummyTable('table1', 'description1'),
    dummyTable('table2', 'description2'),
    dummyTable('table3', 'description3'),
    dummyTable('table4', 'description4'),
    dummyTable('table5', 'description5'),
    dummyTable('table with a lengthy id of tens of characters', 'some description'),
    dummyTable('table10', 'description10'),
    dummyTable('table11', 'description11'),
    dummyTable('table12', 'description12'),
    dummyTable('table13', 'description13'),
    dummyTable('table14', 'description14'),
    dummyTable('table15', 'description15'),
    dummyTable('table16', 'description16'),
    dummyTable('table17', 'description17'),
    dummyTable('table18', 'description18'),
    dummyTable('table19', 'description19'),
    dummyTable('table20', 'description20'),
    dummyTable('table21', 'description21'),
    dummyTable('table22', 'description22'),
    dummyTable('table23', 'description23'),
    dummyTable('table24', 'description24'),
    dummyTable('table25', 'description25'),
    dummyTable('table26', 'description26'),
    dummyTable('table27', 'description27'),
    dummyTable('table28', 'description28'),
    dummyTable('table29', 'description29'),
    dummyTable('table30', 'description30'),
    dummyTable('table31', 'description31'),
    dummyTable('table32', 'description32'),
    dummyTable('table33', 'description33'),
    dummyTable('table34', 'description34'),
    dummyTable('table35', 'description35'),
    dummyTable('table36', 'description36'),
    dummyTable('table37', 'description37'),
    dummyTable('table38', 'description38'),
    dummyTable('table39', 'description39'),
    dummyTable('table40', 'description40'),
  ],
}
