import { tableFromArrays, Timestamp, TimeUnit, vectorFromArray } from 'apache-arrow'
import { generateHourlyWindowLastYearInMs, mockGrowingSeries } from './new-mock-helpers'

/**
 * I'm mocking the data in Spark, assuming there has this schema
 * case class IngestionHourly(
 *                             hour_window: Timestamp,
 *                             ingestion_wall_duration_ms_hist: WindowedHistogram[Long],
 *                             ingestion_process_duration_ms_hist: WindowedHistogram[Long],
 *                             num_input_files_hist: WindowedHistogram[Int],
 *                             byte_size_input_files_hist: WindowedHistogram[Long],
 *                             max_ingestion_delay_ms_hist: WindowedHistogram[Long]
 *                            )
 */

const generateMockData = () => {
  const times = generateHourlyWindowLastYearInMs()
  const total_input_bytes = mockGrowingSeries(times.length, 10, 10000)
  const total_input_files = mockGrowingSeries(times.length, 10, 10000)
  const number_of_ingested_tables = mockGrowingSeries(times.length, 10, 10000)

  return {
    hour_window: vectorFromArray(times, new Timestamp(TimeUnit.MILLISECOND, 'Etc/UTC')),
    total_input_bytes,
    total_input_files,
    number_of_ingested_tables,
  }
}

const mockData = generateMockData()

export const mockGlobalIngestionsHourly = tableFromArrays(mockData)
