import { loadOptimizationEventsForTable, WebClientResultWithoutData } from '../../spark-api'
import { duck, DuckSession } from '../../duck'
import { sanitize } from '../sql-utils'
import { optimizationEventsQuery } from './queries/optimization-events-query'

export interface OptimizationEvent {
  /**
   * When does the event start
   */
  started_at: number

  /**
   * Event duration in seconds
   */
  duration: number

  /**
   * Optimization method that was used
   */
  optimization_name: string

  /**
   * Number of files that were optimized
   */
  num_files: number

  /**
   * Number of bytes that were optimized
   */
  bytes: number
}

export const fetchOptimizationEvents = async (accessToken: string, tableName: string): Promise<OptimizationEvent[]> => {
  const result: WebClientResultWithoutData = await loadOptimizationEventsForTable(
    accessToken,
    tableName + '.optimizations'
  )
  if (result.hasFailed && result.errorCode != 113) {
    return []
  } else {
    return duck.runWithSession(async (session: DuckSession) => {
      const queryResult = await session.runQuery(optimizationEventsQuery(tableName))

      const numRows = queryResult.numRows
      const data: OptimizationEvent[] = []
      for (let i = 0; i < numRows; i++) {
        data.push({
          started_at: sanitize(queryResult.getChild('started_at').toArray()[i]),
          duration: sanitize(queryResult.getChild('duration').toArray()[i]),
          optimization_name: queryResult.getChild('optimization_name').toArray()[i].toString(),
          num_files: sanitize(queryResult.getChild('num_files').toArray()[i]),
          bytes: sanitize(queryResult.getChild('bytes').toArray()[i]),
        })
      }
      return data
    })
  }
}
