import { selectChosenConsumptionInsightsArrowTableName } from '../../../../../state/slices/catalogs-slice'
import { useDispatch, useSelector } from 'react-redux'
import { loadConsumptionsForTable, WebClientResultWithoutData } from '../../../../../transport/spark-api'
import { actions as modalActions } from '../../../../../state/slices/modal-slice'
import * as Sentry from '@sentry/react'
import { actions } from '../../../../../state/slices/consumption-insights-slice'

export const useFetchConsumptionInsights = () => {
  const dispatch = useDispatch()
  const consumptionInsightsTableName = useSelector(selectChosenConsumptionInsightsArrowTableName)

  return async (accessToken: string) => {
    dispatch(actions.startFetchingConsumptionInsights(consumptionInsightsTableName))
    const result: WebClientResultWithoutData = await loadConsumptionsForTable(accessToken, consumptionInsightsTableName)
    if (result.hasFailed) {
      dispatch(
        modalActions.showModal({
          message: 'There has been an error when retrieving consumption insights. Please reload the page.',
        })
      )
      Sentry.captureException('There has been an error when retrieving consumption insights. ' + JSON.stringify(result))
    } else {
      dispatch(actions.addConsumptionInsights(consumptionInsightsTableName))
    }
  }
}
