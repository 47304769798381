import { getConfig } from '../config/environment-config'

export const withExecutionTimeLogging = async <T>(
  functionToBeMeasured: () => Promise<T>,
  functionName: string
): Promise<T> => {
  const startTime = performance.now()
  const result: T = await functionToBeMeasured()
  const endTime = performance.now()
  const executionTime = endTime - startTime
  if (getConfig().shouldLogDebug) {
    console.debug(`${functionName} took ${executionTime} milliseconds.`)
  }
  return result
}
