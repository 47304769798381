interface ArrowAndTextProps {
  text: string
  handleClick: () => void
  isChosen?: boolean
}

export const TableLine = ({
  text,
  isChosen,
  handleClick,
}: {
  text: string
  isChosen: boolean
  handleClick: () => void
}) => (
  <button onClick={handleClick} className='ml-6 flex flex-row items-center gap-x-1'>
    <img src='/qbeast-icons/data-explorer/app-window.svg' alt='app window' />
    {isChosen ? (
      <div className='w-full text-start font-bold text-white underline'>{text}</div>
    ) : (
      <div className='w-full text-start text-white'>{text}</div>
    )}
  </button>
)

export const ExpandedCatalog = ({ text, handleClick, isChosen }: ArrowAndTextProps) => (
  <button className='flex flex-row items-center gap-x-1' onClick={handleClick}>
    <img src='/qbeast-icons/data-explorer/arrow-down2.svg' alt='arrow down' />
    <img src='/qbeast-icons/data-explorer/hard-drive.svg' alt='hard drive' />
    {isChosen ? (
      <div className='w-full text-start font-bold text-white underline'>{text}</div>
    ) : (
      <div className='w-full text-start text-white'>{text}</div>
    )}
  </button>
)
export const CollapsedCatalog = ({ text, handleClick }: ArrowAndTextProps) => (
  <button className='flex flex-row items-center gap-x-1' onClick={handleClick}>
    <img src='/qbeast-icons/data-explorer/arrow-right2.svg' alt='arrow right' />
    <img src='/qbeast-icons/data-explorer/hard-drive.svg' alt='hard drive' />
    <div className='w-full text-start text-white'>{text}</div>
  </button>
)

export const ExpandedNamespace = ({ text, handleClick, isChosen }: ArrowAndTextProps) => (
  <button className='flex flex-row items-center gap-x-1' onClick={handleClick}>
    <img src='/qbeast-icons/data-explorer/arrow-down2.svg' alt='arrow down' />
    <img src='/qbeast-icons/data-explorer/database.svg' alt='database' />
    {isChosen ? (
      <div className='w-full text-start font-bold text-white underline'>{text}</div>
    ) : (
      <div className='w-full text-start text-white'>{text}</div>
    )}
  </button>
)
export const CollapsedNamespace = ({ text, handleClick }: ArrowAndTextProps) => (
  <button className='flex flex-row items-center gap-x-1' onClick={handleClick}>
    <img src='/qbeast-icons/data-explorer/arrow-right2.svg' alt='arrow right' />
    <img src='/qbeast-icons/data-explorer/database.svg' alt='database' />
    <div className='w-full text-start text-white'>{text}</div>
  </button>
)
