import { selectChosenConsumptionInsightsArrowTableName } from '../../../../../state/slices/catalogs-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  actions,
  selectAreConsumptionInsightsFetched,
  selectConsumptionTimeSeriesWindow,
} from '../../../../../state/slices/consumption-insights-slice'
import { withExecutionTimeLogging } from '../../../../../utils/logging-utils'
import { getConsumptionTimeSeries } from '../../../../../transport/data-transformers/consumption-insights/consuption-insights-data-transformer'

export const useFetchConsumptionTimeSeries = () => {
  const dispatch = useDispatch()
  const isFetched: (tableName: string) => boolean = useSelector(selectAreConsumptionInsightsFetched)
  const timeSeriesWindow = useSelector(selectConsumptionTimeSeriesWindow)
  const consumptionInsightsTableName = useSelector(selectChosenConsumptionInsightsArrowTableName)

  return async () => {
    const forTimeSeriesWindow = timeSeriesWindow
    if (isFetched(consumptionInsightsTableName)) {
      const consumptionTimeSeries = await withExecutionTimeLogging(
        async () => await getConsumptionTimeSeries(forTimeSeriesWindow, consumptionInsightsTableName),
        'Consumption time series aggregation'
      )
      dispatch(actions.addConsumptionInsightsTimeLine({ consumptionTimeSeries, forTimeSeriesWindow }))
    }
  }
}
