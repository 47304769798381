export const ArrowDown = () => (
  <svg
    className='ml-2.5 h-2.5 w-2.5'
    aria-hidden='true'
    xmlns='http://www.w3.org/2000/svg'
    fill='none'
    viewBox='0 0 10 6'
  >
    <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 4 4 4-4' />
  </svg>
)

export const DropdownItem = ({
  handleClick,
  isDisabled,
  text,
}: {
  handleClick: () => void
  isDisabled: boolean
  text: string
}) => (
  <li>
    <button
      disabled={isDisabled}
      className='block w-full px-4 py-2 text-center hover:bg-gray-100 disabled:text-gray-300'
      onClick={handleClick}
    >
      {text}
    </button>
  </li>
)
