import { useDispatch, useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../state/slices/account-slice'
import { useFetchGlobalIngestionInsights } from '../data-explorer/data-explorer-right-side/global-overview/use-fetch-global-ingestion-insights'
import {
  actions,
  selectGlobalIngestionInsightsNotAvailableFromBackend,
  selectGlobalIngestionInsightsNumberOfIngestedTable,
  selectGlobalIngestionInsightsTotalInputFiles,
  selectGlobalIngestionTimeSeriesWindow,
  selectIsGlobalIngestionInsightsFetched,
  selectIsGlobalIngestionInsightsTimelineCalculated,
} from '../../state/slices/global-ingestion-insights-slice'
import { ChartDataPoint } from '../common/chart/types'
import { useEffect } from 'react'
import { Timeline } from '../common/chart/timeline/timeline'
import { CANVAS_IDS } from '../../constants/canvas-ids'
import { TimeSeriesType } from '../common/chart/timeline/timeline-types'
import { TimeSeriesWindow } from '../data-explorer/data-explorer-right-side/table-details/types'
import { useFetchGlobalIngestionTimeSeries } from '../data-explorer/data-explorer-right-side/global-overview/use-fetch-global-ingestion-time-series'
import { Loader } from '../common/loader'
import { getConfig } from '../../config/environment-config'

export const GlobalIngestionOverviewTimelineConnector = () => {
  const dispatch = useDispatch()
  const accessToken = useSelector(selectAccountAccessToken)

  const fetchGlobalIngestionInsights = useFetchGlobalIngestionInsights()
  useEffect(() => {
    if (!accessToken) return
    fetchGlobalIngestionInsights(accessToken).then()
  }, [accessToken])

  const isGlobalIngestionInsightsFetched = useSelector(selectIsGlobalIngestionInsightsFetched)

  const fetchGlobalIngestionTimeSeries = useFetchGlobalIngestionTimeSeries()
  const timeSeriesWindow = useSelector(selectGlobalIngestionTimeSeriesWindow)

  useEffect(() => {
    fetchGlobalIngestionTimeSeries().then()
  }, [accessToken, isGlobalIngestionInsightsFetched, timeSeriesWindow])

  const totalInputFiles: ChartDataPoint[] = useSelector(selectGlobalIngestionInsightsTotalInputFiles)
  const numberOfTableIngested: ChartDataPoint[] = useSelector(selectGlobalIngestionInsightsNumberOfIngestedTable)
  const bytesIngested: ChartDataPoint[] = useSelector(selectGlobalIngestionInsightsTotalInputFiles)
  const isTimelineCalculated = useSelector(selectIsGlobalIngestionInsightsTimelineCalculated)
  const isNotAvailableFromBackend = useSelector(selectGlobalIngestionInsightsNotAvailableFromBackend)

  if (isNotAvailableFromBackend || totalInputFiles.length <= 0)
    return (
      <>
        <h4 className='text-center text-2xl font-bold text-gray-600'>No data available</h4>
      </>
    )
  else if (!isTimelineCalculated) return <Loader></Loader>
  else
    return (
      <Timeline
        canvasId={CANVAS_IDS.GLOBAL_INGESTION_OVERVIEW_TIMELINE_ID}
        tableName='Total ingestion'
        timeSeriesData={{
          [TimeSeriesType.MAX_NUMBER_OF_MANAGED_INGESTIONS]: {
            data: numberOfTableIngested,
            label: 'Managed ingestions',
          },
          [TimeSeriesType.FILES]: {
            data: totalInputFiles,
            label: 'Files Ingested',
          },
          [TimeSeriesType.BYTES]: {
            data: bytesIngested,
            label: 'Bytes Ingested',
          },
        }}
        onTimeSeriesWindowChange={(timeSeriesWindow: TimeSeriesWindow) => {
          dispatch(actions.chooseTimeSeriesWindow(timeSeriesWindow))
        }}
        currentTimeSeriesWindow={timeSeriesWindow}
        initialLeftTimeSeries={TimeSeriesType.FILES}
        initialRightTimeSeries={TimeSeriesType.BYTES}
        isOverviewMode={false}
      />
    )
}
