import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app-store'
type CatalogId = string
type NamespaceId = string
type TableId = string

export enum TableDetailsTab {
  OVERVIEW = 'overview',
  TABLE_METADATA = 'table-metadata',
  TABLE_INSIGHTS = 'table-insights',
  CONSUMPTION_INSIGHTS = 'consumption-insights',
  INGESTION_INSIGHTS = 'ingestion-insights',
  TABLE_TEAR_DOWN = 'table-tear-down',
  TABLE_BASIC_STATS = 'table-basic-stats',
}

export enum TableInsightsTab {
  TIMELINE = 'timeline',
  SIZE = 'size',
  NUMBER_OF_ROWS = 'number-of-rows',
  OPTIMIZATION_EVENTS = 'optimization-events',
  FILE_OVERLAP = 'file-overlap',
}

export enum TableMetadataTab {
  DETAILS = 'details',
  COLUMNS = 'columns',
  HISTORY = 'history',
  TABLE_PREVIEW = 'table-preview',
  CONVERT_TO = 'convert-to',
}

export interface AddCatalogsPayload {
  catalogs: CatalogFlatStructure[]
  namespaces: NamespaceFlatStructure[]
  tables: Table[]
}

export interface CatalogFlatStructure {
  id: CatalogId
  description: string
  namespacesIds: NamespaceId[]
}

export interface NamespaceFlatStructure {
  id: NamespaceId
  description: string
  tablesIds: string[]
}

export interface Column {
  name: string
  description: string
  dataType: string
}

export interface Table {
  id: string
  description: string
  tableType: string
  createdBy: string
  createdTime: number
  owner: string
  location: string
  columns: Column[]
  hasIngestionInsights: boolean
  hasConsumptionInsights: boolean
  hasContinuousOptimization: boolean
  index: string
  cubeSize: string
}

export interface CatalogsState {
  isFetching: boolean
  areAlreadyFetched: boolean
  catalogs: Record<CatalogId, CatalogFlatStructure>
  namespaces: Record<NamespaceId, NamespaceFlatStructure>
  tables: Record<TableId, Table>
  isCatalogExpandedMap: Record<NamespaceId, boolean>
  isNamespaceExpandedMap: Record<NamespaceId, boolean>
  tableToNamespace: Record<TableId, NamespaceId>
  namespaceToCatalog: Record<NamespaceId, CatalogId>
  chosenTableId: TableId
  chosenCatalogId: CatalogId
  chosenNamespaceId: NamespaceId
  hasLoadedFromUrl: boolean
  tableDetailsTab: TableDetailsTab
  tableInsightsTab: TableInsightsTab
  tableMetadataTab: TableMetadataTab
}

export interface CatalogCompleteTree {
  id: CatalogId
  description: string
  isExpanded: boolean
  namespaces: {
    id: NamespaceId
    description: string
    isExpanded: boolean
    tables: {
      id: TableId
      description: string
      columns: Column[]
    }[]
  }[]
}

const initialCatalogsState: CatalogsState = {
  isFetching: false,
  areAlreadyFetched: false,
  catalogs: {},
  namespaces: {},
  tables: {},
  isCatalogExpandedMap: {},
  isNamespaceExpandedMap: {},
  tableToNamespace: {},
  namespaceToCatalog: {},
  chosenTableId: null,
  chosenCatalogId: null,
  chosenNamespaceId: null,
  hasLoadedFromUrl: false,
  tableDetailsTab: TableDetailsTab.TABLE_METADATA,
  tableInsightsTab: TableInsightsTab.TIMELINE,
  tableMetadataTab: TableMetadataTab.DETAILS,
}

const catalogsSlice = createSlice({
  name: 'catalogs',
  initialState: initialCatalogsState,
  reducers: {
    chooseTableDetailsTab: (state, { payload: tableDetailsTab }: PayloadAction<TableDetailsTab>) => {
      state.tableDetailsTab = tableDetailsTab
    },
    chooseTableInsightsTab: (state, { payload: tableInsightsTab }: PayloadAction<TableInsightsTab>) => {
      state.tableInsightsTab = tableInsightsTab
    },
    chooseTableMetadataTab: (state, { payload: tableMetadataTab }: PayloadAction<TableMetadataTab>) => {
      state.tableMetadataTab = tableMetadataTab
    },
    startFetchingCatalogs: (state) => {
      state.isFetching = true
    },
    stopFetchingCatalogs: (state) => {
      state.isFetching = false
    },
    expandCatalog: (state, { payload: catalogId }: PayloadAction<CatalogId>) => {
      state.isCatalogExpandedMap[catalogId] = true
      state.chosenTableId = null
      state.chosenNamespaceId = null
      state.chosenCatalogId = catalogId
    },
    expandNamespace: (state, { payload: chosenNamespaceId }: PayloadAction<NamespaceId>) => {
      state.isNamespaceExpandedMap[chosenNamespaceId] = true
      state.chosenTableId = null
      state.chosenNamespaceId = chosenNamespaceId
      state.chosenCatalogId = state.namespaceToCatalog[chosenNamespaceId]
    },
    collapseAllCatalogs: (state) => {
      Object.keys(state.catalogs).forEach((cId) => {
        state.isCatalogExpandedMap[cId] = false
      })
      Object.keys(state.namespaces).forEach((nId) => {
        state.isNamespaceExpandedMap[nId] = false
      })
      state.chosenCatalogId = null
      state.chosenNamespaceId = null
      state.chosenTableId = null
    },
    collapseCatalog: (state, { payload: catalogId }: PayloadAction<CatalogId>) => {
      state.isCatalogExpandedMap[catalogId] = false
      state.chosenNamespaceId = null
      state.catalogs[catalogId].namespacesIds.forEach((namespaceId: NamespaceId) => {
        state.isNamespaceExpandedMap[namespaceId] = false
      })
      state.chosenCatalogId = null
      state.chosenTableId = null
    },
    collapseNamespace: (state, { payload: namespaceId }: PayloadAction<NamespaceId>) => {
      state.isNamespaceExpandedMap[namespaceId] = false
      state.chosenNamespaceId = null
      state.chosenTableId = null
    },
    selectTable: (state, { payload: chosenTableId }: PayloadAction<TableId>) => {
      state.chosenTableId = chosenTableId
      state.chosenNamespaceId = state.tableToNamespace[chosenTableId]
      state.chosenCatalogId = state.namespaceToCatalog[state.tableToNamespace[chosenTableId]]
    },
    deselectTable: (state) => {
      state.chosenTableId = null
    },
    addCatalogs: (state, { payload: { catalogs, namespaces, tables } }: PayloadAction<AddCatalogsPayload>) => {
      catalogs.forEach((c) => {
        state.catalogs[c.id] = c
        state.isCatalogExpandedMap[c.id] = false
        c.namespacesIds.forEach((namespaceId: NamespaceId) => (state.namespaceToCatalog[namespaceId] = c.id))
      })
      namespaces.forEach((n) => {
        state.namespaces[n.id] = n
        state.isNamespaceExpandedMap[n.id] = false
        n.tablesIds.forEach((tableId: TableId) => (state.tableToNamespace[tableId] = n.id))
      })
      tables.forEach((t) => (state.tables[t.id] = t))
      state.isFetching = false
      state.areAlreadyFetched = true
    },
    setHasLoadedFromUrl: (state) => {
      state.hasLoadedFromUrl = true
    },
    collapseAll: (state) => {
      Object.keys(state.catalogs).forEach((cId) => {
        state.isCatalogExpandedMap[cId] = false
      })
      Object.keys(state.namespaces).forEach((nId) => {
        state.isNamespaceExpandedMap[nId] = false
      })
      state.chosenCatalogId = null
      state.chosenNamespaceId = null
      state.chosenTableId = null

      state.hasLoadedFromUrl = true
    },
    collapseAllAndExpandCatalog: (state, { payload: catalogId }: PayloadAction<CatalogId>) => {
      Object.keys(state.catalogs).forEach((cId) => {
        state.isCatalogExpandedMap[cId] = false
      })
      Object.keys(state.namespaces).forEach((nId) => {
        state.isNamespaceExpandedMap[nId] = false
      })
      state.chosenCatalogId = null
      state.chosenNamespaceId = null
      state.chosenTableId = null

      state.isCatalogExpandedMap[catalogId] = true
      state.chosenCatalogId = catalogId

      state.hasLoadedFromUrl = true
    },
    collapseAllAndExpandCatalogAndNamespace: (
      state,
      { payload: { catalogId, namespaceId } }: PayloadAction<{ catalogId: CatalogId; namespaceId: NamespaceId }>
    ) => {
      Object.keys(state.catalogs).forEach((cId) => {
        state.isCatalogExpandedMap[cId] = false
      })
      Object.keys(state.namespaces).forEach((nId) => {
        state.isNamespaceExpandedMap[nId] = false
      })
      state.chosenCatalogId = null
      state.chosenNamespaceId = null
      state.chosenTableId = null

      state.isCatalogExpandedMap[catalogId] = true
      state.chosenNamespaceId = namespaceId
      state.isNamespaceExpandedMap[namespaceId] = true
      state.chosenCatalogId = state.namespaceToCatalog[namespaceId]

      state.hasLoadedFromUrl = true
    },
    collapseAllAndExpandCatalogAndNamespaceAndSelectTable: (
      state,
      {
        payload: { catalogId, namespaceId, tableId },
      }: PayloadAction<{ catalogId: CatalogId; namespaceId: NamespaceId; tableId: TableId }>
    ) => {
      Object.keys(state.catalogs).forEach((cId) => {
        state.isCatalogExpandedMap[cId] = false
      })
      Object.keys(state.namespaces).forEach((nId) => {
        state.isNamespaceExpandedMap[nId] = false
      })
      state.chosenCatalogId = null
      state.chosenNamespaceId = null
      state.chosenTableId = null

      state.isCatalogExpandedMap[catalogId] = true
      state.chosenNamespaceId = namespaceId
      state.isNamespaceExpandedMap[namespaceId] = true
      state.chosenCatalogId = state.namespaceToCatalog[namespaceId]
      state.chosenTableId = tableId

      state.hasLoadedFromUrl = true
    },
  },
})

export const {
  startFetchingCatalogs,
  stopFetchingCatalogs,
  addCatalogs,
  expandCatalog,
  expandNamespace,
  selectTable,
  collapseAllCatalogs,
  collapseCatalog,
  collapseNamespace,
  deselectTable,
  setHasLoadedFromUrl,
  collapseAll,
  collapseAllAndExpandCatalog,
  collapseAllAndExpandCatalogAndNamespace,
  collapseAllAndExpandCatalogAndNamespaceAndSelectTable,
  chooseTableInsightsTab,
  chooseTableDetailsTab,
  chooseTableMetadataTab,
} = catalogsSlice.actions

export const selectChosenTable = (state: RootState): Table => {
  return (
    state.catalogs &&
    state.catalogs.tables &&
    state.catalogs.chosenTableId &&
    state.catalogs.tables[state.catalogs.chosenTableId]
  )
}

export const selectChosenCatalog = (state: RootState): CatalogFlatStructure => {
  return (
    state.catalogs &&
    state.catalogs.catalogs &&
    state.catalogs.chosenCatalogId &&
    state.catalogs.catalogs[state.catalogs.chosenCatalogId]
  )
}

export const selectChosenNamespace = (state: RootState): NamespaceFlatStructure => {
  return (
    state.catalogs &&
    state.catalogs.namespaces &&
    state.catalogs.chosenNamespaceId &&
    state.catalogs.namespaces[state.catalogs.chosenNamespaceId]
  )
}
export const selectHasLoadedFromUrl = (state: RootState): boolean => state.catalogs.hasLoadedFromUrl
export const selectCatalogs = (state: RootState): Record<CatalogId, CatalogFlatStructure> => state.catalogs.catalogs
const selectNamespaces = (state: RootState): Record<NamespaceId, NamespaceFlatStructure> => state.catalogs.namespaces
const selectTables = (state: RootState): Record<TableId, Table> => state.catalogs.tables

export const selectCatalogsList: (state: RootState) => CatalogFlatStructure[] = createSelector(
  selectCatalogs,
  (catalogs) => {
    const result: CatalogFlatStructure[] = Object.values(catalogs)
    return result
  }
)

export const selectNamespacesList = createSelector(
  selectChosenCatalog,
  selectNamespaces,
  (chosenCatalog, namespaces) => {
    const result = []
    if (chosenCatalog) {
      chosenCatalog.namespacesIds.forEach((namespaceId: NamespaceId) => {
        result.push(namespaces[namespaceId])
      })
    }
    return result
  }
)

export const selectTablesList = createSelector(selectChosenNamespace, selectTables, (chosenNamespace, tables) => {
  const result = []
  if (chosenNamespace) {
    chosenNamespace.tablesIds.forEach((tableId: TableId) => {
      result.push(tables[tableId])
    })
  }
  return result
})

export const selectChosenCatalogId = (state: RootState): TableId => state.catalogs.chosenCatalogId
export const selectChosenNamespaceId = (state: RootState): TableId => state.catalogs.chosenNamespaceId
export const selectChosenTableId = (state: RootState): TableId => state.catalogs.chosenTableId

export const selectHasIngestionInsights = (state: RootState): boolean =>
  state.catalogs.tables[state.catalogs.chosenTableId].hasIngestionInsights

export const selectHasConsumptionInsights = (state: RootState): boolean =>
  state.catalogs.tables[state.catalogs.chosenTableId].hasConsumptionInsights

export const selectHasContinuousOptimization = (state: RootState): boolean =>
  state.catalogs.tables[state.catalogs.chosenTableId].hasContinuousOptimization

export const selectChosenOptimizationTableName = createSelector(
  [selectChosenCatalogId, selectChosenNamespaceId, selectChosenTableId],
  (catalogId, namespaceId, tableId) => `use_catalog.${catalogId}.${namespaceId}.${tableId}`
)

export const selectChosenTableInsightsArrowTableName = createSelector(
  [selectChosenCatalogId, selectChosenNamespaceId, selectChosenTableId],
  (catalogId, namespaceId, tableId) => `use_catalog.${catalogId}.${namespaceId}.${tableId}.files_hourly`
)

export const selectChosenConsumptionInsightsArrowTableName = createSelector(
  [selectChosenCatalogId, selectChosenNamespaceId, selectChosenTableId],
  (catalogId, namespaceId, tableId) => `use_catalog.${catalogId}.${namespaceId}.${tableId}.consumptions_hourly`
)

export const selectChosenIngestionInsightsArrowTableName = createSelector(
  [selectChosenCatalogId, selectChosenNamespaceId, selectChosenTableId],
  (catalogId, namespaceId, tableId) => `use_catalog.${catalogId}.${namespaceId}.${tableId}.ingestions_hourly`
)
export const selectColumnsOfChosenTable = (state: RootState): Column[] =>
  selectChosenTable(state) && selectChosenTable(state).columns

export const selectIsFetchingCatalogs = (state: RootState): boolean => state.catalogs.isFetching
export const selectAreAlreadyFetched = (state: RootState): boolean => state.catalogs.areAlreadyFetched
export const selectIsCatalogFound = createSelector(
  selectCatalogs,
  (catalogs) => (catalogId: CatalogId) => !!catalogs[catalogId]
)
export const selectIsNamespaceFound = createSelector(
  selectNamespaces,
  (namespaces) => (namespaceId: NamespaceId) => !!namespaces[namespaceId]
)

export const selectIsTableFound = createSelector(selectTables, (tables) => (tableId: TableId) => !!tables[tableId])

const selectIsCatalogExpandedMap = (state: RootState): Record<NamespaceId, boolean> =>
  state.catalogs.isCatalogExpandedMap
const selectIsNamespaceExpandedMap = (state: RootState): Record<NamespaceId, boolean> =>
  state.catalogs.isNamespaceExpandedMap

export const selectCatalogsCompleteTree = createSelector(
  selectCatalogs,
  selectNamespaces,
  selectTables,
  selectIsCatalogExpandedMap,
  selectIsNamespaceExpandedMap,
  (catalogs, namespaces, tables, isCatalogExpandedMap, isNamespaceExpandedMap): CatalogCompleteTree[] => {
    return Object.values(catalogs).map((c: CatalogFlatStructure) => {
      const resultCatalog = {
        ...c,
        isExpanded: isCatalogExpandedMap[c.id],
        namespaces: c.namespacesIds.map((namespaceId: NamespaceId) => {
          const namespace = namespaces[namespaceId]
          const resultNamespace = {
            ...namespace,
            isExpanded: isNamespaceExpandedMap[namespaceId],
            tables: namespace.tablesIds.map((tableId: TableId) => {
              return {
                ...tables[tableId],
              }
            }),
          }
          delete resultNamespace.tablesIds
          return resultNamespace
        }),
        namespacesIds: undefined,
      }
      delete resultCatalog.namespacesIds
      return resultCatalog
    })
  }
)

export const selectChosenTableDetailsTab = (state: RootState): TableDetailsTab => state.catalogs.tableDetailsTab
export const selectChosenTableInsightsTab = (state: RootState): TableInsightsTab => state.catalogs.tableInsightsTab
export const selectChosenTableMetadataTab = (state: RootState): TableMetadataTab => state.catalogs.tableMetadataTab

export const selectChosenTabs = createSelector(
  selectChosenTableDetailsTab,
  selectChosenTableInsightsTab,
  selectChosenTableMetadataTab,
  (
    tableDetailsTab: TableDetailsTab,
    tableInsightsTab: TableInsightsTab,
    tableMetadataTab: TableMetadataTab
  ): [TableDetailsTab, TableInsightsTab | TableMetadataTab | null] => {
    let secondTab: TableInsightsTab | TableMetadataTab | null
    if (tableDetailsTab === TableDetailsTab.TABLE_INSIGHTS) {
      secondTab = tableInsightsTab
    } else if (tableDetailsTab === TableDetailsTab.TABLE_METADATA) {
      secondTab = tableMetadataTab
    } else {
      secondTab = null
    }
    return [tableDetailsTab, secondTab]
  }
)
export default catalogsSlice.reducer
