import { useSelector } from 'react-redux'
import {
  selectTimeSeries,
  selectTableInsightsTimeSeriesWindow,
} from '../../../../../../state/slices/table-insights-slice'
import { useEffect } from 'react'
import { Loader } from '../../../../../common/loader'
import { OptimizeTableButton } from '../../../../../common/optimize-table-button'
import { TableInsightsTimelineConnector } from './table-insights-timeline-connector'
import {
  selectChosenTable,
  selectChosenTableInsightsArrowTableName,
  selectHasConsumptionInsights,
  selectHasContinuousOptimization,
} from '../../../../../../state/slices/catalogs-slice'
import { useFetchTableTimeSeries } from './use-fetch-table-time-series'
import { ContOptimizeTableButton } from '../../../../../common/cont-optimize-table-button'

export const TableInsightsTimeline = () => {
  const tableInsightsArrowTableName = useSelector(selectChosenTableInsightsArrowTableName)
  const timeSeries = useSelector(selectTimeSeries)
  const tableInsightsTimeSeriesWindow = useSelector(selectTableInsightsTimeSeriesWindow)
  const table = useSelector(selectChosenTable)

  const fetchTableTimeSeries = useFetchTableTimeSeries()
  useEffect(() => {
    fetchTableTimeSeries().then()
  }, [tableInsightsTimeSeriesWindow, tableInsightsArrowTableName])

  return (
    <div className='mt-4 flex w-full flex-col items-center p-4'>
      {timeSeries ? (
        <div className='mb-20 flex h-full w-10/12 flex-col items-center justify-center 3xl:w-3/4'>
          <div className='flex-grow flex-row'>
            {table.tableType === 'qbeast' && <OptimizeTableButton />}
            &nbsp;
            {table.tableType === 'qbeast' && <ContOptimizeTableButton />}
          </div>
          <TableInsightsTimelineConnector />
        </div>
      ) : (
        <div className='w-full'>
          <Loader />
        </div>
      )}
    </div>
  )
}
