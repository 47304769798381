import { TableInsightsMetric } from './table-insights-metric'
import { bytesToReadable } from '../../../../../utils/conversion-utils'
import { CANVAS_IDS } from '../../../../../constants/canvas-ids'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectAreTableInsightsFetched,
  selectChosenPeriod,
  selectSizeStats,
  actions,
} from '../../../../../state/slices/table-insights-slice'
import { useEffect } from 'react'
import { Period } from '../types'
import { Loader } from '../../../../common/loader'
import { getTableInsightsStats } from '../../../../../transport/data-transformers/table-insights/table-insights-data-transformer'
import { selectChosenTableInsightsArrowTableName } from '../../../../../state/slices/catalogs-slice'

export const TableInsightsSize = () => {
  const filesInsightTableName = useSelector(selectChosenTableInsightsArrowTableName)
  const areTableDetailsFetched: (tableName: string) => boolean = useSelector(selectAreTableInsightsFetched)
  const period = useSelector(selectChosenPeriod)
  const dispatch = useDispatch()
  const sizeInStats = useSelector(selectSizeStats)

  const fetchSizeStats = async () => {
    const forPeriod = period
    if (areTableDetailsFetched(filesInsightTableName)) {
      dispatch(actions.startCalculatingTableInsightsSizeStats())
      const stats = await getTableInsightsStats(period, 'file_bytes_hist', filesInsightTableName)
      dispatch(actions.addTableInsightsSizeStats({ sizeStats: stats, forPeriod }))
    }
  }

  useEffect(() => {
    fetchSizeStats().then()
  }, [period, filesInsightTableName])

  return (
    <div className='mt-24 flex w-full flex-col items-center p-4'>
      {sizeInStats ? (
        <TableInsightsMetric
          period={period}
          handlePeriodClick={(period: Period) => dispatch(actions.choosePeriod(period))}
          data={sizeInStats}
          canvasId={CANVAS_IDS.TABLE_INSIGHTS_SIZE_HISTOGRAM_ID}
          conversionFunction={bytesToReadable}
          label={'File Size'}
          labelForTotal={'Size'}
          labelForCount={'Number of Files'}
          labelForBarHover='# of files with sizes between'
        />
      ) : (
        <div className='w-full'>
          <Loader />
        </div>
      )}
    </div>
  )
}
