import { Link } from 'react-router-dom'

interface SideBarButtonProps {
  imageWidth: number
  imageHeight: number
  imageLink: string
  text: string
  routeTo: string
}

export const SideBarButton = ({ imageHeight, imageWidth, imageLink, text, routeTo }: SideBarButtonProps) => {
  return (
    <Link to={routeTo}>
      <div className='flex flex-row gap-x-2'>
        <img src={imageLink} width={imageWidth} height={imageHeight} />
        <span className='text-white'>{text}</span>
      </div>
    </Link>
  )
}
