import { tableFromArrays, Timestamp, TimeUnit, vectorFromArray } from 'apache-arrow'
import { generateHourlyWindowLastYearInMs, mockGrowingSeries } from './new-mock-helpers'

/**
/**
 * This data is mocked to simulate the data in Spark with the following schema:
 * case class FilesHourly(
 *     hour_window: Timestamp, // Represents the hourly time window
 *     total_bytes: Long, // Represents the total bytes
 *     total_rows: Long, // Represents the total rows
 *     number_of_tables: Long, // Represents the number of tables
 * )
 */

const generateMockData = () => {
  const times = generateHourlyWindowLastYearInMs()
  const total_bytes = mockGrowingSeries(times.length, 10, 10000)
  const total_rows = mockGrowingSeries(times.length, 10, 10000)
  const number_of_tables = mockGrowingSeries(times.length, 10, 20)

  return {
    hour_window: vectorFromArray(times, new Timestamp(TimeUnit.MILLISECOND, 'Etc/UTC')),
    total_bytes,
    total_rows,
    number_of_tables,
  }
}

const mockData = generateMockData()

export const mockGlobalFilesHourly = tableFromArrays(mockData)
