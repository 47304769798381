import { CANVAS_IDS } from '../../../../../constants/canvas-ids'
import { useDispatch, useSelector } from 'react-redux'
import {
  actions,
  selectConsumptionInsightsBytesReadData,
  selectConsumptionInsightsFilesReadData,
  selectConsumptionTimeSeriesWindow,
} from '../../../../../state/slices/consumption-insights-slice'
import { ChartDataPoint } from '../../../../common/chart/types'
import { Timeline } from '../../../../common/chart/timeline/timeline'
import { TimeSeriesType } from '../../../../common/chart/timeline/timeline-types'
import { TimeSeriesWindow } from '../types'
import { selectChosenConsumptionInsightsArrowTableName } from '../../../../../state/slices/catalogs-slice'

export const ConsumptionInsightsTimelineConnector = () => {
  const dispatch = useDispatch()
  const timeSeriesWindow = useSelector(selectConsumptionTimeSeriesWindow)
  const consumptionInsightsArrowTableName = useSelector(selectChosenConsumptionInsightsArrowTableName)
  const bytesRead: ChartDataPoint[] = useSelector(selectConsumptionInsightsBytesReadData)
  // const rowsRead: ChartDataPoint[] = useSelector(selectConsumptionInsightsRowsReadData)
  const filesRead: ChartDataPoint[] = useSelector(selectConsumptionInsightsFilesReadData)

  return (
    <Timeline
      canvasId={CANVAS_IDS.CONSUMPTION_DETAILS_TIMELINE_ID}
      tableName={consumptionInsightsArrowTableName}
      timeSeriesData={{
        [TimeSeriesType.BYTES]: {
          data: bytesRead,
          label: 'Bytes Read',
        },
        /*      [TimeSeriesType.ROWS]: {
                data: rowsRead,
                label: 'Rows Read',
              },*/
        [TimeSeriesType.FILES]: {
          data: filesRead,
          label: 'Files Read',
        },
      }}
      onTimeSeriesWindowChange={(timeSeriesWindow: TimeSeriesWindow) => {
        dispatch(actions.chooseTimeSeriesWindow(timeSeriesWindow))
      }}
      currentTimeSeriesWindow={timeSeriesWindow}
      initialLeftTimeSeries={TimeSeriesType.FILES}
      initialRightTimeSeries={TimeSeriesType.BYTES}
    />
  )
}
