import { useDispatch, useSelector } from 'react-redux'
import {
  actions,
  selectGlobalFilesInsightsTimeSeriesWindow,
  selectIsGlobalFilesInsightsFetched,
} from '../../../../state/slices/global-files-insights-slice'
import { withExecutionTimeLogging } from '../../../../utils/logging-utils'
import {
  getGlobalFilesTimeSeries,
  GlobalFilesTimeSeries,
} from '../../../../transport/data-transformers/global-table-insights/global-files-insights-data-transformer'

export const useFetchGlobalFilesTimeSeries = () => {
  const dispatch = useDispatch()
  const isFetched = useSelector(selectIsGlobalFilesInsightsFetched)
  const timeSeriesWindow = useSelector(selectGlobalFilesInsightsTimeSeriesWindow)

  return async () => {
    const forTimeSeriesWindow = timeSeriesWindow
    if (isFetched) {
      const globalFilesTimeSeries: GlobalFilesTimeSeries = await withExecutionTimeLogging(
        async () => await getGlobalFilesTimeSeries(forTimeSeriesWindow),
        'Global files time series aggregation'
      )
      if (globalFilesTimeSeries !== null) {
        dispatch(
          actions.addGlobalFilesInsightsTimeLine({
            numberOfTables: globalFilesTimeSeries.numberOfTables,
            totalRows: globalFilesTimeSeries.totalRows,
            totalBytes: globalFilesTimeSeries.totalBytes,
            forTimeSeriesWindow,
          })
        )
      }
    }
  }
}
