import { TimeSeriesWindow } from '../../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { generateDenseSeries, packColumns } from '../../sql-utils'

export const globalFilesTimeSeriesQuery = (timeWindow: TimeSeriesWindow): string => {
  const duckTableName = 'use_catalog_system_lake_tables_files_hourly'
  const seriesSQL = generateDenseSeries(duckTableName, timeWindow)
  return `WITH
  ${seriesSQL},
  NumberOf AS (
  SELECT r.time_window,
      SUM(CASE WHEN total_bytes is null THEN 0 ELSE total_bytes END) as totalBytes,
      SUM(CASE WHEN total_rows is null THEN 0 ELSE total_rows END) as totalRows,
      MAX(CASE WHEN number_of_tables is null THEN 0 ELSE number_of_tables END) as numberOfTables
    FROM Windowed w RIGHT JOIN RANGES r on (r.time_window = w.time_window)
    GROUP BY r.time_window
    ORDER BY r.time_window ASC
    )
    
     ${packColumns(['totalBytes', 'totalRows', 'numberOfTables'], 'NumberOf')}
`
}
