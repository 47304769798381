import { useAuth0 } from '@auth0/auth0-react'

const LogoutButton = () => {
  const { logout } = useAuth0()

  return (
    <button
      className='hover:white rounded-full bg-gray-600 px-8 py-2 font-semibold text-gray-50 shadow-sm duration-300 hover:bg-gray-800 hover:shadow-md'
      onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
    >
      log out
    </button>
  )
}

export default LogoutButton
