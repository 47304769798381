import { useAuth0 } from '@auth0/auth0-react'
import { ReactElement, useEffect } from 'react'
import { Loader } from '../components/common/loader'
import { getConfig } from '../config/environment-config'

export const ProtectedRoute = ({ children }: { children: ReactElement }): ReactElement => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0()

  useEffect(() => {
    if (!isLoading && !isAuthenticated && getConfig().shouldRequireLogin) {
      loginWithRedirect().then()
    }
  }, [isLoading, isAuthenticated])

  return (isLoading || !isAuthenticated) && getConfig().shouldRequireLogin ? (
    <div className='h-screen w-full'>
      <Loader />
    </div>
  ) : (
    children
  )
}
