import { EXTERNAL_LINKS } from '../../constants/external-links'
import { Link } from 'react-router-dom'
import { ROUTES } from '../../routing/routes'

export const Help = () => {
  return (
    <div className='flex h-screen grow flex-row items-center bg-gray-50 p-2 text-center text-gray-600 md:p-8'>
      <div className='w-0 xl:w-1/6 2xl:w-1/4 4xl:w-1/3'></div>
      <div className='w-full rounded-3xl bg-white p-4 shadow md:px-12 md:py-8 xl:w-2/3 2xl:w-1/2 4xl:w-1/3'>
        <div className='flex flex-col items-start justify-between gap-y-8 md:flex-row md:items-end md:gap-y-0'>
          <div className='flex flex-col items-start text-left'>
            <h1 className='mt-6 text-4xl font-medium'>HELP</h1>
            <h2 className='mt-6 text-xl'>Join our slack:</h2>
            <a className='mt-2' target=':blank' href={EXTERNAL_LINKS.X_TWITTER}>
              <div className='flex cursor-pointer flex-row gap-x-2 hover:underline'>
                <span className='text-2xl font-medium text-gray-700 duration-300 hover:text-black'>Slack</span>
                <img src={'/qbeast-icons/help/slack.svg'} width={24} height={24} />
              </div>
            </a>
            <h2 className='mt-6 text-xl'>Browse our help resources:</h2>
            <a className='mt-2' target=':blank' href={EXTERNAL_LINKS.NOTION_HELP_CENTER}>
              <div className='flex cursor-pointer flex-row gap-x-2 hover:underline'>
                <span className='text-2xl font-medium text-gray-700 duration-300 hover:text-black'>
                  U.S.E. Help Center
                </span>
                <img src={'/qbeast-icons/help/notion.svg'} width={24} height={24} />
              </div>
            </a>
            <h2 className='mt-6 text-xl'>Write to us at:</h2>
            <a
              className='text-2xl font-medium text-gray-700 duration-300 hover:text-black'
              href='mailto: support@qbeast.io'
            >
              support@qbeast.io
            </a>
            <h2 className='mt-6 text-xl'>
              or contact us through other{' '}
              <Link className='text-2xl font-medium text-gray-700 duration-300 hover:text-black' to={ROUTES.CONTACT}>
                channels
              </Link>
            </h2>
          </div>
        </div>
      </div>
    </div>
  )
}
