import { CANVAS_IDS } from '../../../../../constants/canvas-ids'
import { useSelector } from 'react-redux'
import { selectIngestionTimeSeriesWindow } from '../../../../../state/slices/ingestion-insights-slice'
import { ChartDataPoint } from '../../../../common/chart/types'
import { Timeline } from '../../../../common/chart/timeline/timeline'
import { TimeSeriesType } from '../../../../common/chart/timeline/timeline-types'
import { selectChosenTableInsightsArrowTableName } from '../../../../../state/slices/catalogs-slice'
import {
  selectTableInsightsBytesReadData,
  selectTableInsightsFilesReadData,
  selectTableInsightsRowsReadData,
} from '../../../../../state/slices/table-insights-slice'

export const TableInsightsOverviewTimelineConnector = () => {
  const filesInsightTableName = useSelector(selectChosenTableInsightsArrowTableName)
  const timeSeriesWindow = useSelector(selectIngestionTimeSeriesWindow)
  const bytesRead: ChartDataPoint[] = useSelector(selectTableInsightsBytesReadData)
  const rowsRead: ChartDataPoint[] = useSelector(selectTableInsightsRowsReadData)
  const filesRead: ChartDataPoint[] = useSelector(selectTableInsightsFilesReadData)

  return (
    <Timeline
      canvasId={CANVAS_IDS.TABLE_INSIGHTS_OVERVIEW_TIMELINE_ID}
      tableName={filesInsightTableName}
      timeSeriesData={{
        [TimeSeriesType.BYTES]: {
          data: bytesRead,
          label: 'Bytes Created',
        },
        [TimeSeriesType.ROWS]: {
          data: rowsRead,
          label: 'Bytes Created',
        },
        [TimeSeriesType.FILES]: {
          data: filesRead,
          label: 'Files Created',
        },
      }}
      onTimeSeriesWindowChange={() => {}}
      currentTimeSeriesWindow={timeSeriesWindow}
      initialLeftTimeSeries={TimeSeriesType.FILES}
      initialRightTimeSeries={TimeSeriesType.ROWS}
      isOverviewMode={true}
    />
  )
}
