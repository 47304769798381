import { tableFromArrays, Timestamp, TimeUnit, vectorFromArray } from 'apache-arrow'
import { generateHourlyWindowLastYearInMs, mockWindowedHistogramGrowingSeries } from './new-mock-helpers'

/**
 * I'm mocking the data in Spark, assuming there has this schema
 * case class IngestionHourly(
 *                             hour_window: Timestamp,
 *                             ingestion_wall_duration_ms_hist: WindowedHistogram[Long],
 *                             ingestion_process_duration_ms_hist: WindowedHistogram[Long],
 *                             num_input_files_hist: WindowedHistogram[Int],
 *                             byte_size_input_files_hist: WindowedHistogram[Long],
 *                             max_ingestion_delay_ms_hist: WindowedHistogram[Long]
 *                            )
 */

const generateMockData = () => {
  const times = generateHourlyWindowLastYearInMs()
  const ingestion_wall_duration_ms_hist = mockWindowedHistogramGrowingSeries(times.length, 10, 10000, 20_000, 130_000)
  const ingestion_process_duration_ms_hist = mockWindowedHistogramGrowingSeries(times.length, 10, 10000, 10, 50_000)
  const num_input_files_hist = mockWindowedHistogramGrowingSeries(times.length, 10, 10000, 1, 100)

  const byte_size_input_files_hist = mockWindowedHistogramGrowingSeries(
    times.length,
    10,
    10000,
    10_000_000,
    500_000_000
  )
  const max_ingestion_delay_ms_hist = mockWindowedHistogramGrowingSeries(times.length, 10, 10000, 100_000, 1_000_000)
  return {
    hour_window: vectorFromArray(times, new Timestamp(TimeUnit.MILLISECOND, 'Etc/UTC')),
    ingestion_wall_duration_ms_hist,
    ingestion_process_duration_ms_hist,
    num_input_files_hist,
    byte_size_input_files_hist,
    max_ingestion_delay_ms_hist,
  }
}

const mockData = generateMockData()

export const mockIngestionsHourly = tableFromArrays(mockData)
