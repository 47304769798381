import { EXTERNAL_LINKS } from '../../../../constants/external-links'
import { SideBarButton } from './side-bar-button'
import { ROUTES } from '../../../../routing/routes'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectAccountData, selectIsSignedIn } from '../../../../state/slices/account-slice'

export const SideBarContent = () => {
  const { name, picture } = useSelector(selectAccountData)
  const isSignedIn = useSelector(selectIsSignedIn)
  return (
    <>
      <div className='flex flex-col pl-4'>
        <img className='mb-10' src='/logos/new-qbeast-logo.png' width='130' />
        <div className='flex flex-col gap-y-8'>
          <SideBarButton
            imageWidth={22}
            imageHeight={20}
            imageLink={'/qbeast-icons/side-bar/chart.svg'}
            text='Overview'
            routeTo={ROUTES.OVERVIEW}
          />
          <SideBarButton
            imageWidth={20}
            imageHeight={16}
            imageLink={'/qbeast-icons/side-bar/folder.svg'}
            text='Data Explorer'
            routeTo={ROUTES.DATA_EXPLORER}
          />
          <SideBarButton
            imageWidth={22}
            imageHeight={22}
            imageLink={'/qbeast-icons/side-bar/round-question-mark.svg'}
            text='Help'
            routeTo={ROUTES.HELP}
          />
          <SideBarButton
            imageWidth={22}
            imageHeight={22}
            imageLink={'/qbeast-icons/side-bar/share.svg'}
            text='Contact'
            routeTo={ROUTES.CONTACT}
          />
        </div>
      </div>
      <div className='mb-6 flex w-full flex-col items-center gap-y-5'>
        <a target='_blank' rel='noopener noreferrer' href={import.meta.env.VITE_CODER_LINK}>
          <button className='flex flex-row items-center gap-x-4 rounded-full bg-primary px-4 py-2 shadow-lg hover:bg-primaryLight'>
            <img src='/qbeast-icons/side-bar/ide-on-monitor.svg' />
            <span className='text-lg font-bold text-white'>Data Studio</span>
          </button>
        </a>
        <div className='h-px w-full px-6'>
          <hr className='h-px w-full bg-white' />
        </div>
        <Link to={ROUTES.ACCOUNT}>
          <div className='flex flex-row gap-x-2'>
            {isSignedIn ? (
              <>
                <img src={picture} width='28' height='28' className='rounded-full' />
                <span className='text-lg font-medium text-gray-100'>{name ? name : 'account'}</span>
              </>
            ) : (
              <>
                <img src='/qbeast-icons/side-bar/avatar.svg' width='22' height='22' />
                <span className='text-lg font-medium text-gray-100'>profile</span>
              </>
            )}
          </div>
        </Link>
      </div>
    </>
  )
}
