import { TimeSeries } from '../../response-models'
import { TimeSeriesWindow } from '../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { sanitize } from '../sql-utils'
import { consumptionTimeSeries } from './queries/files-summary-time-series'
import { duck, DuckSession } from '../../duck'

export interface ConsumptionTimeSeries {
  numberOfBytesRead: TimeSeries<number>
  //numberOfRowsRead: TimeSeries<number>
  numberOfFilesRead: TimeSeries<number>
}

/**
 * This function uses the data from TableInsight to return a time window that shows
 * how the number of files, bytes and rows changed over time.
 * @param timeWindow
 * @param consumptionInsightsTable
 */

export const getConsumptionTimeSeries = async (
  timeWindow: TimeSeriesWindow,
  consumptionInsightsTable: string
): Promise<ConsumptionTimeSeries> => {
  const query = consumptionTimeSeries(consumptionInsightsTable, timeWindow)

  return duck.runWithSession(async (session: DuckSession) => {
    const queryResult = await session.runQuery(query)
    const firstRow = queryResult.get(0).toJSON()
    if (firstRow && firstRow.number_of_files_read && firstRow.bytes_read && firstRow.bytes_read.x) {
      const { number_of_files_read, bytes_read } = firstRow

      return {
        numberOfBytesRead: {
          windowUnit: timeWindow,
          x: bytes_read.x.toJSON().map(sanitize) as number[],
          y: bytes_read.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
        numberOfFilesRead: {
          windowUnit: timeWindow,
          x: number_of_files_read.x.toJSON().map(sanitize) as number[],
          y: number_of_files_read.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
        /* numberOfRowsRead: {
           windowUnit: timeWindow,
           x: rows_read.x.toJSON().map(sanitize) as number[],
           y: rows_read.y.toJSON().map(sanitize) as number[],
         } as TimeSeries<number>,*/
      }
    } else {
      return {
        numberOfBytesRead: {
          windowUnit: timeWindow,
          x: [],
          y: [],
        } as TimeSeries<number>,
        numberOfFilesRead: {
          windowUnit: timeWindow,
          x: [],
          y: [],
        },
      }
    }
  })
}
