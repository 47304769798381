import { Navigate, Route, Routes } from 'react-router-dom'

import { ROUTES } from '../routing/routes'
import { OverviewView } from '../components/overview/overview-view'
import { NotificationsView } from '../components/notifications/notifications-view'
import { HelpView } from '../components/help/help-view'
import { DataExplorerView } from '../components/data-explorer/data-explorer-view'
import { AccountView } from '../components/account/account-view'
import { ContactView } from '../components/contact/contact-view'

export const ProtectedRoutes = () => (
  <Routes>
    <Route path={'/'} element={<OverviewView />} />
    <Route path={ROUTES.NOTIFICATIONS} element={<NotificationsView />} />
    <Route path={ROUTES.DATA_EXPLORER_TABLE_WITH_TOP_AND_BOTTOM_TABS} element={<DataExplorerView />} />
    <Route path={ROUTES.DATA_EXPLORER_TABLE_WITH_TOP} element={<DataExplorerView />} />
    <Route path={ROUTES.DATA_EXPLORER_NAMESPACE} element={<DataExplorerView />} />
    <Route path={ROUTES.DATA_EXPLORER_CATALOG} element={<DataExplorerView />} />
    <Route path={ROUTES.DATA_EXPLORER} element={<DataExplorerView />} />
    <Route path={ROUTES.OVERVIEW} element={<OverviewView />} />
    <Route path={ROUTES.HELP} element={<HelpView />} />
    <Route path={ROUTES.CONTACT} element={<ContactView />} />
    <Route path={ROUTES.ACCOUNT} element={<AccountView />} />
    <Route path='/*' element={<Navigate to='/' />} />
  </Routes>
)
