import {
  Cell4,
  ColumnTitle4,
  Table as HtmlTable,
  TableBodyRow,
  TableHeadRow,
  TableWrapper,
} from '../../common/table/table-atoms'
import { useDispatch, useSelector } from 'react-redux'
import { selectTable, selectTablesList, Table } from '../../../state/slices/catalogs-slice'

export const NamespaceRow = ({ table: { description, id, owner, tableType } }: { table: Table }) => {
  const dispatch = useDispatch()

  return (
    <TableBodyRow>
      <Cell4
        text={id}
        handleClick={() => {
          dispatch(selectTable(id))
        }}
      />
      <Cell4 text={description || 'NO DESCRIPTION'} />
      <Cell4 text={tableType || 'NO TYPE'} />
      <Cell4 text={owner || 'NO OWNER'} />
    </TableBodyRow>
  )
}

export const NamespaceDetails = () => {
  const tables = useSelector(selectTablesList)

  return (
    <>
      {tables.length === 0 ? (
        <div className='flex h-full w-full flex-col items-center justify-center'>
          <h1 className='text-3xl font-bold text-gray-700'>There are no tables in the selected namespace</h1>
        </div>
      ) : (
        <div className='mt-16 flex w-full flex-col items-center'>
          <h1 className='text-3xl font-bold text-gray-700'>Tables</h1>
          <div className='mt-16 flex w-full flex-col items-center justify-center p-4 xl:w-2/3 2xl:w-1/2'>
            <TableWrapper>
              <HtmlTable>
                <TableHeadRow>
                  <ColumnTitle4 title='name' />
                  <ColumnTitle4 title='description' />
                  <ColumnTitle4 title='type' />
                  <ColumnTitle4 title='owner' />
                </TableHeadRow>
                <tbody>
                  {tables.map((t: Table) => (
                    <NamespaceRow key={t.id} table={t} />
                  ))}
                </tbody>
              </HtmlTable>
            </TableWrapper>
          </div>
        </div>
      )}
    </>
  )
}
