import { Chart, TooltipItem } from 'chart.js'
import { useEffect, useRef } from 'react'
import { Stats } from '../../../../../transport/response-models'
import { BRAND_COLORS } from '../../../../../constants/brand-colors'
import { getZoomConfig } from '../../../../common/chart/chart-utils'
import { ChartNavigationButtons } from '../../../../common/chart/timeline/chart-navigation-buttons'
import { ChartCanvas } from '../../../../common/chart/chart-canvas'
import { useAddEventsForChartZoom } from '../../../../common/chart/use-add-events-for-chart-zoom'
import { initializeChartjsIfNotInitialized } from '../../../../common/chart/chart-initializer'
import { TimeSeriesType } from '../../../../common/chart/timeline/timeline-types'
import { bytesToReadable, toMaxTwoFloatingPoints } from '../../../../../utils/conversion-utils'
import { ChartDataPoint } from '../../../../common/chart/types'

interface TableInsightsHistogramProps {
  stats: Stats<number>
  canvasId: string
  label: string
  labelForBarHover: string
  conversionFunction: (value: number) => string
}

initializeChartjsIfNotInitialized()
export const TableInsightsHistogram = ({
  stats,
  canvasId,
  label,
  labelForBarHover,
  conversionFunction,
}: TableInsightsHistogramProps) => {
  const { chartNavigationMode, setChartNavigationMode } = useAddEventsForChartZoom()
  const chartRef = useRef<Chart>(null)
  const histogram = stats.histogram
  const resetHistogram = () => {
    if (chartRef.current) {
      chartRef.current.destroy()
    }
    const binWidth = (stats.max - stats.min) / histogram.length
    const x = histogram.map((bin) => bin.binIndex * binWidth + stats.min)

    const y = histogram.map((bin) => bin.binCount)

    chartRef.current = new Chart(document.getElementById(canvasId) as HTMLCanvasElement, {
      type: 'bar',
      data: {
        labels: x,
        datasets: [
          {
            label: label,
            barPercentage: 0.99,
            categoryPercentage: 0.99,
            data: y,
            backgroundColor: BRAND_COLORS.ACCENT,
          },
        ],
      },
      options: {
        plugins: {
          zoom: getZoomConfig(chartNavigationMode, canvasId),
          tooltip: {
            callbacks: {
              label: (context: TooltipItem<'bar'>) => {
                const left = conversionFunction(context.parsed.x)
                const right = conversionFunction(context.parsed.x + binWidth)
                return `${labelForBarHover} ${left} and ${right}`
              },
              title: (context: TooltipItem<'bar'>[]) => {
                const yValue = context[0].formattedValue || ''
                return yValue
              },
            },
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 1000,
          easing: 'easeInOutCirc',
        },
        scales: {
          x: {
            type: 'linear',
            ticks: {
              callback: (value) => {
                return conversionFunction(value as number)
              },
            },
          },
          y: {
            ticks: {
              callback: (value) => {
                if (typeof value === 'string') {
                  return value
                } else {
                  return (value as number).toFixed(2)
                }
              },
            },
          },
        },
      },
    })
  }

  useEffect(() => {
    resetHistogram()
  }, [histogram])

  const resetZoom = () => {
    chartRef.current.options.plugins.zoom = getZoomConfig(chartNavigationMode, canvasId)
    chartRef.current.update('none')
  }

  useEffect(() => {
    resetZoom()
  }, [chartNavigationMode])

  useEffect(() => {
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
      }
    }
  }, [])

  return (
    <div className='w-full'>
      <ChartCanvas canvasId={canvasId} />
      <ChartNavigationButtons
        handleNavigationButtonClick={setChartNavigationMode}
        chartNavigationMode={chartNavigationMode}
        handleResetHistogram={resetHistogram}
      />
    </div>
  )
}
