import { Help } from './help'
import { NavigationWrapper } from '../common/app-navigation/navigation-wrapper'
import { useEffect } from 'react'
import { AMPLITUDE_EVENTS } from '../../analytics/amplitude/amplitude'

export const HelpView = () => {
  useEffect(() => {
    AMPLITUDE_EVENTS.viewHelp()
  }, [])

  return (
    <NavigationWrapper>
      <Help />
    </NavigationWrapper>
  )
}
