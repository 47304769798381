import { getEnvironmentName, isDevelopment, isProduction, isTest } from './environment.utils'

export type EnvironmentConfig = {
  shouldCallMockedBackend: boolean
  auth0Domain: string
  auth0ClientId: string
  featureFlags: FeatureFlags
  shouldRequireLogin: boolean
  fullstoryOrganizationId: string
  livesessionTrackId: string
  amplitudeApiKey: string
  backendHost: string
  shouldLogDebug: boolean
  sentryDataSourceName: string
}

export interface FeatureFlags {
  isTableTearDownEnabled: boolean
  isBasicStatsEnabled: boolean
}

export const getFeatureFlagsFromString = (defaultFlagValues: FeatureFlags, featureFlagsConfig: string) => {
  let currentEnvironmentFeatureFlags: FeatureFlags
  try {
    currentEnvironmentFeatureFlags = JSON.parse(featureFlagsConfig)
  } catch (e) {
    console.log('There has been a problem with parsing the feature flag config')
  }
  if (currentEnvironmentFeatureFlags) {
    const result = {}
    for (const key of Object.keys(defaultFlagValues)) {
      if (currentEnvironmentFeatureFlags[key] === undefined) {
        result[key] = defaultFlagValues[key]
      } else {
        result[key] = currentEnvironmentFeatureFlags[key]
      }
    }
    return result as FeatureFlags
  }
  return defaultFlagValues
}

const getFeatureFlagsForEnvironment = (): FeatureFlags => {
  let defaultFlagValues: FeatureFlags
  if (isDevelopment()) {
    defaultFlagValues = developmentDefaultFeatureFlagValues
  } else if (isProduction()) {
    defaultFlagValues = productionDefaultFeatureFlagValues
  } else if (isTest()) {
    defaultFlagValues = testDefaultFeatureFlagValues
  } else {
    throw Error(`There is no config for environment: ${getEnvironmentName()}`)
  }
  return getFeatureFlagsFromString(
    defaultFlagValues,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    import.meta.env.VITE_FEATURE_FLAGS_CONFIG
  )
}

const productionDefaultFeatureFlagValues: FeatureFlags = {
  isTableTearDownEnabled: false,
  isBasicStatsEnabled: false,
}

const developmentDefaultFeatureFlagValues: FeatureFlags = {
  isTableTearDownEnabled: true,
  isBasicStatsEnabled: true,
}
const testDefaultFeatureFlagValues: FeatureFlags = {
  isTableTearDownEnabled: true,
  isBasicStatsEnabled: true,
}

const getProductionConfig: () => EnvironmentConfig = () => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  shouldCallMockedBackend: import.meta.env.VITE_SHOULD_CALL_MOCKED_BACKEND === 'true',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  auth0ClientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  shouldRequireLogin: import.meta.env.VITE_SHOULD_REQUIRE_LOGIN !== 'false',
  featureFlags: getFeatureFlagsForEnvironment(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fullstoryOrganizationId: import.meta.env.VITE_FULLSTORY_ORGANIZATION_ID,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  livesessionTrackId: import.meta.env.VITE_LIVESESSION_TRACK_ID,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  amplitudeApiKey: import.meta.env.VITE_AMPLITUDE_API_KEY,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  backendHost: import.meta.env.VITE_USE_BACKEND_HOST,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  shouldLogDebug: import.meta.env.VITE_SHOULD_LOG_DEBUG !== 'false',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  sentryDataSourceName: import.meta.env.VITE_SENTRY_DATA_SOURCE_NAME,
})
const getDevelopmentConfig: () => EnvironmentConfig = () => ({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  shouldCallMockedBackend: import.meta.env.VITE_SHOULD_CALL_MOCKED_BACKEND === 'true',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  auth0Domain: import.meta.env.VITE_AUTH0_DOMAIN,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  auth0ClientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  shouldRequireLogin: import.meta.env.VITE_SHOULD_REQUIRE_LOGIN === 'true',
  featureFlags: getFeatureFlagsForEnvironment(),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  fullstoryOrganizationId: import.meta.env.VITE_FULLSTORY_ORGANIZATION_ID,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  livesessionTrackId: import.meta.env.VITE_LIVESESSION_TRACK_ID,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  amplitudeApiKey: import.meta.env.VITE_AMPLITUDE_API_KEY,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  backendHost: import.meta.env.VITE_USE_BACKEND_HOST,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  shouldLogDebug: import.meta.env.VITE_SHOULD_LOG_DEBUG === 'true',
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  sentryDataSourceName: import.meta.env.VITE_SENTRY_DATA_SOURCE_NAME,
})
const getTestConfig: () => EnvironmentConfig = () => ({
  shouldCallMockedBackend: true,
  auth0Domain: null,
  auth0ClientId: null,
  shouldRequireLogin: false,
  featureFlags: getFeatureFlagsForEnvironment(),
  fullstoryOrganizationId: null,
  livesessionTrackId: null,
  amplitudeApiKey: null,
  backendHost: null,
  shouldLogDebug: true,
  sentryDataSourceName: null,
})

let config = null

export const getConfig = (): EnvironmentConfig => {
  if (!config) {
    if (isProduction()) {
      config = getProductionConfig()
    } else if (isDevelopment()) {
      config = getDevelopmentConfig()
    } else if (isTest()) {
      config = getTestConfig()
    } else {
      throw Error(`There is no config for environment: ${getEnvironmentName()}`)
    }
  }
  return config
}
