import { Int64, tableFromArrays, Timestamp, TimeUnit, vectorFromArray } from 'apache-arrow'
import { generateHourlyWindowLastYearInMs, mockWindowedHistogramGrowingSeries } from './new-mock-helpers'

/**
 * I'm mocking the data in Spark, assuming there has this schema
 * case class ConsumptionPerHourSummary(
 *     hour_window: Timestamp,
 *     source_ip_address:String,
 *     user_agent:String
 *     bytes_out_hist: WindowedHistogram[Long])
 */

const generateMockData = () => {
  const times = generateHourlyWindowLastYearInMs()

  const rows_out_hist = mockWindowedHistogramGrowingSeries(times.length, 10, 10000, 1, 100)

  const bytes_out_hist = mockWindowedHistogramGrowingSeries(times.length, 10, 10000, 10_000, 100_000_000)
  const files_out_hist = mockWindowedHistogramGrowingSeries(times.length, 10, 10000, 1, 100)

  return {
    hour_window: vectorFromArray(times, new Timestamp(TimeUnit.MILLISECOND, 'Etc/UTC')),
    bytes_out_hist,
    rows_out_hist,
    number_of_files: vectorFromArray(
      times.map((t) => BigInt(Math.floor(Math.random() * 100))),
      new Int64()
    ),
  }
}

const mockData = generateMockData()

export const mockConsumptionHourly = tableFromArrays(mockData)
