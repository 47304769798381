import { BigScreenSideBar } from './side-bar/big-screen-side-bar'
import { TopNavBar } from './top-nav-bar'
import { ReactNode, useState } from 'react'
import { SmallScreenSideBar } from './side-bar/small-screen-side-bar'

export const NavigationWrapper = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const handleOnButtonClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <>
      <TopNavBar handleOnButtonClick={handleOnButtonClick} />
      <div className='flex w-full flex-row'>
        <BigScreenSideBar />
        {isOpen && <SmallScreenSideBar />}
        {children}
      </div>
    </>
  )
}
