import zoomPlugin from 'chartjs-plugin-zoom'
import { Chart } from 'chart.js'

Chart.register(zoomPlugin)

let isInitialized: boolean = false

export const initializeChartjsIfNotInitialized = () => {
  if (!isInitialized) {
    Chart.register(zoomPlugin)
    isInitialized = true
  }
}
