import { Contact } from './contact'
import { NavigationWrapper } from '../common/app-navigation/navigation-wrapper'
import { useEffect } from 'react'
import { AMPLITUDE_EVENTS } from '../../analytics/amplitude/amplitude'

export const ContactView = () => {
  useEffect(() => {
    AMPLITUDE_EVENTS.viewContact()
  }, [])

  return (
    <NavigationWrapper>
      <Contact />
    </NavigationWrapper>
  )
}
