import { H2PageTitle } from '../data-explorer/atoms'
import { GlobalIngestionOverviewTimelineConnector } from './global-ingestion-overview-timeline-connector'
import { GlobalFilesOverviewTimelineConnector } from './global-files-overview-timeline-connector'

export const Overview = () => {
  return (
    <main className='flex grow flex-col px-10 py-8'>
      <H2PageTitle title={'Overview'} />
      <div className='mt-8 flex w-full flex-col gap-y-20'>
        <div className='flex w-full flex-col gap-y-4'>
          <h3 className='text-center text-3xl font-bold text-gray-600'>Tables Evolution</h3>
          <GlobalFilesOverviewTimelineConnector />
        </div>
        <div className='flex w-full flex-col gap-y-4'>
          <h3 className='text-center text-3xl font-bold text-gray-600'>Ingestion Overview</h3>
          <GlobalIngestionOverviewTimelineConnector />
        </div>
      </div>
    </main>
  )
}
