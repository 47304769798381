import { Overview } from './overview'
import { NavigationWrapper } from '../common/app-navigation/navigation-wrapper'

export const OverviewView = () => {
  return (
    <NavigationWrapper>
      <Overview />
    </NavigationWrapper>
  )
}
