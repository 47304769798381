import { EXTERNAL_LINKS } from '../../constants/external-links'

export const Contact = () => {
  return (
    <div className='flex h-screen grow flex-row items-center bg-gray-50 p-2 text-center text-gray-600 md:p-8'>
      <div className='w-0 xl:w-1/6 2xl:w-1/4 4xl:w-1/3'></div>
      <div className='w-full rounded-3xl bg-white p-4 shadow md:px-12 md:py-8 xl:w-2/3 2xl:w-1/2 4xl:w-1/3'>
        <div className='flex flex-col items-start justify-between gap-y-8 md:flex-row md:items-end md:gap-y-0'>
          <div className='flex flex-col items-start text-left'>
            <h1 className='mt-6 text-4xl font-medium'>CONTACT</h1>
            <h2 className='mt-6 text-xl'>You can follow us on social media:</h2>
            <div className='mt-2 flex flex-row gap-x-8'>
              <a target=':blank' href={EXTERNAL_LINKS.X_TWITTER}>
                <div className='flex cursor-pointer flex-row gap-x-1 hover:underline'>
                  <span className='text-2xl font-medium text-gray-700 duration-300 hover:text-black'>Twitter</span>
                  <img src={'/qbeast-icons/contact/x-twitter.svg'} width={24} height={24} />
                </div>
              </a>
              <a target=':blank' href={EXTERNAL_LINKS.LINKED_IN}>
                <div className='flex cursor-pointer flex-row gap-x-2 hover:underline'>
                  <span className='text-2xl font-medium text-gray-700 duration-300 hover:text-black hover:text-black'>
                    Linkedin
                  </span>
                  <img src={'/qbeast-icons/contact/linkedin.svg'} className='mt-0.5' width={20} height={20} />
                </div>
              </a>
            </div>
            <h2 className='mt-6 text-xl'>Our website:</h2>
            <a className='mt-2' target=':blank' href={EXTERNAL_LINKS.QBEAST_IO}>
              <div className='flex cursor-pointer flex-row gap-x-3 hover:underline'>
                <span className='text-2xl font-medium text-gray-700 duration-300 hover:text-black hover:text-black'>
                  Qbeast
                </span>
                <img src={'/logos/qbeast-logo-face.svg'} width={24} height={24} />
              </div>
            </a>
            <h2 className='mt-6 text-xl'>Our email:</h2>
            <a
              className='text-2xl font-medium text-gray-700 duration-300 hover:text-black'
              href='mailto: support@qbeast.io'
            >
              support@qbeast.io
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
