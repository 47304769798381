import { SideBarContent } from './side-bar-content'

export const BigScreenSideBar = () => {
  return (
    <>
      <nav className='sticky top-0 hidden h-screen w-44 flex-col justify-between bg-dark pt-4 xl:flex'>
        <SideBarContent />
      </nav>
    </>
  )
}
