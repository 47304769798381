import { Table } from 'apache-arrow'

export const convertArrowTableToPlainObject = (table: Table): object[] => {
  return table.toArray().map((row) => convertRowToPlainObject(row))
}

const convertRowToPlainObject = (row: Table): object => {
  const plainObject = {}
  for (const key in row) {
    if (row[key] instanceof Table) {
      plainObject[key] = convertArrowTableToPlainObject(row[key])
    } else if (typeof row[key] === 'object' && row[key] !== null) {
      plainObject[key] = convertRowToPlainObject(row[key])
    } else {
      plainObject[key] = row[key]
    }
  }
  return plainObject
}
