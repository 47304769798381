import { useSelector } from 'react-redux'
import {
  selectAccountData,
  selectHasUsedEmailToLogIn,
  selectHasUsedGoogleToLogIn,
} from '../../state/slices/account-slice'
import LogoutButton from './logout-button'
import { useEffect } from 'react'
import { AMPLITUDE_EVENTS } from '../../analytics/amplitude/amplitude'
import { useAuth0 } from '@auth0/auth0-react'

export const Account = () => {
  const { isAuthenticated } = useAuth0()
  const { email, name, lastName, picture } = useSelector(selectAccountData)
  const hasUsedGoogleToLogIn = useSelector(selectHasUsedGoogleToLogIn)
  const hasUsedEmailToLogIn = useSelector(selectHasUsedEmailToLogIn)

  useEffect(() => {
    AMPLITUDE_EVENTS.viewAccount()
  }, [])

  return (
    <div className='flex h-screen grow items-center bg-gray-50 p-2 text-center text-gray-700 md:p-8'>
      <div className='w-0 xl:w-1/6 2xl:w-1/4 4xl:w-1/3'></div>
      <div className='w-full rounded-3xl bg-white p-4 shadow md:px-12 md:py-8 xl:w-2/3 2xl:w-1/2 4xl:w-1/3'>
        <div className='flex flex-col items-start justify-between gap-y-8 md:flex-row md:items-end md:gap-y-0'>
          <div className='flex flex-col items-start text-left font-medium'>
            {picture && <img src={picture} className='h-32 w-32 rounded-full' />}
            {name && <h1 className='mt-6 text-4xl'>Hello {name}</h1>}
            {hasUsedGoogleToLogIn && <h2 className='mt-6 text-2xl'>You have logged in to U.S.E. with google</h2>}
            {hasUsedEmailToLogIn && <h2 className='mt-6 text-2xl'>You have logged in to U.S.E. with email</h2>}
            <h2 className='mt-6 text-2xl'>Your login details are:</h2>
            {email && <h2 className='mt-2'>email: {email}</h2>}
            {lastName && <h2 className='mt-2'>last name: {lastName}</h2>}
          </div>
          {isAuthenticated && <LogoutButton />}
        </div>
      </div>
    </div>
  )
}
