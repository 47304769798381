import { ChartNavigationMode } from '../types'

interface ChartNavigationButtonsProps {
  handleNavigationButtonClick: (chartNavigationMode: ChartNavigationMode) => void
  chartNavigationMode: ChartNavigationMode
  handleResetHistogram: () => void
}

export const ChartNavigationButtons = ({
  chartNavigationMode,
  handleNavigationButtonClick,
  handleResetHistogram,
}: ChartNavigationButtonsProps) => {
  return (
    <div className='relative mt-4 flex flex-row justify-center gap-x-4'>
      <button
        className='flex flex-row items-center gap-x-2 rounded-md bg-gray-200 px-4 py-2 text-gray-400 disabled:bg-primary disabled:text-gray-100'
        onClick={() => {
          handleNavigationButtonClick(ChartNavigationMode.PAN)
        }}
        disabled={chartNavigationMode === ChartNavigationMode.PAN}
      >
        <span>Panning</span>
        <img src='/qbeast-icons/charts/pan.svg' height='18' width='18' />
      </button>
      <button
        className='flex flex-row items-center gap-x-2 rounded-md bg-gray-200 px-4 py-2 text-gray-400 disabled:bg-primary disabled:text-gray-100'
        onClick={() => {
          handleNavigationButtonClick(ChartNavigationMode.SELECTION)
        }}
        disabled={chartNavigationMode === ChartNavigationMode.SELECTION}
      >
        <span>Selection</span>
        <img className='invert' src='/qbeast-icons/charts/selection.svg' height='22' width='22' />
      </button>
      <button
        className='flex flex-row items-center gap-x-2 rounded-md bg-gray-200 px-4 py-2 text-gray-400 disabled:bg-primary disabled:text-gray-100'
        onClick={() => {
          handleNavigationButtonClick(ChartNavigationMode.ZOOM)
        }}
        disabled={chartNavigationMode === ChartNavigationMode.ZOOM}
      >
        <span>Zoom</span>
        <img src='/qbeast-icons/charts/magnifying-glass.svg' height='22' width='22' />
      </button>
      <button
        className='absolute right-0 flex flex-row items-center gap-x-2 rounded-md bg-primary px-4 py-2 text-gray-100'
        onClick={() => {
          handleResetHistogram()
        }}
      >
        <span>reset</span>
        <img src='/qbeast-icons/charts/reset.svg' height='18' width='18' />
      </button>
    </div>
  )
}
