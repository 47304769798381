import { SideBarContent } from './side-bar-content'

export const SmallScreenSideBar = () => {
  return (
    <>
      <nav className='fixed left-0 top-0 z-10 flex h-screen w-44 flex-col justify-between bg-dark pt-4 xl:hidden'>
        <SideBarContent />
      </nav>
    </>
  )
}
