import { ConsumptionOverviewTimelineConnector } from './consumption-overview-timeline-connector'
import { IngestionOverviewTimelineConnector } from './ingestion-overview-timeline-connector'
import { H2PageTitle } from '../../../atoms'
import { TableInsightsOverviewTimelineConnector } from './table-insights-overview-timeline-connector'
import { useEffect } from 'react'
import { useFetchConsumptionInsights } from '../consumption-insights/use-fetch-consumption-insights'
import { useFetchConsumptionTimeSeries } from '../consumption-insights/use-fetch-consumption-time-series'
import { useSelector } from 'react-redux'
import {
  selectAreConsumptionInsightsFetched,
  selectIsConsumptionInsightsTimelineCalculated,
  selectShouldFetchConsumptionTableWithName,
} from '../../../../../state/slices/consumption-insights-slice'
import { selectAccountAccessToken } from '../../../../../state/slices/account-slice'
import {
  selectChosenCatalogId,
  selectChosenConsumptionInsightsArrowTableName,
  selectChosenIngestionInsightsArrowTableName,
  selectChosenNamespaceId,
  selectChosenTableId,
  selectChosenTableInsightsArrowTableName,
  selectHasConsumptionInsights,
  selectHasIngestionInsights,
} from '../../../../../state/slices/catalogs-slice'
import { Loader } from '../../../../common/loader'
import { useFetchIngestionInsights } from '../ingestion-insights/use-fetch-ingestion-insights'
import { useFetchIngestionTimeSeries } from '../ingestion-insights/use-fetch-ingestion-time-series'
import {
  selectAreIngestionInsightsFetched,
  selectIsIngestionInsightsTimelineCalculated,
  selectShouldFetchIngestionTableWithName,
} from '../../../../../state/slices/ingestion-insights-slice'
import { useFetchTableInsights } from '../table-insights/use-fetch-table-insights'
import {
  selectAreTableInsightsFetched,
  selectIsTableInsightsTimelineCalculated,
  selectShouldFetchTableWithName,
} from '../../../../../state/slices/table-insights-slice'
import { useFetchTableTimeSeries } from '../table-insights/table-insights-timeline/use-fetch-table-time-series'

export const TableOverview = () => {
  const accessToken = useSelector(selectAccountAccessToken)

  const hasConsumptionInsights = useSelector(selectHasConsumptionInsights)
  const hasIngestionInsights = useSelector(selectHasIngestionInsights)
  const fetchTableInsights = useFetchTableInsights()
  const fetchTableTimeSeries = useFetchTableTimeSeries()
  const fetchConsumptionInsights = useFetchConsumptionInsights()
  const fetchConsumptionTimeSeries = useFetchConsumptionTimeSeries()
  const fetchIngestionInsights = useFetchIngestionInsights()
  const fetchIngestionTimeSeries = useFetchIngestionTimeSeries()

  const tableInsightsArrowTableName = useSelector(selectChosenTableInsightsArrowTableName)
  const consumptionInsightsArrowTableName = useSelector(selectChosenConsumptionInsightsArrowTableName)
  const ingestionInsightsArrowTableName = useSelector(selectChosenIngestionInsightsArrowTableName)

  const areConsumptionInsightsFetched: (arrowTableName: string) => boolean = useSelector(
    selectAreConsumptionInsightsFetched
  )
  const areTableInsightsFetched: (arrowTableName: string) => boolean = useSelector(selectAreTableInsightsFetched)
  const areIngestionInsightsFetched: (tableName: string) => boolean = useSelector(selectAreIngestionInsightsFetched)

  const isTableInsightsTimelineCalculated: boolean = useSelector(selectIsTableInsightsTimelineCalculated)
  const isConsumptionTimelineCalculated: boolean = useSelector(selectIsConsumptionInsightsTimelineCalculated)
  const isIngestionTimelineCalculated: boolean = useSelector(selectIsIngestionInsightsTimelineCalculated)

  const shouldFetchTableWithName: (tableName: string) => boolean = useSelector(selectShouldFetchTableWithName)
  const shouldFetchConsumptionTableWithName: (tableName: string) => boolean = useSelector(
    selectShouldFetchConsumptionTableWithName
  )
  const shouldFetchIngestionTableWithName: (arrowTableName: string) => boolean = useSelector(
    selectShouldFetchIngestionTableWithName
  )

  const catalogId = useSelector(selectChosenCatalogId)
  const namespaceId = useSelector(selectChosenNamespaceId)
  const tableId = useSelector(selectChosenTableId)

  useEffect(() => {
    if (catalogId && namespaceId && tableId && shouldFetchTableWithName(tableInsightsArrowTableName)) {
      fetchTableInsights(accessToken).then()
    }
  }, [tableInsightsArrowTableName])

  useEffect(() => {
    fetchTableTimeSeries().then()
  }, [tableInsightsArrowTableName, areTableInsightsFetched(tableInsightsArrowTableName)])

  useEffect(() => {
    if (
      catalogId &&
      namespaceId &&
      tableId &&
      hasConsumptionInsights &&
      shouldFetchConsumptionTableWithName(consumptionInsightsArrowTableName) &&
      areTableInsightsFetched(tableInsightsArrowTableName)
    ) {
      fetchConsumptionInsights(accessToken).then()
    }
  }, [consumptionInsightsArrowTableName, areTableInsightsFetched(tableInsightsArrowTableName)])

  useEffect(() => {
    if (hasConsumptionInsights && areTableInsightsFetched(tableInsightsArrowTableName)) {
      fetchConsumptionTimeSeries().then()
    }
  }, [
    consumptionInsightsArrowTableName,
    areConsumptionInsightsFetched(consumptionInsightsArrowTableName),
    areTableInsightsFetched(tableInsightsArrowTableName),
  ])

  useEffect(() => {
    if (
      catalogId &&
      namespaceId &&
      tableId &&
      hasIngestionInsights &&
      shouldFetchIngestionTableWithName(ingestionInsightsArrowTableName)
    ) {
      fetchIngestionInsights(accessToken).then()
    }
  }, [ingestionInsightsArrowTableName, areConsumptionInsightsFetched(consumptionInsightsArrowTableName)])

  useEffect(() => {
    if (hasIngestionInsights && areIngestionInsightsFetched(ingestionInsightsArrowTableName)) {
      fetchIngestionTimeSeries().then()
    }
  }, [
    ingestionInsightsArrowTableName,
    areIngestionInsightsFetched(ingestionInsightsArrowTableName),
    areConsumptionInsightsFetched(consumptionInsightsArrowTableName),
  ])

  return (
    <div className='flex grow flex-col px-10 py-8'>
      <H2PageTitle title='Table Overview' />
      <div className='mt-8 flex w-full flex-col items-center gap-y-20'>
        {!areTableInsightsFetched(tableInsightsArrowTableName) || !isTableInsightsTimelineCalculated ? (
          <div className='h-60 w-full'>
            <Loader />
          </div>
        ) : (
          <div className='flex w-10/12 flex-col gap-y-4'>
            <h3 className='text-center text-3xl font-bold text-gray-600'>Table Insights Overview</h3>
            <TableInsightsOverviewTimelineConnector />
          </div>
        )}
        {hasConsumptionInsights ? (
          !areConsumptionInsightsFetched(consumptionInsightsArrowTableName) || !isConsumptionTimelineCalculated ? (
            <div className='h-60 w-full'>
              <Loader />
            </div>
          ) : (
            <div className='flex w-10/12 flex-col gap-y-4'>
              <h3 className='text-center text-3xl font-bold text-gray-600'>Consumption Overview</h3>
              <ConsumptionOverviewTimelineConnector />
            </div>
          )
        ) : (
          <div className='flex w-10/12 flex-col gap-y-4'>
            <h3 className='text-center text-3xl font-bold text-gray-600'>Consumption Overview</h3>
            <span className='text-l text-center  text-gray-600'> Not available for this table</span>
          </div>
        )}
        <div className='flex w-10/12 flex-col gap-y-4'>
          <h3 className='text-center text-3xl font-bold text-gray-600'>Ingestion Overview</h3>
          {hasIngestionInsights ? (
            !areIngestionInsightsFetched(ingestionInsightsArrowTableName) || !isIngestionTimelineCalculated ? (
              <div className='h-60 w-full'>
                <Loader />
              </div>
            ) : (
              <IngestionOverviewTimelineConnector />
            )
          ) : (
            <span className='text-l text-center  text-gray-600'> Not available for this table</span>
          )}
        </div>
      </div>
    </div>
  )
}
