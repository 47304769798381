import { TimeSeriesWindow } from '../../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { generateDenseSeries, packColumns } from '../../sql-utils'

export const globalIngestionTimeSeriesQuery = (timeWindow: TimeSeriesWindow): string => {
  const duckTableName = 'use_catalog_system_lake_tables_ingestions_hourly'
  const seriesSQL = generateDenseSeries(duckTableName, timeWindow)
  return `WITH
  ${seriesSQL},
  NumberOf AS (
  SELECT r.time_window,
      SUM(CASE WHEN total_input_bytes is null THEN 0 ELSE total_input_bytes END) as totalInputBytes,
      SUM(CASE WHEN total_input_files is null THEN 0 ELSE total_input_files END) as totalInputFiles,
      MAX(CASE WHEN number_of_ingested_tables is null THEN 0 ELSE number_of_ingested_tables END) as numberOfIngestedTable
    FROM Windowed w RIGHT JOIN RANGES r on (r.time_window = w.time_window)
    GROUP BY r.time_window
    ORDER BY r.time_window ASC
    )
    
     ${packColumns(['totalInputBytes', 'totalInputFiles', 'numberOfIngestedTable'], 'NumberOf')}
`
}
