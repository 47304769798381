import { Table } from 'apache-arrow'
import { createWasmDuckSession } from './duck-browser'

class DuckClient {
  createSession(): Promise<DuckSession> {
    return createWasmDuckSession()
  }

  async runWithSession<T>(f: (session: DuckSession) => Promise<T>): Promise<T> {
    const session = await this.createSession()
    try {
      return await f(session)
    } finally {
      await session.close()
    }
  }

  /**
   * Use this method is you are going to run only one query, otherwise,
   * reuse the connection using the method runWithSession.
   * @param query
   */
  async runOne<T>(query: string): Promise<Table> {
    const session = await this.createSession()
    try {
      return await session.runQuery(query)
    } finally {
      await session.close()
    }
  }
}

export interface DuckSession {
  runQuery(query: string): Promise<Table>

  checkTableExists(tableId: string): Promise<boolean>

  /**
   * Use this method when you try to insert an arrow table that was created by
   * duckdb.
   */
  insertDuckArrowTable(table: Table, tableName: string): Promise<void>

  /**
   * Use this method when you try to insert an arrow table that was *not*
   * created by duckdb.
   */
  insertExternalArrowTableByIPC(table: Table, tableName: string): Promise<void>

  close(): Promise<void>
}

export const duck: DuckClient = new DuckClient()
// debug export
window.use_debug = { duck, ...window.use_debug }
