import { selectChosenTableInsightsArrowTableName } from '../../../../../../state/slices/catalogs-slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  actions,
  selectAreTableInsightsFetched,
  selectTableInsightsTimeSeriesWindow,
} from '../../../../../../state/slices/table-insights-slice'
import { withExecutionTimeLogging } from '../../../../../../utils/logging-utils'
import { getFileTimeSeries } from '../../../../../../transport/data-transformers/table-insights/table-insights-data-transformer'

export const useFetchTableTimeSeries = () => {
  const dispatch = useDispatch()
  const filesInsightTableName = useSelector(selectChosenTableInsightsArrowTableName)
  const areTableInsightsFetched: (tableName: string) => boolean = useSelector(selectAreTableInsightsFetched)
  const timeSeriesWindow = useSelector(selectTableInsightsTimeSeriesWindow)

  return async () => {
    const forTimeSeriesWindow = timeSeriesWindow
    if (areTableInsightsFetched(filesInsightTableName)) {
      const fileTimeSeries = await withExecutionTimeLogging(
        async () => await getFileTimeSeries(forTimeSeriesWindow, filesInsightTableName),
        'Table Insights time series aggregation'
      )
      dispatch(actions.addTableInsightsTimeLine({ fileTimeSeries, forTimeSeriesWindow }))
    }
  }
}
