import { ReactNode } from 'react'

const ColumnTitle = ({ title, division }: { title: string; division: 2 | 3 | 4 | 5 | 6 }) => (
  <td className={`px-6 py-3 font-bold`} width={`${100 / division}%}`}>
    {title}
  </td>
)

export const ColumnTitle2 = ({ title }: { title: string }) => <ColumnTitle title={title} division={2} />
export const ColumnTitle3 = ({ title }: { title: string }) => <ColumnTitle title={title} division={3} />
export const ColumnTitle4 = ({ title }: { title: string }) => <ColumnTitle title={title} division={4} />
export const ColumnTitle6 = ({ title }: { title: string }) => <ColumnTitle title={title} division={6} />

const Cell = ({
  text,
  division,
  handleClick,
}: {
  text: string
  division: 2 | 3 | 4 | 5 | 6
  handleClick?: () => void
}) => {
  const classNames = `px-6 py-3 ${handleClick && 'cursor-pointer hover:font-bold underline'}`
  return (
    <td className={classNames} width={`${100 / division}%}`} onClick={handleClick}>
      {text}
    </td>
  )
}
export const Cell2 = ({ text, handleClick }: { text: string; handleClick?: () => void }) => (
  <Cell text={text} division={2} handleClick={handleClick} />
)
export const Cell3 = ({ text, handleClick }: { text: string; handleClick?: () => void }) => (
  <Cell text={text} division={3} handleClick={handleClick} />
)
export const Cell4 = ({ text, handleClick }: { text: string; handleClick?: () => void }) => (
  <Cell text={text} division={4} handleClick={handleClick} />
)
export const Cell6 = ({ text, handleClick }: { text: string; handleClick?: () => void }) => (
  <Cell text={text} division={6} handleClick={handleClick} />
)

export const TableWrapper = ({ children }: { children: ReactNode }) => (
  <div className='w-full overflow-x-auto rounded-xl bg-white shadow-xl'>{children}</div>
)

export const Table = ({ children }: { children: ReactNode }) => (
  <table className='w-full text-left text-lg text-gray-500'>{children}</table>
)

export const TableHeadRow = ({ children }: { children: ReactNode }) => (
  <thead className='bg-dark uppercase text-white'>
    <tr className='py-2'>{children}</tr>
  </thead>
)

export const TableBodyRow = ({ children }: { children: ReactNode }) => (
  <tr className='odd:bg-gray-50 even:bg-gray-100'>{children}</tr>
)
