import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app-store'
import { TimeSeries } from '../../transport/response-models'
import { TimeSeriesWindow } from '../../components/data-explorer/data-explorer-right-side/table-details/types'
import { ChartDataPoint } from '../../components/common/chart/types'
import { zipTimeSeriesOrGetEmptyIfFalsy } from '../../utils/array-utils'

// hour_window|total_bytes|total_rows|number_of_tables
export interface GlobalFilesInsightsState {
  isFetching: boolean
  isFetched: boolean
  areFileTimeSeriesBeingCalculated: boolean
  totalBytes: TimeSeries<number>
  totalRows: TimeSeries<number>
  numberOfTables: TimeSeries<number>
  timeSeriesWindow: TimeSeriesWindow
}

const initialState: GlobalFilesInsightsState = {
  isFetching: false,
  isFetched: false,
  areFileTimeSeriesBeingCalculated: false,
  totalBytes: { x: [], y: [], windowUnit: TimeSeriesWindow.HOUR },
  totalRows: { x: [], y: [], windowUnit: TimeSeriesWindow.HOUR },
  numberOfTables: { x: [], y: [], windowUnit: TimeSeriesWindow.HOUR },
  timeSeriesWindow: TimeSeriesWindow.WEEK,
}

export interface AddGlobalFilesInsightsTimelineStats {
  totalBytes: TimeSeries<number>
  totalRows: TimeSeries<number>
  numberOfTables: TimeSeries<number>
  forTimeSeriesWindow: TimeSeriesWindow
}

const globalFilesInsightsSlice = createSlice({
  name: 'globalFilesInsights',
  initialState,
  reducers: {
    startGlobalFetchingFilesInsights: (state, { payload }: PayloadAction<void>) => {
      state.isFetching = true
    },
    addGlobalFilesInsights: (state, { payload }: PayloadAction<void>) => {
      state.isFetching = false
      state.isFetched = true
    },
    startCalculatingGlobalFilesInsightsTimeLine: (state) => {
      state.areFileTimeSeriesBeingCalculated = true
    },
    addGlobalFilesInsightsTimeLine: (
      state,
      {
        payload: { totalBytes, totalRows, numberOfTables, forTimeSeriesWindow },
      }: PayloadAction<AddGlobalFilesInsightsTimelineStats>
    ) => {
      if (state.timeSeriesWindow === forTimeSeriesWindow) {
        state.totalBytes = totalBytes
        state.totalRows = totalRows
        state.numberOfTables = numberOfTables
      }
    },
    chooseTimeSeriesWindow: (state, { payload }: PayloadAction<TimeSeriesWindow>) => {
      state.timeSeriesWindow = payload
    },
  },
})

export const selectIsGlobalFilesInsightsFetched = (state: RootState): boolean => state.globalFilesInsights.isFetched

export const selectIsGlobalFilesInsightsFetching = (state: RootState): boolean => state.globalFilesInsights.isFetching

export const selectShouldFetchGlobalFiles = (state: RootState): boolean =>
  state.globalFilesInsights.isFetched === false && state.globalFilesInsights.isFetching === false
export const selectGlobalFilesInsightsTimeSeriesWindow = (state: RootState): TimeSeriesWindow =>
  state.globalFilesInsights.timeSeriesWindow

export const selectGlobalBytesTimeSeries = (state: RootState) => state.globalFilesInsights.totalBytes
export const selectNumberOfRowsTimeSeries = (state: RootState) => state.globalFilesInsights.totalRows

export const selectGlobalFilesInsightsTotalBytes: (state: RootState) => ChartDataPoint[] = createSelector(
  selectGlobalBytesTimeSeries,
  (totalBytes: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(totalBytes)
)
export const selectGlobalFilesInsightsTotalRows: (state: RootState) => ChartDataPoint[] = createSelector(
  selectNumberOfRowsTimeSeries,
  (totalRows: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(totalRows)
)

export const selectIsGlobalFilesInsightsTimelineCalculated = (state: RootState): boolean => {
  return state.globalFilesInsights.isFetched && !state.globalFilesInsights.isFetching
}

export const selectNumberOfTablesTimeSeries = (state: RootState) => state.globalFilesInsights.numberOfTables

export const selectGlobalFilesInsightsTotalTables: (state: RootState) => ChartDataPoint[] = createSelector(
  selectNumberOfTablesTimeSeries,
  (numberOfTables: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(numberOfTables)
)
export const actions = globalFilesInsightsSlice.actions

export default globalFilesInsightsSlice.reducer
