import { ChartNavigationMode } from './types'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'

export const useAddEventsForChartZoom = (): {
  chartNavigationMode: ChartNavigationMode
  setChartNavigationMode: Dispatch<SetStateAction<ChartNavigationMode>>
} => {
  const [chartNavigationMode, setChartNavigationMode] = useState<ChartNavigationMode>(ChartNavigationMode.PAN)
  const [previousChartNavigationMode, setPreviousChartNavigationMode] = useState<ChartNavigationMode>(
    ChartNavigationMode.PAN
  )
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.metaKey || e.ctrlKey) {
        setPreviousChartNavigationMode(chartNavigationMode)
        setChartNavigationMode(ChartNavigationMode.ZOOM)
      }
    }
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Meta' || e.key === 'Control') {
        setChartNavigationMode(previousChartNavigationMode)
      }
    }
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [chartNavigationMode, previousChartNavigationMode])

  return {
    chartNavigationMode,
    setChartNavigationMode,
  }
}
