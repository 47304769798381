import { DataExplorerSideBar } from './side-bar/data-explorer-side-bar'
import { Loader } from '../common/loader'
import { useSelector } from 'react-redux'
import { selectIsFetchingCatalogs } from '../../state/slices/catalogs-slice'
import { useEffect } from 'react'
import { useFetchCatalogs } from './use-fetch-catalogs'
import { RightSide } from './data-explorer-right-side/right-side'
import { StateToRouteSynchronizer } from './route-and-state-synchronizers/state-to-route-synchronizer'
import { RouteToStateSynchronizer } from './route-and-state-synchronizers/route-to-state-synchronizer'
import { selectAccountAccessToken } from '../../state/slices/account-slice'
import { getConfig } from '../../config/environment-config'
import { AMPLITUDE_EVENTS } from '../../analytics/amplitude/amplitude'

export const DataExplorer = () => {
  const isFetching = useSelector(selectIsFetchingCatalogs)
  const fetchCatalogs = useFetchCatalogs()
  const accessToken = useSelector(selectAccountAccessToken)

  useEffect(() => {
    fetchCatalogs(accessToken).then(() => {})
  }, [accessToken])

  useEffect(() => {
    AMPLITUDE_EVENTS.viewDataExplorer()
  }, [])

  return (
    <div className='flex h-full grow'>
      <RouteToStateSynchronizer />
      <StateToRouteSynchronizer />
      <div className='sticky top-0 flex h-screen w-64 flex-col gap-y-8 bg-primary p-2 pt-8 '>
        <h1 className='pl-8 text-xl font-bold text-white'>Data Explorer</h1>
        <DataExplorerSideBar />
      </div>
      {isFetching ? (
        <div className='flex h-screen flex-grow'>
          <Loader />
        </div>
      ) : (
        <RightSide />
      )}
    </div>
  )
}
