import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../app-store'
import { Stats, TimeSeries, Timestamp } from '../../transport/response-models'
import { Period, TimeSeriesWindow } from '../../components/data-explorer/data-explorer-right-side/table-details/types'
import { FileTimeSeries } from '../../transport/data-transformers/table-insights/table-insights-data-transformer'
import { ChartDataPoint } from '../../components/common/chart/types'
import { zipTimeSeriesOrGetEmptyIfFalsy } from '../../utils/array-utils'

export interface TableInsightsState {
  namesOfFetchedTables: string[]
  namesOfTablesBeingFetched: string[]
  areRowsStatsBeingCalculated: boolean
  areFileTimeSeriesBeingCalculated: boolean
  sizeStats: Stats<number>
  numberOfRowsStats: Stats<number>
  fileTimeSeries: FileTimeSeries
  period: Period
  timeSeriesWindow: TimeSeriesWindow
}

const initialState: TableInsightsState = {
  namesOfFetchedTables: [],
  namesOfTablesBeingFetched: [],
  areRowsStatsBeingCalculated: false,
  areFileTimeSeriesBeingCalculated: false,
  sizeStats: null,
  numberOfRowsStats: null,
  fileTimeSeries: null,
  period: Period.LAST_DAY,
  timeSeriesWindow: TimeSeriesWindow.WEEK,
}

export interface AddTableInsightsSizeStats {
  sizeStats: Stats<number>
  forPeriod: Period
}

export interface AddTableInsightsNumberOfRowsStats {
  numberOfRowsStats: Stats<number>
  forPeriod: Period
}

export interface AddTableInsightsTimelineStats {
  fileTimeSeries: FileTimeSeries
  forTimeSeriesWindow: TimeSeriesWindow
}

const tableInsightsSlice = createSlice({
  name: 'tableInsights',
  initialState,
  reducers: {
    startFetchingTableInsights: (state, { payload }: PayloadAction<string>) => {
      state.namesOfTablesBeingFetched.push(payload)
    },
    addTableInsights: (state, { payload }: PayloadAction<string>) => {
      const nameOfFetchedTable = payload
      state.namesOfFetchedTables.push(nameOfFetchedTable)
      state.namesOfTablesBeingFetched = state.namesOfTablesBeingFetched.filter((name) => name !== nameOfFetchedTable)
    },
    startCalculatingTableInsightsSizeStats: (state) => {
      state.sizeStats = null
    },
    addTableInsightsSizeStats: (
      state,
      { payload: { sizeStats, forPeriod } }: PayloadAction<AddTableInsightsSizeStats>
    ) => {
      if (state.period === forPeriod) {
        state.sizeStats = sizeStats
      }
    },
    startCalculatingTableInsightsNumberOfRowsStats: (state) => {
      state.numberOfRowsStats = null
    },
    addTableInsightsNumberOfRowsStats: (
      state,
      { payload: { numberOfRowsStats, forPeriod } }: PayloadAction<AddTableInsightsNumberOfRowsStats>
    ) => {
      if (state.period === forPeriod) {
        state.numberOfRowsStats = numberOfRowsStats
      }
    },
    startCalculatingTableInsightsTimeLine: (state) => {
      state.fileTimeSeries = null
    },
    addTableInsightsTimeLine: (
      state,
      { payload: { fileTimeSeries, forTimeSeriesWindow } }: PayloadAction<AddTableInsightsTimelineStats>
    ) => {
      if (state.timeSeriesWindow === forTimeSeriesWindow) {
        state.fileTimeSeries = fileTimeSeries
        state.timeSeriesWindow = forTimeSeriesWindow
      }
    },
    choosePeriod: (state, { payload }: PayloadAction<Period>) => {
      state.period = payload
    },
    chooseTimeSeriesWindow: (state, { payload }: PayloadAction<TimeSeriesWindow>) => {
      state.timeSeriesWindow = payload
    },
  },
})

const selectNamesOfFetchedTables = (state: RootState): string[] => state.tableInsights.namesOfFetchedTables

export const selectAreTableInsightsFetched: (state: RootState) => (tableName: string) => boolean = createSelector(
  selectNamesOfFetchedTables,
  (namesOfFetchedTables) => {
    return (tableName: string) => {
      return namesOfFetchedTables.includes(tableName)
    }
  }
)

export const selectChosenPeriod = (state: RootState): Period => state.tableInsights.period

const selectNamesOfTablesBeingFetched = (state: RootState): string[] => state.tableInsights.namesOfTablesBeingFetched

export const selectShouldFetchTableWithName: (state: RootState) => (tableName: string) => boolean = createSelector(
  selectNamesOfTablesBeingFetched,
  selectNamesOfFetchedTables,
  (namesOfTablesBeingFetched, namesOfFetchedTables) => {
    return (tableName: string) => {
      return !namesOfTablesBeingFetched.includes(tableName) && !namesOfFetchedTables.includes(tableName)
    }
  }
)

export const selectSizeStats = (state: RootState): Stats<Timestamp> => state.tableInsights.sizeStats

export const selectNumberOfRowsStats = (state: RootState): Stats<Timestamp> => state.tableInsights.numberOfRowsStats
export const selectTimeSeries = (state: RootState): FileTimeSeries => state.tableInsights.fileTimeSeries
export const selectTableInsightsTimeSeriesWindow = (state: RootState): TimeSeriesWindow =>
  state.tableInsights.timeSeriesWindow

const selectNumberOfBytesTimeSeries = (state: RootState) =>
  state.tableInsights.fileTimeSeries && state.tableInsights.fileTimeSeries.numberOfBytes
const selectNumberOfRowsTimeSeries = (state: RootState) =>
  state.tableInsights.fileTimeSeries && state.tableInsights.fileTimeSeries.numberOfRows

const selectNumberOfFilesTimeSeries = (state: RootState) =>
  state.tableInsights.fileTimeSeries && state.tableInsights.fileTimeSeries.numberOfFiles
export const selectTableInsightsBytesReadData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectNumberOfBytesTimeSeries,
  (bytesRead: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(bytesRead)
)

export const selectTableInsightsRowsReadData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectNumberOfRowsTimeSeries,
  (rowsRead: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(rowsRead)
)

export const selectTableInsightsFilesReadData: (state: RootState) => ChartDataPoint[] = createSelector(
  selectNumberOfFilesTimeSeries,
  (filesRead: TimeSeries<number>) => zipTimeSeriesOrGetEmptyIfFalsy(filesRead)
)

export const selectIsTableInsightsTimelineCalculated = (state: RootState): boolean => {
  return state.tableInsights.fileTimeSeries !== null
}

export const actions = tableInsightsSlice.actions

export default tableInsightsSlice.reducer
