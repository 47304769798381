import { tableFromArrays, Timestamp, TimeUnit, vectorFromArray } from 'apache-arrow'
import { generateHourlyWindowLastYearInMs, mockWindowedHistogramGrowingSeries } from './new-mock-helpers'
import { FilesInsights } from '../spark-data-model'

const generateMockData = () => {
  const fullTimes = generateHourlyWindowLastYearInMs()
  const times = fullTimes.filter((value, i) => i === 0 || i === fullTimes.length - 1 || Math.random() < 0.3)

  const file_rows_hist = mockWindowedHistogramGrowingSeries(times.length, 10, 10_000, 0, 100)
  const file_bytes_hist = mockWindowedHistogramGrowingSeries(times.length, 100, 10_000, 100, 1_000_000_000)

  return {
    hour_window: vectorFromArray(times, new Timestamp(TimeUnit.MICROSECOND, 'Etc/UTC')),
    file_rows_hist,
    file_bytes_hist,
  }
}

const mockData = generateMockData()

export const mockFilesHourly: FilesInsights = tableFromArrays(mockData)
