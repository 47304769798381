import { selectChosenTableInsightsArrowTableName } from '../../../../../state/slices/catalogs-slice'
import { useDispatch, useSelector } from 'react-redux'
import { loadFilesForTable, WebClientResultWithoutData } from '../../../../../transport/spark-api'
import { actions as modalActions } from '../../../../../state/slices/modal-slice'
import * as Sentry from '@sentry/react'
import { actions } from '../../../../../state/slices/table-insights-slice'

export const useFetchTableInsights = () => {
  const dispatch = useDispatch()
  const filesInsightsTableName = useSelector(selectChosenTableInsightsArrowTableName)

  return async (accessToken: string) => {
    dispatch(actions.startFetchingTableInsights(filesInsightsTableName))
    const result: WebClientResultWithoutData = await loadFilesForTable(accessToken, filesInsightsTableName)
    if (result.hasFailed) {
      dispatch(
        modalActions.showModal({
          message: 'There has been an error when retrieving table insights. Please reload the page.',
        })
      )
      Sentry.captureException('There has been an error when retrieving table insights. ' + JSON.stringify(result))
    } else {
      dispatch(actions.addTableInsights(filesInsightsTableName))
    }
  }
}
