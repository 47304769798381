import { useDispatch, useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../state/slices/account-slice'
import {
  actions,
  selectGlobalFilesInsightsTimeSeriesWindow,
  selectGlobalFilesInsightsTotalBytes,
  selectGlobalFilesInsightsTotalRows,
  selectGlobalFilesInsightsTotalTables,
  selectIsGlobalFilesInsightsFetched,
  selectIsGlobalFilesInsightsTimelineCalculated,
} from '../../state/slices/global-files-insights-slice'
import { ChartDataPoint } from '../common/chart/types'
import { useEffect } from 'react'
import { Timeline } from '../common/chart/timeline/timeline'
import { CANVAS_IDS } from '../../constants/canvas-ids'
import { TimeSeriesType } from '../common/chart/timeline/timeline-types'
import { TimeSeriesWindow } from '../data-explorer/data-explorer-right-side/table-details/types'
import { useFetchGlobalFilesInsights } from '../data-explorer/data-explorer-right-side/global-overview/use-fetch-global-files-insights'
import { useFetchGlobalFilesTimeSeries } from '../data-explorer/data-explorer-right-side/global-overview/use-fetch-global-files-time-series'
import { Loader } from '../common/loader'
import { getConfig } from '../../config/environment-config'

export const GlobalFilesOverviewTimelineConnector = () => {
  const dispatch = useDispatch()
  const accessToken = useSelector(selectAccountAccessToken)

  const fetchGlobalTableInsights = useFetchGlobalFilesInsights()
  useEffect(() => {
    if (!accessToken) return
    fetchGlobalTableInsights(accessToken).then()
  }, [accessToken])

  const isGlobalTableInsightsFetched = useSelector(selectIsGlobalFilesInsightsFetched)

  const computeGlobalTableTimeSeries = useFetchGlobalFilesTimeSeries()

  const timeSeriesWindow = useSelector(selectGlobalFilesInsightsTimeSeriesWindow)

  const isTimelineCalculated = useSelector(selectIsGlobalFilesInsightsTimelineCalculated)

  useEffect(() => {
    computeGlobalTableTimeSeries().then()
  }, [accessToken, isGlobalTableInsightsFetched, timeSeriesWindow])
  const totalTables: ChartDataPoint[] = useSelector(selectGlobalFilesInsightsTotalTables)
  const numberOfRows: ChartDataPoint[] = useSelector(selectGlobalFilesInsightsTotalRows)
  const bytesIngested: ChartDataPoint[] = useSelector(selectGlobalFilesInsightsTotalBytes)

  if (totalTables.length <= 0)
    return (
      <>
        <h4 className='text-center text-2xl font-bold text-gray-600'>No data available</h4>
      </>
    )
  else if (!isTimelineCalculated) return <Loader></Loader>
  else
    return (
      <Timeline
        canvasId={CANVAS_IDS.GLOBAL_FILES_OVERVIEW_TIMELINE_ID}
        tableName='Table changes'
        timeSeriesData={{
          [TimeSeriesType.ROWS]: {
            data: numberOfRows,
            label: 'Number of Rows',
          },
          [TimeSeriesType.MAX_NUMBER_OF_TABLES]: {
            data: totalTables,
            label: 'Managed Tables',
          },
          [TimeSeriesType.BYTES]: {
            data: bytesIngested,
            label: 'Bytes Added',
          },
        }}
        onTimeSeriesWindowChange={(timeSeriesWindow: TimeSeriesWindow) => {
          dispatch(actions.chooseTimeSeriesWindow(timeSeriesWindow))
        }}
        currentTimeSeriesWindow={timeSeriesWindow}
        initialLeftTimeSeries={TimeSeriesType.MAX_NUMBER_OF_TABLES}
        initialRightTimeSeries={TimeSeriesType.BYTES}
        isOverviewMode={false}
      />
    )
}
