export const TableTearDown = () => {
  return (
    <div className='flex grow flex-row items-center justify-center'>
      <div className=''>
        <h2 className='text-center text-2xl'>Table Tear Down</h2>
        <h2 className='text-center text-2xl'>You had accessed a secret functionality.</h2>
        <h2 className='text-center text-2xl'>You can destroy all your data with a single click. Wanna try it?</h2>
      </div>
    </div>
  )
}
