import { useEffect } from 'react'
import { TableInsightsNumberOfRows } from './table-insights-number-of-rows'
import { TableInsightsTimeline } from './table-insights-timeline/table-insights-timeline'
import { ChosenTab, NotChosenTab } from '../../../../common/catalog-navigation/catalog-navigation-atoms'
import { TableInsightsSize } from './table-insights-size'
import { useDispatch, useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../../../../state/slices/account-slice'
import {
  chooseTableInsightsTab,
  selectChosenCatalogId,
  selectChosenNamespaceId,
  selectChosenTable,
  selectChosenTableId,
  selectChosenTableInsightsArrowTableName,
  selectChosenTableInsightsTab,
  TableInsightsTab,
} from '../../../../../state/slices/catalogs-slice'
import {
  selectAreTableInsightsFetched,
  selectShouldFetchTableWithName,
} from '../../../../../state/slices/table-insights-slice'
import { Loader } from '../../../../common/loader'
import { useFetchTableInsights } from './use-fetch-table-insights'
import { TableOptimizationEvents } from './table-insights-optimization-events'

export const TableInsights = () => {
  const chosenTab = useSelector(selectChosenTableInsightsTab)
  const dispatch = useDispatch()

  const isFetched: (arrowTableName: string) => boolean = useSelector(selectAreTableInsightsFetched)
  const shouldFetchTableWithName: (tableName: string) => boolean = useSelector(selectShouldFetchTableWithName)
  const accessToken = useSelector(selectAccountAccessToken)

  const catalogId = useSelector(selectChosenCatalogId)
  const namespaceId = useSelector(selectChosenNamespaceId)
  const tableId = useSelector(selectChosenTableId)
  const table = useSelector(selectChosenTable)
  const tableInsightsArrowTableName = useSelector(selectChosenTableInsightsArrowTableName)
  const fetchTableInsights = useFetchTableInsights()

  useEffect(() => {
    if (catalogId && namespaceId && tableId && shouldFetchTableWithName(tableInsightsArrowTableName)) {
      fetchTableInsights(accessToken).then()
    }
  }, [tableInsightsArrowTableName])

  const tabs = [
    [TableInsightsTab.TIMELINE, 'TIMELINE'],
    [TableInsightsTab.SIZE, 'SIZE'],
    [TableInsightsTab.NUMBER_OF_ROWS, 'NUMBER OF ROWS'],
    /*[TableInsightsTab.FILE_OVERLAP, 'FILE OVERLAP'],*/
  ]
  if (table.tableType === 'qbeast') tabs.push([TableInsightsTab.OPTIMIZATION_EVENTS, 'OPTIMIZATION EVENTS'])
  return (
    <>
      <ul className='sticky top-0 z-10 flex h-20 w-full flex-row items-center justify-around border-gray-200 bg-gray-100 shadow'>
        {tabs.map((tuple) => {
          const [tab, title] = tuple as [TableInsightsTab, string]
          if (tab === chosenTab) {
            return (
              <ChosenTab
                key={title}
                title={title}
                handleClick={() => {
                  dispatch(chooseTableInsightsTab(tab))
                }}
              />
            )
          } else {
            return (
              <NotChosenTab
                key={title}
                title={title as string}
                handleClick={() => {
                  dispatch(chooseTableInsightsTab(tab))
                }}
              />
            )
          }
        })}
      </ul>
      <div className='w-full'>
        {isFetched(tableInsightsArrowTableName) ? (
          <>
            {
              {
                [TableInsightsTab.TIMELINE]: <TableInsightsTimeline />,
                [TableInsightsTab.SIZE]: <TableInsightsSize />,
                [TableInsightsTab.NUMBER_OF_ROWS]: <TableInsightsNumberOfRows />,
                [TableInsightsTab.OPTIMIZATION_EVENTS]: <TableOptimizationEvents />,
              }[chosenTab]
            }
          </>
        ) : (
          <div className='mt-40 w-full'>
            <Loader />
          </div>
        )}
      </div>
    </>
  )
}
