import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { ProtectedRoutes } from './general/protected-routes'
import { Provider } from 'react-redux'
import { appStore } from './state/app-store'
import { Auth0Provider } from '@auth0/auth0-react'
import { getConfig } from './config/environment-config'
import SilentLogin from './components/authorization/silent-login'
import { ProtectedRoute } from './routing/protected-route'
import { useEffect } from 'react'
import { initializeAnalytics } from './analytics/analytics-initializer'
import { initializeChartjsIfNotInitialized } from './components/common/chart/chart-initializer'
import { Modal } from './components/modal/modal'

initializeChartjsIfNotInitialized()

export const App = () => {
  useEffect(() => {
    initializeAnalytics()
  }, [])

  return (
    <Auth0Provider
      domain={getConfig().auth0Domain}
      clientId={getConfig().auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: `https://${getConfig().auth0Domain}/api/v2/`,
        scope: 'read:current_user',
      }}
    >
      <Provider store={appStore}>
        <Modal />
        <SilentLogin />
        <BrowserRouter>
          <div className='font-ibmPlex'>
            <Routes>
              <Route
                element={
                  <ProtectedRoute>
                    <ProtectedRoutes />
                  </ProtectedRoute>
                }
                path='/*'
              />
            </Routes>
          </div>
        </BrowserRouter>
      </Provider>
    </Auth0Provider>
  )
}
