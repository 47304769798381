import { TimeSeriesWindow } from '../../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { generateDenseSeries, packColumns } from '../../sql-utils'

export const consumptionTimeSeries = (fileInsightsTableName: string, timeWindow: TimeSeriesWindow): string => {
  const duckTableName = fileInsightsTableName.replace(/\./g, '_')
  const seriesSQL = generateDenseSeries(duckTableName, timeWindow)

  // The row_hist_column does not exist yet
  //                 SUM(CASE WHEN rows_out_hist is null THEN 0 ELSE rows_out_hist.sum_value END) as rows_read
  return `
WITH
    ${seriesSQL},
    NumberOf AS (
                SELECT r.time_window,
                SUM(CASE WHEN number_of_files is null THEN 0 ELSE number_of_files END) as number_of_files_read,
                SUM(CASE WHEN bytes_out_hist is null THEN 0 ELSE bytes_out_hist.sum_value END) as bytes_read,
                FROM Windowed w RIGHT JOIN RANGES r on (r.time_window = w.time_window)
                GROUP BY r.time_window
                ORDER BY r.time_window ASC
    ) 
    ${packColumns(['number_of_files_read', 'bytes_read'], 'NumberOf')} 
`
}
