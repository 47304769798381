import { useEffect, useRef, useState } from 'react'
import { TimeSeriesType } from './timeline-types'
import { ArrowDown, DropdownItem } from './timeline-atoms'

const timeSeriesTypeToString = (timeSeriesType: TimeSeriesType) => {
  switch (timeSeriesType) {
    case TimeSeriesType.BYTES:
      return 'Bytes'
    case TimeSeriesType.ROWS:
      return 'Rows'
    case TimeSeriesType.FILES:
      return 'Files'
    case TimeSeriesType.CPU_CONSUMPTION:
      return 'Cpu Consumption'
    case TimeSeriesType.AVERAGE_DURATION:
      return 'Average Duration'
    case TimeSeriesType.MAX_INGESTION_DELAY:
      return 'Max Ingestion Delay'
    case TimeSeriesType.MAX_NUMBER_OF_MANAGED_INGESTIONS:
      return 'Managed Ingestions'
    case TimeSeriesType.MAX_NUMBER_OF_TABLES:
      return 'Number of Tables'
    default:
      console.error('Unknown time series type')
      throw new Error('Unknown time series type')
  }
}

interface TimelineComparisonDropdownsProps {
  leftTimeSeries: TimeSeriesType
  rightTimeSeries: TimeSeriesType
  possibleChoices: TimeSeriesType[]
  onTimeSeriesChoice: (arr: [TimeSeriesType, TimeSeriesType]) => void
}

export const TimelineComparisonDropdowns = (props: TimelineComparisonDropdownsProps) => {
  const [leftTimeSeries, setLeftTimeSeries] = useState<TimeSeriesType>(props.leftTimeSeries)
  const [rightTimeSeries, setRightTimeSeries] = useState<TimeSeriesType>(props.rightTimeSeries)
  const [isLeftGraphDropdownOpen, setIsLeftGraphDropdownOpen] = useState<boolean>(false)
  const [isRightGraphDropdownOpen, setIsRightGraphDropdownOpen] = useState<boolean>(false)
  const leftDropdownListRef = useRef<HTMLDivElement>(null)
  const leftDropdownButtonRef = useRef<HTMLButtonElement>(null)
  const rightDropdownListRef = useRef<HTMLDivElement>(null)
  const rightDropdownButtonRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    props.onTimeSeriesChoice([leftTimeSeries, rightTimeSeries])
  }, [leftTimeSeries, rightTimeSeries])
  const toggleLeftGraphDropdown = () => {
    if (isRightGraphDropdownOpen) {
      setIsRightGraphDropdownOpen(false)
    }
    setIsLeftGraphDropdownOpen(!isLeftGraphDropdownOpen)
  }
  const toggleRightGraphDropdown = () => {
    if (isLeftGraphDropdownOpen) {
      setIsLeftGraphDropdownOpen(false)
    }
    setIsRightGraphDropdownOpen(!isRightGraphDropdownOpen)
  }

  const handleClickOutside = (event: MouseEvent) => {
    const isClickOnLeftDropdownButtonOrList =
      leftDropdownListRef &&
      leftDropdownListRef.current &&
      !leftDropdownListRef.current.contains(event.target as Node) &&
      !leftDropdownButtonRef.current.contains(event.target as Node)
    if (isLeftGraphDropdownOpen && isClickOnLeftDropdownButtonOrList) {
      setIsLeftGraphDropdownOpen(false)
    }

    const isClickOnRightDropdownButtonOrList =
      rightDropdownListRef &&
      rightDropdownListRef.current &&
      !rightDropdownListRef.current.contains(event.target as Node) &&
      !rightDropdownButtonRef.current.contains(event.target as Node)
    if (isRightGraphDropdownOpen && isClickOnRightDropdownButtonOrList) {
      setIsRightGraphDropdownOpen(false)
    }
  }

  const onLeftChoiceClick = (timeSeriesType: TimeSeriesType) => {
    setLeftTimeSeries(timeSeriesType)
    setIsLeftGraphDropdownOpen(false)
  }

  const onRightChoiceClick = (timeSeriesType: TimeSeriesType) => {
    setRightTimeSeries(timeSeriesType)
    setIsRightGraphDropdownOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isLeftGraphDropdownOpen, isRightGraphDropdownOpen])

  const handleEscapeDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsLeftGraphDropdownOpen(false)
      setIsRightGraphDropdownOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeDown)
    return () => {
      document.removeEventListener('keydown', handleEscapeDown)
    }
  }, [])

  return (
    <div className='mt-4 flex flex-row items-center justify-center gap-x-2'>
      <div className='relative flex flex-col items-center gap-y-2'>
        <button
          ref={leftDropdownButtonRef}
          onClick={toggleLeftGraphDropdown}
          id='leftDropdownButton'
          className='flex flex-row items-center justify-between rounded-lg bg-accent px-5 py-2.5 text-center text-sm font-medium text-white duration-300 hover:shadow-lg'
        >
          {timeSeriesTypeToString(leftTimeSeries)}
          <ArrowDown />
        </button>

        {isLeftGraphDropdownOpen && (
          <div
            className='absolute top-12 z-10 divide-y divide-gray-100 rounded-lg bg-white shadow'
            ref={leftDropdownListRef}
          >
            <ul className='w-40 py-2 text-sm text-gray-700' aria-labelledby='leftDropdownButton'>
              {props.possibleChoices.map((timeSeriesType: TimeSeriesType) => (
                <DropdownItem
                  key={timeSeriesType}
                  isDisabled={rightTimeSeries === timeSeriesType}
                  handleClick={() => onLeftChoiceClick(timeSeriesType)}
                  text={timeSeriesTypeToString(timeSeriesType)}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
      <span className='text-gray-700'>vs</span>
      <div className='relative flex flex-col items-center gap-y-2'>
        <button
          ref={rightDropdownButtonRef}
          onClick={toggleRightGraphDropdown}
          id='rightDropdownButton'
          className='flex flex-row items-center justify-between rounded-lg bg-secondary px-5 py-2.5 text-center text-sm font-medium text-white duration-300 hover:shadow-lg'
        >
          {timeSeriesTypeToString(rightTimeSeries)}
          <ArrowDown />
        </button>

        {isRightGraphDropdownOpen && (
          <div
            className='absolute top-12 z-10 divide-y divide-gray-100 rounded-lg bg-white shadow'
            ref={rightDropdownListRef}
          >
            <ul className='w-40 py-2 text-sm text-gray-700' aria-labelledby='leftDropdownButton'>
              {props.possibleChoices.map((timeSeriesType: TimeSeriesType) => (
                <DropdownItem
                  key={timeSeriesType}
                  isDisabled={leftTimeSeries === timeSeriesType}
                  handleClick={() => onRightChoiceClick(timeSeriesType)}
                  text={timeSeriesTypeToString(timeSeriesType)}
                />
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  )
}
