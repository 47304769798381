import { ZoomOptions, ZoomPluginOptions } from 'chartjs-plugin-zoom/types/options'
import { BRAND_COLORS } from '../../../constants/brand-colors'
import { ChartNavigationMode } from './types'

export const getZoomConfig = (chartNavigationMode: ChartNavigationMode, canvasId: string): ZoomPluginOptions => {
  const config: ZoomPluginOptions = {}
  if (chartNavigationMode === ChartNavigationMode.ZOOM) {
    config.zoom = {
      wheel: {
        enabled: true,
      },
      pinch: {
        enabled: true,
      },
      mode: 'xy',
    }
  } else if (chartNavigationMode === ChartNavigationMode.PAN) {
    config.pan = {
      enabled: true,
      mode: 'xy',
      onPanStart: () => {
        const ctx = (document.getElementById(canvasId) as HTMLCanvasElement).getContext('2d')
        if (ctx.canvas) {
          ctx.canvas.style.cursor = 'grab'
        }
        return true
      },
      onPanComplete: () => {
        const ctx = (document.getElementById(canvasId) as HTMLCanvasElement).getContext('2d')
        if (ctx.canvas) {
          ctx.canvas.style.cursor = 'default'
        }
      },
    }
  } else if (chartNavigationMode === ChartNavigationMode.SELECTION) {
    config.zoom = {
      drag: {
        enabled: true,
        borderColor: BRAND_COLORS.PRIMARY,
        borderWidth: 1,
        backgroundColor: BRAND_COLORS.PRIMARY_LIGHT,
      },
    }
  }
  return config
}
