import { Period } from '../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { Dialect, getTimePeriodWherePredicate } from '../sql-utils'

export const windowedHistogramPerPeriod = (
  fileInsightsTableName: string,
  columnName: string,
  period: Period,
  dialect: Dialect
): string => {
  const duckName = fileInsightsTableName.replace(/\./g, '_')
  const timeWherePredicate = getTimePeriodWherePredicate(period, 'hour_window')
  const { LIST } = dialect
  return `
    WITH 
    source_table AS (
        SELECT * 
        FROM ${duckName}
        ${timeWherePredicate}
    ),
    global_stats AS ( 
        SELECT
            max(${columnName}.max_value) AS max_value, 
            min(${columnName}.min_value) AS min_value,
            (max_value - min_value)/40 AS delta,
            sum(${columnName}.count_value) AS count_value,
            sum(${columnName}.sum_value) AS sum_value
        FROM source_table
    ),
    normalized_bins AS (
        SELECT 
            i.index AS normalized_bin_index,
            i.index * delta + min_value AS normalized_bin_start,
            normalized_bin_start + delta AS normalized_bin_end
        FROM global_stats, 
            (SELECT unnest(range(CASE WHEN delta > 0 THEN 40 ELSE 1 END)) AS index) AS i
    ),
    local_bin AS (
        SELECT 
            ${columnName}.max_value AS local_max_value,
            ${columnName}.min_value AS local_min_value,
            (local_max_value - local_min_value)/40 AS local_delta,
            UNNEST(${columnName}.hist.bin_heights) AS local_bin_heights
        FROM source_table
    ),
    normalized_local_bins AS (
        SELECT 
            local_bin_heights.bin_index AS local_bin_index,
            local_bin_heights.bin_count AS local_bin_count,
            local_delta AS local_delta,
            local_bin_index * local_delta + local_min_value AS normalized_local_bin_start,
            normalized_local_bin_start + local_delta AS normalized_local_bin_end
        FROM local_bin
    ),
    bins AS (
        SELECT  
            normalized_bin_index,  
            LEAST(normalized_local_bin_end, normalized_bin_end) AS overlap_end,
            GREATEST(normalized_local_bin_start, normalized_bin_start) AS overlap_start,
            overlap_end - overlap_start AS overlap,
            (CASE WHEN local_delta > 0 THEN overlap / local_delta ELSE 1 END) * local_bin_count AS contribution
        FROM normalized_bins, normalized_local_bins
        WHERE overlap_end >= overlap_start
    ),
    bins_stats AS (
        SELECT 
            normalized_bin_index, 
            CAST(sum(CASE WHEN contribution is null THEN 0 ELSE contribution END) AS INT4) AS bin_count
        FROM bins
        GROUP BY normalized_bin_index
    ),
    histogram AS (
        SELECT 
            ${LIST}(STRUCT_PACK(bin_index := normalized_bin_index, bin_count := bin_count)) AS hist
        FROM bins_stats
    )
SELECT 
    hist, 
    max_value, 
    min_value, 
    sum_value / count_value AS avg_value, 
    sum_value, 
    count_value 
FROM global_stats, histogram
`
}
