import { useSelector } from 'react-redux'
import {
  selectAreAlreadyFetched,
  selectChosenCatalogId,
  selectChosenNamespaceId,
  selectChosenTableId,
  selectChosenTabs,
  selectHasLoadedFromUrl,
} from '../../../state/slices/catalogs-slice'
import { useEffect } from 'react'
import {
  buildDataExplorerCatalogNamespaceRoute,
  buildDataExplorerCatalogNamespaceTableRoute,
  buildDataExplorerCatalogRoute,
  ROUTES,
} from '../../../routing/routes'
import { useLocation, useNavigate } from 'react-router-dom'

export const StateToRouteSynchronizer = () => {
  const areCatalogsAlreadyFetched = useSelector(selectAreAlreadyFetched)
  const catalogId = useSelector(selectChosenCatalogId)
  const namespaceId = useSelector(selectChosenNamespaceId)
  const tableId = useSelector(selectChosenTableId)
  const [topTab, bottomTab] = useSelector(selectChosenTabs)
  const hasLoadedFromUrl = useSelector(selectHasLoadedFromUrl)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    let to: string
    if (areCatalogsAlreadyFetched && hasLoadedFromUrl) {
      if (!catalogId && !namespaceId && !tableId) {
        to = ROUTES.DATA_EXPLORER
      } else if (catalogId && !namespaceId && !tableId) {
        to = buildDataExplorerCatalogRoute(catalogId)
      } else if (catalogId && namespaceId && !tableId) {
        to = buildDataExplorerCatalogNamespaceRoute(catalogId, namespaceId)
      } else if (catalogId && namespaceId && tableId) {
        to = buildDataExplorerCatalogNamespaceTableRoute(catalogId, namespaceId, tableId, topTab, bottomTab)
      }
      if (to !== location.pathname) {
        navigate(to)
      }
    }
  }, [areCatalogsAlreadyFetched, catalogId, namespaceId, tableId, hasLoadedFromUrl, topTab, bottomTab])

  return <></>
}
