import { useDispatch, useSelector } from 'react-redux'
import {
  chooseTableDetailsTab,
  chooseTableInsightsTab,
  chooseTableMetadataTab,
  collapseAll,
  collapseAllAndExpandCatalog,
  collapseAllAndExpandCatalogAndNamespace,
  collapseAllAndExpandCatalogAndNamespaceAndSelectTable,
  selectAreAlreadyFetched,
  selectChosenCatalogId,
  selectChosenNamespaceId,
  selectChosenTableId,
  setHasLoadedFromUrl,
  TableDetailsTab,
  TableInsightsTab,
  TableMetadataTab,
} from '../../../state/slices/catalogs-slice'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'

export const RouteToStateSynchronizer = () => {
  const { catalogId: urlCatalogId, namespaceId: urlNamespaceId, tableId: urlTableId, topTab, bottomTab } = useParams()
  const stateCatalogId = useSelector(selectChosenCatalogId)
  const stateNamespaceId = useSelector(selectChosenNamespaceId)
  const stateTableId = useSelector(selectChosenTableId)
  const areCatalogsAlreadyFetched = useSelector(selectAreAlreadyFetched)
  const dispatch = useDispatch()

  useEffect(() => {
    if (areCatalogsAlreadyFetched) {
      if (userNavigatedInHistory()) {
        if (!urlCatalogId && !urlNamespaceId && !urlTableId) {
          dispatch(collapseAll())
        } else if (urlCatalogId && !urlNamespaceId && !urlTableId) {
          dispatch(collapseAllAndExpandCatalog(urlCatalogId))
        } else if (urlCatalogId && urlNamespaceId && !urlTableId) {
          dispatch(collapseAllAndExpandCatalogAndNamespace({ catalogId: urlCatalogId, namespaceId: urlNamespaceId }))
        } else if (urlTableId) {
          dispatch(
            collapseAllAndExpandCatalogAndNamespaceAndSelectTable({
              catalogId: urlCatalogId,
              namespaceId: urlNamespaceId,
              tableId: urlTableId,
            })
          )

          if (topTab) {
            const key = Object.keys(TableDetailsTab).find((key) => TableDetailsTab[key] === topTab)
            const tableDetailTab = TableDetailsTab[key]
            if (tableDetailTab) {
              dispatch(chooseTableDetailsTab(tableDetailTab))

              if (bottomTab) {
                if (tableDetailTab === TableDetailsTab.TABLE_INSIGHTS) {
                  const key = Object.keys(TableInsightsTab).find((key) => TableInsightsTab[key] === bottomTab)
                  const tableTab = TableInsightsTab[key]
                  if (tableTab) {
                    dispatch(chooseTableInsightsTab(tableTab))
                  }
                } else if (tableDetailTab === TableDetailsTab.TABLE_METADATA) {
                  const key = Object.keys(TableMetadataTab).find((key) => TableMetadataTab[key] === bottomTab)
                  const metadataTab = TableMetadataTab[key]
                  if (metadataTab) {
                    dispatch(chooseTableMetadataTab(metadataTab))
                  }
                }
              }
            }
          }
        }
      } else {
        dispatch(setHasLoadedFromUrl())
      }
    }
  }, [areCatalogsAlreadyFetched, urlCatalogId, urlNamespaceId, urlTableId])

  const userNavigatedInHistory = () => {
    return urlCatalogId != stateCatalogId || urlNamespaceId != stateNamespaceId || urlTableId != stateTableId
  }

  return <></>
}
