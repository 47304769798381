import { Int64, vectorFromArray } from 'apache-arrow'
import { WindowedHistogramStructType } from './arrow-mock-types'

export const generateHourlyWindowLastYearInMs: () => number[] = () => {
  const now = new Date().getTime()
  const hour = 60 * 60 * 1000
  const yearAgo = now - 365 * 24 * hour
  let current = yearAgo
  const hourlyTimestamps: number[] = []

  while (current < now) {
    hourlyTimestamps.push(current)
    current += hour
  }
  return hourlyTimestamps
}

export const mockHistogram = (maxCount: number = 1000) => ({
  number_of_bins: 40,
  bin_heights: Array.from({ length: 40 }, (_, index) => ({
    bin_index: index,
    bin_count: BigInt(Math.round(Math.random() * maxCount)),
  })),
})

export const mockWindowedHistogram = (minValue: number, maxValue: number, maxCount: number = 1000) => {
  const hist = mockHistogram(maxCount)
  const countValue = hist.bin_heights.reduce((acc, curr) => acc + curr.bin_count, BigInt(0))
  const delta = BigInt(maxValue - minValue)
  const sumValue = hist.bin_heights.reduce(
    (acc, curr) => acc + curr.bin_count * BigInt(curr.bin_index) * delta + BigInt(minValue),
    BigInt(0)
  )
  return {
    // min_value: BigInt(Math.floor(minValue * (1 + Math.random()))),
    // min_value: BigInt(1),
    min_value: BigInt(minValue),
    max_value: BigInt(Math.floor(maxValue * Math.random())),
    count_value: countValue,
    sum_value: sumValue,
    hist: hist,
  }
}

export const mockWindowedHistogramGrowingSeries = (
  seriesLength: number,
  maxInitialCount: number,
  maxFinalCount: number,
  minValue: number,
  maxValue: number
) => {
  return vectorFromArray(
    mockWindowedHistogramGrowingSeriesRaw(seriesLength, maxInitialCount, maxFinalCount, minValue, maxValue),
    WindowedHistogramStructType
  )
}
export const mockWindowedHistogramGrowingSeriesRaw = (
  seriesLength: number,
  maxInitialCount: number,
  maxFinalCount: number,
  minValue: number,
  maxValue: number
) => {
  const deltaForStep = (maxFinalCount - maxInitialCount) / seriesLength

  const growth = []
  for (let i = 0; i < seriesLength; i++) {
    growth.push(i * deltaForStep + maxInitialCount)
  }

  return [...growth.map((g) => mockWindowedHistogram(minValue, maxValue, g))]
}

export const mockGrowingSeries = (seriesLength: number, maxInitialCount: number, maxFinalCount: number) => {
  return vectorFromArray(mockGrowingSeriesRaw(seriesLength, maxInitialCount, maxFinalCount), new Int64())
}
export const mockGrowingSeriesRaw = (seriesLength: number, maxInitialCount: number, maxFinalCount: number) => {
  const deltaForStep = BigInt(Math.floor((maxFinalCount - maxInitialCount) / seriesLength))

  const growth = []
  for (let i = 0; i < seriesLength; i++) {
    growth.push(BigInt(i) * deltaForStep + BigInt(maxInitialCount))
  }

  return growth
}
