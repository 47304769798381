import { Cell2, ColumnTitle3, Table, TableBodyRow, TableHeadRow, TableWrapper } from '../../common/table/table-atoms'
import { CatalogFlatStructure, expandCatalog, selectCatalogsList } from '../../../state/slices/catalogs-slice'
import { useDispatch, useSelector } from 'react-redux'

export const CatalogRow = ({ catalog: { description, id } }: { catalog: CatalogFlatStructure }) => {
  const dispatch = useDispatch()

  return (
    <TableBodyRow>
      <Cell2
        text={id}
        handleClick={() => {
          dispatch(expandCatalog(id))
        }}
      />
      <Cell2 text={description || 'NO DESCRIPTION'} />
    </TableBodyRow>
  )
}

export const CatalogsList = () => {
  const catalogs = useSelector(selectCatalogsList)

  return (
    <div className='mt-16 flex w-full flex-col items-center'>
      <h1 className='text-3xl font-bold text-gray-700'>Catalogs</h1>
      <div className='mt-16 flex w-full flex-col items-center justify-center p-4 xl:w-2/3 2xl:w-1/2'>
        <TableWrapper>
          <Table>
            <TableHeadRow>
              <ColumnTitle3 title='Catalog Name' />
              <ColumnTitle3 title='Type' />
            </TableHeadRow>
            <tbody>
              {catalogs.map((c: CatalogFlatStructure) => (
                <CatalogRow key={c.id} catalog={c} />
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </div>
    </div>
  )
}
