import { CANVAS_IDS } from '../../../../../../constants/canvas-ids'
import { useDispatch, useSelector } from 'react-redux'
import {
  actions,
  selectTableInsightsBytesReadData,
  selectTableInsightsFilesReadData,
  selectTableInsightsRowsReadData,
  selectTableInsightsTimeSeriesWindow,
} from '../../../../../../state/slices/table-insights-slice'
import { ChartDataPoint } from '../../../../../common/chart/types'
import { Timeline } from '../../../../../common/chart/timeline/timeline'
import { TimeSeriesType } from '../../../../../common/chart/timeline/timeline-types'
import { selectChosenTableInsightsArrowTableName } from '../../../../../../state/slices/catalogs-slice'
import { TimeSeriesWindow } from '../../types'

export const TableInsightsTimelineConnector = () => {
  const dispatch = useDispatch()
  const filesInsightTableName = useSelector(selectChosenTableInsightsArrowTableName)
  const timeSeriesWindow = useSelector(selectTableInsightsTimeSeriesWindow)
  const bytesRead: ChartDataPoint[] = useSelector(selectTableInsightsBytesReadData)
  const rowsRead: ChartDataPoint[] = useSelector(selectTableInsightsRowsReadData)
  const filesRead: ChartDataPoint[] = useSelector(selectTableInsightsFilesReadData)

  return (
    <Timeline
      canvasId={CANVAS_IDS.TABLE_INSIGHTS_TIMELINE_ID}
      tableName={filesInsightTableName}
      timeSeriesData={{
        [TimeSeriesType.BYTES]: {
          data: bytesRead,
          label: 'Bytes Added',
        },
        [TimeSeriesType.ROWS]: {
          data: rowsRead,
          label: 'Rows Added',
        },
        [TimeSeriesType.FILES]: {
          data: filesRead,
          label: 'Files Created',
        },
      }}
      onTimeSeriesWindowChange={(timeSeriesWindow: TimeSeriesWindow) => {
        dispatch(actions.chooseTimeSeriesWindow(timeSeriesWindow))
      }}
      currentTimeSeriesWindow={timeSeriesWindow}
      initialLeftTimeSeries={TimeSeriesType.FILES}
      initialRightTimeSeries={TimeSeriesType.ROWS}
    />
  )
}
