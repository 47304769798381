import { TimeSeries } from '../../response-models'
import { TimeSeriesWindow } from '../../../components/data-explorer/data-explorer-right-side/table-details/types'
import { sanitize } from '../sql-utils'
import { globalFilesTimeSeriesQuery } from './queries/global-files-time-series'
import { duck, DuckSession } from '../../duck'

export interface GlobalFilesTimeSeries {
  /**
   * The total size of data ingested in the given time window
   */
  totalBytes: TimeSeries<number>
  /**
   * The total size of files or events ingested in the given time window
   */
  totalRows: TimeSeries<number>
  /**
   * The total number of managed ingestions in the given time window
   */
  numberOfTables: TimeSeries<number>
}

/**
 * This function uses the data from loadIngestionsTable to return a time
 * window that shows how the number of files, bytes and rows changed over time.
 * @param timeWindow
 */

export const getGlobalFilesTimeSeries = async (timeWindow: TimeSeriesWindow): Promise<GlobalFilesTimeSeries> => {
  const query = globalFilesTimeSeriesQuery(timeWindow)

  return duck.runWithSession(async (session: DuckSession) => {
    const queryResult = await session.runQuery(query)
    const firstRow = queryResult.get(0).toJSON()

    if (
      firstRow &&
      firstRow.totalBytes &&
      firstRow.totalBytes.x != null &&
      firstRow.totalRows &&
      firstRow.numberOfTables
    ) {
      const { totalBytes, totalRows, numberOfTables } = firstRow

      return {
        totalBytes: {
          windowUnit: timeWindow,
          x: totalBytes.x.toJSON().map(sanitize) as number[],
          y: totalBytes.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
        totalRows: {
          windowUnit: timeWindow,
          x: totalRows.x.toJSON().map(sanitize) as number[],
          y: totalRows.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
        numberOfTables: {
          windowUnit: timeWindow,
          x: numberOfTables.x.toJSON().map(sanitize) as number[],
          y: numberOfTables.y.toJSON().map(sanitize) as number[],
        } as TimeSeries<number>,
      }
    } else {
      return null
    }
  })
}
