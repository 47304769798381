export const TopNavBar = ({ handleOnButtonClick }: { handleOnButtonClick: () => void }) => {
  return (
    <>
      <div className='block h-[65px] w-full xl:hidden' />
      <nav className='fixed top-0 z-10 flex w-full flex-row justify-between bg-dark p-4 xl:hidden'>
        <img src='/logos/new-qbeast-logo.png' width='130' />
        <button className='' onClick={handleOnButtonClick}>
          <div className='flex h-[33px] w-[50px] items-center justify-center '>
            <div className='flex h-[20px] w-[20px] flex-col items-center justify-between'>
              <div className='h-[2px] w-7 bg-white'></div>
              <div className='h-[2px] w-7 rounded bg-white'></div>
              <div className='h-[2px] w-7 bg-white'></div>
            </div>
          </div>
        </button>
      </nav>
    </>
  )
}
