import { initializeFullstory } from './fullstory-initializer'
import { initializeAmplitude } from './amplitude/amplitude-initializer'
import { initializeLivesession } from './livesession-initializer'
import { initializeSentry } from './sentry-initializer'

export const initializeAnalytics = () => {
  initializeFullstory()
  initializeAmplitude()
  initializeLivesession()
  initializeSentry()
}
