import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { selectAccountAccessToken } from '../../../../../state/slices/account-slice'
import { selectChosenOptimizationTableName } from '../../../../../state/slices/catalogs-slice'

import {
  fetchOptimizationEvents,
  OptimizationEvent,
} from '../../../../../transport/data-transformers/optimization-events/optimization-events-data-transformer'
import OptimizationTable from '../../../../common/table/table-optimization-events'
import { H2PageTitle } from '../../../atoms'

export const TableOptimizationEvents = () => {
  const accessToken = useSelector(selectAccountAccessToken)
  const tableName = useSelector(selectChosenOptimizationTableName)

  const [queryResult, setQueryResult] = useState<OptimizationEvent[]>([])

  useEffect(() => {
    fetchOptimizationEvents(accessToken, tableName)
      .then((data) => {
        setQueryResult(data)
      })
      .catch((error) => {
        console.error('Error fetching optimization events: ', error)
        setQueryResult([])
      })
  }, [accessToken, tableName])

  return (
    <div className='mt-24 flex w-full flex-col items-center gap-4'>
      <H2PageTitle title={'Latest Optimization Events'} />
      {queryResult.length <= 0 ? (
        <p>No optimization events to display.</p>
      ) : (
        <div className='h-full'>
          <OptimizationTable data={queryResult} />
        </div>
      )}
    </div>
  )
}
