import {
  CatalogResponseModel,
  CatalogsResponseModel,
  NamespaceResponseModel,
  TableResponseModel,
} from '../transport/response-models'
import { AddCatalogsPayload, CatalogFlatStructure, NamespaceFlatStructure, Table } from './slices/catalogs-slice'

const sanitizeNamespaceIdToString = (namespaceId: string | string[]) =>
  typeof namespaceId === 'string' ? namespaceId : namespaceId.join('.')

export const catalogsResponseToStoreMapper = (response: CatalogsResponseModel): AddCatalogsPayload => {
  const tables: Table[] = response.tables.map(
    (t: TableResponseModel): Table => ({
      id: t.id,
      description: t.description,
      tableType: t.tableType,
      createdBy: t.createdBy,
      createdTime: t.createdTime,
      owner: t.owner,
      location: t.location,
      index: t.index,
      cubeSize: t.cubeSize,
      columns: t.columns.map((c) => ({
        name: c.name,
        description: c.description,
        dataType: c.dataType,
      })),
      hasConsumptionInsights: t.hasConsumptionInsights,
      hasIngestionInsights: t.hasIngestionInsights,
      hasContinuousOptimization: t.hasContinuousOptimization,
    })
  )
  const tableIdsThatDoHaveObjects: Set<string> = new Set(tables.map((n) => n.id))
  const namespaces: NamespaceFlatStructure[] = response.namespaces.map(
    (n: NamespaceResponseModel): NamespaceFlatStructure => ({
      id: sanitizeNamespaceIdToString(n.id),
      description: n.description,
      tablesIds: n.tablesIds.filter((id) => tableIdsThatDoHaveObjects.has(id)),
    })
  )
  const namespaceIdsThatDoHaveObjects: Set<string> = new Set(namespaces.map((n) => n.id))
  return {
    catalogs: response.catalogs.map(
      (c: CatalogResponseModel): CatalogFlatStructure => ({
        id: c.id,
        description: c.description,
        namespacesIds: c.namespacesIds
          .map((id) => sanitizeNamespaceIdToString(id))
          .filter((id) => namespaceIdsThatDoHaveObjects.has(id)),
      })
    ),
    namespaces: namespaces,
    tables: tables,
  }
}
