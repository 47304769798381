import { useSelector } from 'react-redux'
import { selectChosenCatalog, selectChosenNamespace, selectChosenTable } from '../../../state/slices/catalogs-slice'
import { TableDetails } from './table-details/table-details'
import { NamespaceDetails } from './namespace-details'
import { CatalogDetails } from '../catalog-details'
import { CatalogsList } from './catalogs-list'

export const RightSide = () => {
  const catalog = useSelector(selectChosenCatalog)
  const namespace = useSelector(selectChosenNamespace)
  const table = useSelector(selectChosenTable)
  return (
    <div className='flex grow flex-col items-center bg-gray-50'>
      {!table && !namespace && !catalog && <CatalogsList />}
      {!table && !namespace && catalog && <CatalogDetails />}
      {!table && namespace && <NamespaceDetails />}
      {table && <TableDetails />}
    </div>
  )
}
